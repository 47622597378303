import { Grid, Typography } from "@material-ui/core";
import React from "react";

const AuthTemplate = (props) => {
  return (
    <Grid container item>
      {props.title !== undefined && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ padding: "1rem", paddingBottom: "0.25rem" }}
        >
          <Typography variant="h2" align="center">
            {props.title}
          </Typography>
        </Grid>
      )}
      {props.subtitle !== undefined && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ padding: "1rem", paddingTop: "0.25rem" }}
        >
          <Typography variant="body1" align="center">
            {props.subtitle}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} style={props.childrenStyle}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default AuthTemplate;
