import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  StyledTableRow,
  countOrACVFormatter,
  percentFormatter,
} from "../../util/customFunctions";
import PipelineProgressionTable from "./PipelineProgressionTable";
import Legend from "../../RepPerformance/AsOfDate/Legend";
import D3SankeyContainer from "./SankeyContainer";
const useStyles = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "1.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    // outline: "0.2px solid lightgrey",
    // outlineOffset: "-1.5px",
    outline: "lightgrey solid 0.5px",
    outlineOffset: "-1.5px",
    zIndex: 5,
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      // background: "lightgrey", // Adjust the color as needed
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
  itemContainer: {
    padding: "2rem 2rem",
  },
}));

const PipelineProgressionTableContainer = (props) => {
  console.log("PipelineProgressionTableContainer_data", props.data);
  const classes = useStyles();
  const titleMap = {
    Qualified_Date_Initial_Fiscal_Quarter: "Qualified",
    Created_Fiscal_Quarter: "Created",
    Open_Date_Initial_Fiscal_Quarter: "Opened",
    Qualified_Initial_Year_Month: "Qualified",
    Created_Year_Month: "Created",
    Open_Initial_Year_Month: "Opened",
  };
  console.log(props, "Sankey_Container");
  return (
    <Grid container item justifyContent="center">
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{ padding: "1rem" }}
      >
        <Typography
          variant="h2"
          align="center"
          datatest={`pipeline_progression_heading_qtr${props.id + 1}`}
        >
          Pipeline - {titleMap[props.data.fqType]} initially in{" "}
          {props.data[props.data.fqType]}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <D3SankeyContainer
          id={props.id}
          fqType={props.data.fqType}
          tableData={props.data}
          toggleValue={props.toggleValue}
          handleChangeToggle={props.handleChangeToggle}
          titleLabel={titleMap[props.data.fqType]}
        />
      </Grid>
      <Grid xs={12} container item justifyContent="center">
        <Grid container item xs={12} className={classes.itemContainer}>
          <Typography variant="body1">
            Total Qualified pipeline initially created in{" "}
            <span className={classes.fontBold}>
              {props.data[props.data.fqType]}
            </span>{" "}
            : {"      "}
            <span className={classes.fontBold}>
              {countOrACVFormatter(props.data.GrandTotal, true)}
            </span>
          </Typography>
        </Grid>
        <Grid container item xs={12} className={classes.itemContainer}>
          <PipelineProgressionTable
            handleChangeLocation={props.handleChangeLocation}
            data={props.data}
            id={props.id}
          />
        </Grid>
        <Grid container item xs={12} className={classes.itemContainer}>
          <Legend
            square={15}
            type="AsOfDate"
            legends={[
              {
                text: "Stage with the highest % of open pipe",
                color: props.data.chartConfig.highestPercentOpenOpp,
              },
              {
                text: "Timeframes in the past - ideally there should be no open opps here.",
                color: props.data.chartConfig.openOppsHighlightColor,
              },
            ]}
          />
        </Grid>
        <Grid container xs={12} className={classes.itemContainer}>
          <Grid container xs={12}>
            <Typography variant="body1">
              Still Open ACV:{" "}
              {countOrACVFormatter(props.data.stillOpenACV, true)}
            </Typography>
          </Grid>
          <Grid container xs={12}>
            <Typography variant="body1">
              Still Open % of Total:{" "}
              {percentFormatter(props.data.stillOpenPercent)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PipelineProgressionTableContainer;
