import React from "react";
import {
  Box,
  Modal,
  Dialog,
  Hidden,
  Slide,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material"; // Material-UI v4 import

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SingleLevelDrilldownForAreaOfFocus = ({
  header,
  isOpen,
  onClose,
  handleRadioChange,
  selectedValue,
  handleContinue,
  handleCancel,
}) => {
  const renderContent = () => (
    <Box style={{ display: "flex", flexDirection: "column",  marginTop: 16 }}>
      <RadioGroup value={selectedValue} onChange={handleRadioChange}>
        {header.map((item, idx) => (
          <FormControlLabel
            key={idx}
            value={item.value}
            control={<Radio color="primary" />}
            label={item.label}
            style={{ marginTop: 16 }}
          />
        ))}
      </RadioGroup>
      <Box style={{ display: "flex", justifyContent: "flex-end", paddingTop: 24, marginTop: 16 }}>
        <Button variant="contained" color="primary" onClick={handleContinue} disabled={!selectedValue}
         style={{ marginRight: 10 }}>
          Confirm
        </Button>
        <Button variant="contained" color="primary" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
          {renderContent()}
        </Dialog>
      </Hidden>
      <Hidden smDown>
        <Modal open={isOpen} onClose={onClose}>
          <Box style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            borderRadius: 8,
            boxShadow: 24,
            padding: 32,
          }}>
            {renderContent()}
          </Box>
        </Modal>
      </Hidden>
    </>
  );
};

export default SingleLevelDrilldownForAreaOfFocus;
