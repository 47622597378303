import * as d3 from "d3";
import { updatedScheme10 } from "../../util/chartColorScheme";
import "../../Dashboard/OpenOpportunities/D3OpenOppurtunities.scss";
import {
  convertToThousands,
  getUniqueValues,
  groupBy,
  replaceHyphens,
  countOrACVFormatter,
} from "../../util/customFunctions";
import { deSnake, formatDataValues } from "../../util/dataFormatter";
import "../WinACVMixAnalysis/D3LineChart.scss";
import { computeDomainLimits } from "../../Dashboard/OpenOpportunities/utils";
import { store } from "../../util/store";
const D3LineChart = {};
D3LineChart.create = (el, config) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  if (config.data) {
    config.data = config.data.map((item) => ({
      Acct_Segment_Rollup: item[config.key],
      quarter: item[config.quarter],
      value: item[config.type],
      deals: item.deals,
      count: item.count,
      acv: item.acv,
    }));

    let uniqueStages = config.data
      .map((item) => item.Acct_Segment_Rollup)
      .filter(getUniqueValues);
    if (config.chart === "pcdMovedAnalysis") {
      uniqueStages = uniqueStages.sort((a, b) => a - b);
    }

    const groupedData = uniqueStages.map((segment) => {
      return config.data.filter((item) => item.Acct_Segment_Rollup === segment);
    });
    const uniqueQuarters = config.uniqueQuarters.sort();
    let bisectDate = d3.bisector(function (d) {
      return d.quarter;
    }).left;
    config.margin = {
      top: 20,
      right: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 70
          : 120
        : 10,
      bottom: 50,
      left: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 10
          : 120
        : 10,
    };
    config.tooltip = { width: 100, height: 100, x: 10, y: -30 };
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 100;
    }
    config.height = config.data ? 300 : 100;
    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr("height", config.height + config.margin.top + config.margin.bottom);
    var tooltip = d3
      .select(el)
      .append("div")
      .attr("class", "tooltip-line-chart")
      .style("opacity", 0);
    var chart = chartContainer
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );
    var legend = d3.select(el).append("div").attr("class", "legend");
    var x = d3.scaleBand().rangeRound([0, config.width]).padding(1).align(0.1);
    var y = d3.scaleLinear().range([config.height - config.margin.top * 2, 0]);
    var color = d3.scaleOrdinal(updatedScheme10);
    x.domain(uniqueQuarters);
    const { lower_bound, upper_bound } = computeDomainLimits(
      d3.min(config.data, (d) => d.value) < 0
        ? d3.min(config.data, (d) => d.value)
        : 0,
      d3.max(config.data, (d) => d.value)
    );
    y.domain([lower_bound, upper_bound]);
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(5);
    }
    var y_axis = d3
      .axisLeft()
      .tickFormat((x) =>
        config.type === "acv"
          ? `${countOrACVFormatter(Math.round(x / 1000), true)}`
          : countOrACVFormatter(Math.round(x), false)
      )
      .ticks(5)
      .scale(y);

    chart
      .append("g")
      .attr("class", "grid")
      .attr("z-index", -1)
      .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));
    chart
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left - 170 + "," + config.margin.top + ")"
      )
      .call(y_axis);
    var x_axis = d3.axisBottom().scale(x);

    //Append group and insert axis
    chart
      .append("g")
      .style("font-size", "0.75rem")
      .attr(
        "transform",
        "translate(" + 0 + "," + (config.height - config.margin.top - 20) + ")"
      )
      .call(x_axis)
      .selectAll("text")
      .style("text-anchor", (d) => (uniqueQuarters.length >= 11 ? "end" : null))
      .attr("dx", (d) => (uniqueQuarters.length >= 11 ? "-.4em" : null))
      .attr("dy", (d) => (uniqueQuarters.length >= 11 ? ".7em" : "0.6em"))
      .attr("transform", (d) =>
        uniqueQuarters.length >= 11 ? "rotate(-45)" : "rotate(0)"
      );

    // chart.append("g")
    // .attr("class", "axis axis--y")
    // .style('font-size',"0.75rem")
    // .call(d3.axisLeft(y).ticks(6))

    var lineAndDots = chart
      .append("g")
      .attr("class", "line-and-dots")
      .attr("transform", "translate(" + 0 + "," + 0 + ")");

    var focus = chart
      .append("g")
      .attr("class", "focus")
      .style("display", "none");

    focus.append("circle").attr("r", 5);

    // focus.append("rect")
    //   .attr("class", "tooltip")
    //   .attr("width", 100)
    //   .attr("height", 50)
    //   .attr("x", 10)
    //   .attr("y", -22)
    //   .attr("rx", 4)
    //   .attr("ry", 4);

    // focus.append("text")
    //   .attr("class", "tooltip-date")
    //   .attr("x", 18)
    //   .attr("y", -2);

    // focus.append("text")
    //   .attr("x", 18)
    //   .attr("y", 18)
    //   .text("Likes:");

    // focus.append("text")
    //   .attr("class", "tooltip-likes")
    //   .attr("x", 60)
    //   .attr("y", 18);
    // function mousemove() {
    //   var x0 = x.invert(d3.mouse(this)[0]),
    //     i = bisectDate(config.data, x0, 1),
    //     d0 = config.data[i - 1],
    //     d1 = config.data[i];
    //   console.log(i, d0, d1, x0)
    //   var d = d0;
    //   console.log(d)
    //   // focus.attr("transform", "translate(" + x(new Date(d.collectTime*1000)) + "," + y(d.dataList[3].value) + ")");
    //   // focus.select(".tooltip-date").text(`${new Date(d.collectTime*1000).getDate()}/${new Date(d.collectTime*1000).getMonth()+1}/${new Date(d.collectTime*1000).getFullYear()}`);
    //   // focus.select(".tooltip-likes").text(`${d.dataList[3].value}kWh`);
    // }

    // Data line
    groupedData.map((item, i) => {
      // chart.append("rect")
      //   .attr("class", "overlay")
      //   .attr("width", config.width)
      //   .attr("height", config.height)
      //   .on("mouseover", function () { focus.style("display", null); })
      //   .on("mouseout", function () { focus.style("display", "none"); })
      //   .on("mousemove", mousemove);

      var line = d3
        .line()
        .x(function (d) {
          return x(d.quarter);
        })
        .y(function (d) {
          return y(d.value);
        });
      lineAndDots
        .append("path")
        .style("fill", "none")
        .datum(item)
        .attr("class", "data-line")
        .attr("stroke", (d) => color(i))
        .attr("stroke-width", "4px")
        .attr("d", line);

      // Data dots
      lineAndDots
        .selectAll("line-circle")
        .data(item)
        .enter()
        .append("circle")
        .attr("class", "data-circle")
        .attr("fill", (d) => color(i))
        .attr("r", 5)
        .attr("cx", function (d) {
          return x(d.quarter);
        })
        .attr("cy", function (d) {
          return y(d.value);
        })
        .style("cursor", "pointer")
        .on("mouseover", (d) => {
          tooltip.transition().duration(200).style("opacity", 1);
          tooltip
            .html(
              `
            <div id="tooltip" class="tooltip-container">
        <div class="tooltip-box-scatter" style="border-top:10px solid ${color(
          i
        )};border-radius:0.3rem;padding:0.5rem;">              
        <h4 class="heading-style">${d.Acct_Segment_Rollup}</h4>
        <div class="tooltip-grid-container">
        <div class="flex-item" style="color:gray;">Move Fiscal Quarter : </div>
        <div class="flex-item" style="text-align: right">${d.quarter}</div>
      </div>
      <div class="tooltip-grid-container">
      <div class="flex-item" style="color:gray;">Count : </div>
      <div class="flex-item" style="text-align: right">${`${countOrACVFormatter(
        Math.round(d.count),
        false
      )}`}</div>
    </div>
      <div class="tooltip-grid-container" >
        <div class="flex-item" style="color:gray;">${
          config.Value_Label
        } : </div>
        <div class="flex-item" style="text-align: right">${`${countOrACVFormatter(
          Math.round(d.acv),
          true
        )}`}</div>
      </div>
      </div>`
            )
            .style(
              "left",
              `${
                d3.select(el).node().getBoundingClientRect().width < 800
                  ? d3.event.pageX < window.innerWidth * 0.55
                    ? `calc(${d3.event.pageX + "px"})`
                    : `calc(${d3.event.pageX + "px"}) - 20%`
                  : `calc(${d3.event.pageX + "px"} - 15%)`
              }`
            )
            .style("top", `calc(${d3.event.pageY + "px"} - 3%)`);
        })
        .on("mouseout", (d) => {
          tooltip.transition().duration(300).style("opacity", 0);
        });
    });

    // let legendColor = -1;

    // var legends = legend.selectAll('div').data(uniqueStages).enter().append('div').attr("class", "legend-flex").style('margin-top', '2rem')
    // legends.append('div').attr("class", "legends").style('align-items', 'end')
    //   .style("background-color", (d, i) => { return color(i) })
    // legends

    //   .append('div')
    //   .text((d, i) => d)
    //   // .style('text-align','center')
    //   .style('padding-left', '1rem')

    //   .style('min-width', '10rem').style('max-width', '10rem')

    // legend
    //   .attr('width', config.width)
    //   .attr('height', config.height)
    //   .attr("class", "legend-flex")
    //   .style('justify-content', 'center')
    //   .selectAll(".legends")
    //   .data(['one', 'two', 'three'])
    //   .enter()
    //   .append("div")
    //   .attr("class", "legends")
    //   .style("background-color", d => '')
    //   .html(d => `<div></div>`)

    chart
      .append("text")
      .attr("dy", "1em")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("y", config.margin.left - 190)
      .attr("x", (d) =>
        config.type === "acv" ? -(config.height / 2) : -(config.height / 1.6)
      )
      .attr("transform", "rotate(-90)") // although axis is rotated, text is not
      .attr("fill", "#00000")
      .style("text-transform", "capitalize")
      .text((d) =>
        ["acv"].includes(config.type)
          ? `${Currency_Symbol}K`
          : "Count (# Of Opps.)"
      );

    chart
      .append("text")
      .attr("y", config.height + 20)
      .attr("x", config.width / 2 - 90)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text("Move Fiscal Quarter");
  }
};
D3LineChart.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll("svg").remove();
  d3.select(".tooltip").remove();
  d3.select(".legend-flex").remove();
};
export default D3LineChart;
