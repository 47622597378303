import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ExpectedAcvDescriptionTable from "./ExpectedAcvDescriptionTable";
import ExpectedAcvDimensionTable from "./ExpectedAcvDimensionTable";
import ExpectedAcvScore from "./ExpectedAcvScore";
import ExpectedAcvCategory from "./ExpectedAcvCategoryTable";
import LoadingSkeleton from "./LoadingSkeleton";
import Legend from "../../AsOfDate/Legend";
import { D3MockupGraphsWaterfall } from "../../../EnhancedInsights/NewWaterFall/D3GraphsWaterfall";
import ExpectedAcvOppChangeTable from "./ExpectedAcvOppChangeTable";
import { D3MockupGraphsScoreCard } from "./D3GraphsScoreCard";
import { replaceDynamicValueLabel } from "../../../util/utils";
import { store } from "../../../util/store";

const ExpectedAcvPage = (props) => {
  let Value_Label = props.acvName ? `${props.acvName}:` : "ACV:";
  if (props.Value_Label) {
    Value_Label = replaceDynamicValueLabel(Value_Label, props.Value_Label);
  }
  return (
    <Grid item container justify="flex-start" data-test="excpected-page">
      <Grid item container justify="center">
        <Typography variant="h2">
          {`Expected Yield by projected ${
            props.opp ? "close date" : "quarter end"
          }`}
          <span
            style={{
              background: "#fee599ff",
              padding: "0rem 0.4rem",
              margin: "0rem 0.5rem",
              border: "1px solid lightgrey",
              color: "black",
            }}
          >
            Beta
          </span>
        </Typography>
      </Grid>
      <Grid item container>
        {props.data.otherDetails !== undefined ? (
          <ExpectedAcvDescriptionTable data={props.data.otherDetails} />
        ) : (
          <LoadingSkeleton />
        )}
      </Grid>
      <Grid style={{ padding: "0.5rem" }} item xs={12} container>
        {["C0004"].includes(props.company) ? (
          <Grid item xs={12}>
            {props.data.table1 !== undefined ? (
              props.data.table1.length > 0 && (
                <Grid item xs={12} md={5}>
                  <ExpectedAcvScore
                    data={props.data.table1}
                    thresholds={props.data.thresholds}
                  />
                </Grid>
              )
            ) : (
              <LoadingSkeleton />
            )}
          </Grid>
        ) : (
          <>
            {" "}
            <Grid item xs={8} style={{ paddingRight: "3.5rem" }}>
              {props.data.table1 !== undefined ? (
                props.data.table1.length > 0 && (
                  <>
                    <Typography
                      align="center"
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: 500,
                        fontFamily: "Roboto/Helvetica/Arial/ sans-serif",
                        lineHeight: "2rem",
                      }}
                    >
                      Expected Yield
                    </Typography>
                    <Grid item xs={12} md={12}>
                      <ExpectedAcvCategory
                        data={props.data.table1}
                        thresholds={props.data.thresholds}
                        Win_Rate_Prefix={props.Win_Rate_Prefix}
                      />
                    </Grid>{" "}
                  </>
                )
              ) : (
                <LoadingSkeleton />
              )}

              <Grid style={{ paddingTop: "0.5rem" }} item xs={12} container>
                <Grid item xs={6} container justify="flex-start">
                  <Legend
                    square={15}
                    just="flex-start"
                    type="AsOfDate"
                    legends={[
                      {
                        text: `Limiting Category`,
                        color: "#fbe5d6ff",
                        border: "1px solid lightgrey",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  {props.data.table2 !== undefined ? (
                    <ExpectedAcvDimensionTable data={props.data.table2} />
                  ) : (
                    <LoadingSkeleton />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {props.data.oppChangeEvent !== undefined ? (
                <Typography
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 500,
                    fontFamily: "Roboto/Helvetica/Arial/ sans-serif",
                    lineHeight: "2rem",
                  }}
                >
                  Change Events
                </Typography>
              ) : (
                ""
              )}
              {props.data.oppChangeEvent !== undefined ? (
                <Grid item xs={12} md={12}>
                  <ExpectedAcvOppChangeTable
                    Value_Label={props.Value_Label}
                    data={props.data.oppChangeEvent}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>{" "}
          </>
        )}
      </Grid>

      {console.log(props)}

      <Grid item xs={12}>
        {props.data.oppChangeEvent !== undefined ? (
          <D3MockupGraphsScoreCard
            data={props.data.oppChangeEvent}
            isLowConfidence={props.data.isLowConfidence}
          />
        ) : (
          ""
        )}
      </Grid>

      {props.data !== "" && props.data.isLowConfidence > 0 && (
        <Grid item xs={12} container justify="center">
          <Grid item style={{ background: "#ffc000ff", padding: "0.5rem" }}>
            <Typography variant="h2">
              {`Low confidence estimate. Not enough historical data available for opps  ${
                props.data.lowConfidenceKey
                  ? props.data.lowConfidenceKey
                  : props.lowConfidenceKey
                  ? props.lowConfidenceKey
                  : "> $200K"
              } ${props.Value_Label}.`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ExpectedAcvPage;
