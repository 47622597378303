import { Card, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import PipelineCoverageSectionTable from "./PipelineCoverageSectionTable";
import Drilldown from "../../components/Drilldown/Drilldown";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  countOrACVFormatter,
  countOrACVFormatterThousand,
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import ExpectedAcvPage from "../ScoreCard/ExpectedAcv/ExpectedAcvPage";
import PipelineTrendsRollingDrilldown from "../../EnhancedInsights/PipelineTrends/PipelineTrendsRollingDrilldown";
import DrilldownSLD from "./DrilldownSLD";
import PipelineCoverageDealsTable from "./PipelineCoverageDealsTable";
import PipelineCoverageCustTypeTable from "./PipelineCoverageCustTypeTable";
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { styled } from "@mui/material/styles";
import PipelineCoverageSectionDealTable from "./PipelineCoverageSectionDealTable";
import { store } from "../../util/store";

const styles = (theme) => ({
  gridItem: {
    width: "100%",
    padding: "1rem",
  },
});

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

const classes = styles();

const PipelineCoverage = (props) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  const [location, setlLocation] = useState("");
  const [filtersForDrilldown, setFiltersForDrilldown] = useState([]);
  const [showLowMidHigh, setShowLowMidHigh] = useState(false);
  const [secondlevellocation, setsecondlevellocation] = useState("");
  const [deals20KDrilldown, setdeals20KDrilldown] = useState(false);
  const [toggleValue, setToggleValue] = useState("ACV");

  const handleChangeToggle = (e, newAlignment) => {
    setToggleValue(newAlignment);
  };

  useEffect(() => {
    console.log(props);
    if (![undefined, null, ""].includes(props.filters)) {
      const currentDate = [`${props.currentDate} 23:59:59`];
      var filters = [
        ...props.filters,
        {
          name: "timeFrame",
          value: [
            { name: "projectedCloseRolling", value: 6 },
            { name: "Open_Datetime", value: currentDate },
            { name: "timeFrame", value: 1 },
            { name: "durationType", value: "months" },
          ],
        },
        { name: "Earliest_Open_Stage", value: ["Solutioning"], string: true },
      ];
      props.getPipelineCoverageData(props.filters, props.type);
      props.pipelineCoverageDeals20KFlag === "Y" &&
        props.getPipelineCoverageDeals20KData(props.filters, props.type);
      props.db !== "C0003" &&
        props.getPipelineCoverageCustTypeData(props.filters, props.type);
      props.db === "C0001" &&
        props.type === "CFQ" &&
        !props.isrepScoreCard &&
        props.getPipelineCoverageDealCategoryData(props.filters, props.type);
    }
  }, [props.filters]);

  const handleChangeLocationBack = () => {
    console.log(location);
    setlLocation("");
    setsecondlevellocation("");
  };

  const handleChangeLocation = (
    location,
    teams,
    current_status,
    timeFrame,
    showLowMidHigh,
    custType
  ) => {
    var filters = [...props.filters];
    var filter =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? { name: "Team", value: teams }
        : { name: "Owner_Name", value: teams };
    filters = [...filters, filter];
    if (toggleValue === "ACV-Threshold") {
      filters = [...filters, { name: "threshold", value: true }];
    }
    if (custType !== undefined) {
      filters = [
        ...filters,
        {
          name: props[`pipelineCoverageCustType${props.type}Data`].spltitUpType,
          value: custType,
        },
      ];
    }

    var drilldownfilter = [
      {
        title:
          props.selectedTeam.includes("All") &&
          props.selectedRep.includes("All")
            ? "Team"
            : "Rep",
        value: teams.length > 1 ? "All" : teams,
      },
    ];
    if (custType !== undefined) {
      drilldownfilter = [
        ...drilldownfilter,
        { title: "Customer Type", value: custType },
      ];
    }

    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    setShowLowMidHigh(showLowMidHigh);
    setdeals20KDrilldown(false);

    props.getPipelineCoverageDrilldown(filters, current_status, timeFrame);
  };

  const handleChangeLocationDealCat = (
    location,
    teams,
    current_status,
    timeFrame,
    showLowMidHigh,
    custType
  ) => {
    var filters = [...props.filters];
    // console.log(teams, 'TTTTTT')
    var f = {
      name: "deal_category__c",
      value: typeof teams === "string" ? [teams] : teams,
    };

    filters = [...filters, f];
    if (toggleValue === "ACV-Threshold") {
      filters = [...filters, { name: "threshold", value: true }];
    }

    var drilldownfilter = [
      {
        title: "Deal Category",
        value: teams.length > 1 ? "All" : teams,
      },
    ];

    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    setShowLowMidHigh(showLowMidHigh);
    setdeals20KDrilldown(false);

    props.getPipelineDealCategoryDrilldown(filters, current_status, timeFrame);
  };

  const handleChangeLocationDeal = (
    location,
    teams,
    current_status,
    timeFrame,
    showLowMidHigh,
    custType
  ) => {
    var filters = [...props.filters];
    var filter =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? { name: "Team", value: teams }
        : { name: "Owner_Name", value: teams };
    filters = [...filters, filter];
    if (custType !== undefined) {
      filters = [
        ...filters,
        {
          name: props[`pipelineCoverageCustType${props.type}Data`].spltitUpType,
          value: custType,
        },
      ];
    }
    var drilldownfilter = [
      {
        title:
          props.selectedTeam.includes("All") &&
          props.selectedRep.includes("All")
            ? "Team"
            : "Rep",
        value: teams.length > 1 ? "All" : teams,
      },
    ];
    if (custType !== undefined) {
      drilldownfilter = [
        ...drilldownfilter,
        {
          title:
            props[`pipelineCoverageCustType${props.type}Data`].spltitUpType ===
            "deal_category__c"
              ? "Deal Category"
              : "Customer Type",
          value: custType,
        },
      ];
    }
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    setShowLowMidHigh(showLowMidHigh);
    setdeals20KDrilldown(false);

    props.getPipelineCoverageDrilldown(
      filters,
      current_status,
      timeFrame,
      undefined,
      props[`pipelineCoverageCustType${props.type}Data`].spltitUpType
    );
  };

  const handleChangeLocationDealCatWon = (
    location,
    teams,
    bookingTypes,
    timeFrame,
    deals20K
  ) => {
    setShowLowMidHigh(false);
    var filters = [...props.filters];
    if (props.filters.length === 0) {
      filters = [
        ...filters,
        {
          name: "team",
          value: props.PipelineCoverageCFQ.data
            .filter((s) => !["company", "team"].includes(s.name))
            .map((s) => s.name)
            .filter(getUniqueValues)
            .sort(),
        },
      ];
    }
    filters = [
      ...filters,
      { name: "closed_fiscal_quarter", value: [props.quarter] },
      {
        name: "deal_category__c",
        value: typeof teams === "string" ? [teams] : teams,
      },
    ];

    var drilldownfilter = [
      {
        title: "Deal Category",
        value: teams.length > 1 ? "All" : teams,
      },
    ];

    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    setdeals20KDrilldown(false);

    props.getWinACVDrilldown(filters);
  };

  const handleChangeLocationWon = (
    location,
    teams,
    bookingTypes,
    timeFrame,
    deals20K
  ) => {
    setShowLowMidHigh(false);
    var filters = [...props.filters];
    filters = [
      ...filters,
      { name: "Booking_Type_NL_CS_US", value: bookingTypes },
    ];
    let teamOrOwnerNameBubble =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? { title: "Team", value: teams.length > 1 ? ["All"] : teams }
        : {
            title: teams.length > 1 ? "Team" : "Rep",
            value:
              teams.length > 1
                ? props.selectedTeam.includes("All")
                  ? props.selectedRep
                  : props.selectedTeam
                : teams,
          };
    var drilldownfilter =
      deals20K === "Deals20K"
        ? [
            teamOrOwnerNameBubble,
            {
              title: "Projected Close Fiscal Quarter",
              value:
                props[`PipelineCoverageDeals20K${props.type}`]
                  .fiscalQuartersForFrontEnd,
            },
            { title: "Customer Type", value: bookingTypes },
            { title: "ACV", value: ">=$20,000" },
          ]
        : [{ title: "Team", value: teams.length > 1 ? ["All"] : teams }];
    if (teams !== undefined) {
      var filter =
        props.selectedTeam.includes("All") && props.selectedRep.includes("All")
          ? { name: "Team", value: teams }
          : { name: "Owner_Name", value: teams };
      filters = [...filters, filter];
      drilldownfilter = [...drilldownfilter];
    }
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    setdeals20KDrilldown(deals20K === "Deals20K" ? true : false);
    if (deals20K === "Deals20K") {
      let current_status = "open";
      return props.getPipelineCoverageDrilldown(
        filters,
        current_status,
        timeFrame,
        deals20K
      );
    } else {
      return props.getAttainmentSectionDrilldown(filters, "CFQ", "coverage");
    }
  };

  const handleChangeLocationRolling = (location, teams, bookingTypes) => {
    const currentDate = [`${props.currentDate}T23:59:59.000Z`];
    var filters = [
      ...props.filters,
      { name: "Open_Datetime", value: currentDate },
      {
        name: "timeFrame",
        value: [
          { name: "projectedCloseRolling", value: 6 },
          { name: "timeFrame", value: 1 },
          { name: "durationType", value: "months" },
        ],
      },
      { name: "Earliest_Open_Stage", value: ["Solutioning"], string: true },
    ];
    var filter =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? [
            { name: "Team", value: teams, string: true },
            {
              name: "Booking_Type_NL_CS_US",
              value: bookingTypes,
              string: true,
            },
          ]
        : [
            { name: "Owner_Name", value: teams, string: true },
            {
              name: "Booking_Type_NL_CS_US",
              value: bookingTypes,
              string: true,
            },
          ];
    filters = [...filters, ...filter];
    var drilldownfilter = [
      {
        title:
          props.selectedTeam.includes("All") &&
          props.selectedRep.includes("All")
            ? "Team"
            : "Rep",
        value: teams.length > 1 ? "All" : teams,
      },
      {
        title: "Opportunity Type",
        value: bookingTypes.length > 1 ? "All" : bookingTypes,
      },
    ];
    setlLocation("RollingLocation");
    setFiltersForDrilldown(drilldownfilter);
    setShowLowMidHigh(false);
    props.getPipelineCoverageRollingDrilldown(filters);
  };

  const handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    setsecondlevellocation(location);
    props.getExpectedAcvData(oppId);
  };

  const stepBack = () => {
    setsecondlevellocation("");
    props.clearExpectedAcvData();
  };

  // const children = [
  //   <CustomWidthTooltip title={`ACV`} placement="bottom">
  //   <ToggleButton key="ACV" style={{background: toggleValue === 'ACV' ? '#4472C4' : '',color:toggleValue === 'ACV' ? 'white' : '',fontSize:'1rem',fontWeight:600,width:'3rem',height:'2rem'}} data-test={`acv-button-${props.type}`} value="ACV"><FiberManualRecordIcon /></ToggleButton>
  //   </CustomWidthTooltip>,
  //    <CustomWidthTooltip title="ACV by Threshold" placement="bottom">
  //    <ToggleButton key="Threshold" style={{background: toggleValue === 'ACV-Threshold' ? '#4472C4' : '',color:toggleValue === 'ACV-Threshold' ? 'white' : '',fontSize:'1rem',fontWeight:600,width:'3rem',height:'2rem'}} data-test={`currentAcv-button-${props.type}`} value="ACV-Threshold"><RadioButtonCheckedIcon /></ToggleButton>
  //    </CustomWidthTooltip >
  // ];

  console.log(props.type);
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
        {props[`PipelineCoverage${props.type}`] !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
            <Grid item container xs={12} justify="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography
                  variant="h2"
                  style={{ width: "100%", margin: "auto", paddingTop: "1rem" }}
                  align="center"
                >{`${props.title} - ${
                  props.type === "NFQ"
                    ? `Next Quarter (${props.quarter})`
                    : props.type === "NFQ2"
                    ? `Current Quarter  + 2 (${props.quarter})`
                    : props.type === "NFQ3"
                    ? `Current Quarter  + 3 (${props.quarter})`
                    : `Current Quarter (${props.quarter})`
                }${props.subTitle ? ` ${props.subTitle} ` : ""}`}</Typography>
              </Grid>
              <Grid item xs={1}>
                <ToggleButtonGroup
                  color="primary"
                  indicatorColor="primary"
                  value={toggleValue}
                  exclusive
                  // size='small'
                  onChange={handleChangeToggle}
                >
                  {/* {children} */}

                  <ToggleButton
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      width: "3rem",
                      height: "2rem",
                    }}
                    data-test={`acv-button-${props.type}`}
                    value="ACV"
                    selectedColor="#4472C4"
                  >
                    <CustomWidthTooltip
                      title={`Outliers Included`}
                      placement="bottom"
                    >
                      <RadioButtonCheckedIcon />
                    </CustomWidthTooltip>
                  </ToggleButton>
                  <ToggleButton
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      width: "3rem",
                      height: "2rem",
                    }}
                    data-test={`currentAcv-button-${props.type}`}
                    value="ACV-Threshold"
                    selectedColor="#4472C4"
                  >
                    <CustomWidthTooltip
                      title="Outliers Excluded"
                      placement="bottom"
                    >
                      <FiberManualRecordIcon />
                    </CustomWidthTooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            {toggleValue === "ACV-Threshold" && (
              <Grid item container xs={12} justify="center">
                <Typography
                  style={{
                    background: "#fee599ff",
                    padding: "0rem 0.4rem",
                    marginTop: "1rem",
                    border: "1px solid lightgrey",
                    color: "black",
                  }}
                >{`Excluding outliers >${countOrACVFormatterThousand(
                  props[
                    `PipelineCoverage${props.type}`
                  ].dataWithThreshold.filter((i) =>
                    ["company", "team"].includes(i.name)
                  )[0].outLayerValue,
                  true
                )} ${
                  [null, undefined, ""].includes(
                    props[
                      `PipelineCoverage${props.type}`
                    ].dataWithThreshold.filter((i) =>
                      ["company", "team"].includes(i.name)
                    )[0].outlierCount
                  )
                    ? 0
                    : countOrACVFormatter(
                        props[
                          `PipelineCoverage${props.type}`
                        ].dataWithThreshold.filter((i) =>
                          ["company", "team"].includes(i.name)
                        )[0].outlierCount
                      )
                } / ${
                  [null, undefined, ""].includes(
                    props[
                      `PipelineCoverage${props.type}`
                    ].dataWithThreshold.filter((i) =>
                      ["company", "team"].includes(i.name)
                    )[0].outlierAcv
                  )
                    ? `${Currency_Symbol}0`
                    : countOrACVFormatterThousand(
                        props[
                          `PipelineCoverage${props.type}`
                        ].dataWithThreshold.filter((i) =>
                          ["company", "team"].includes(i.name)
                        )[0].outlierAcv,
                        true
                      )
                }`}</Typography>
              </Grid>
            )}

            {/* {console.log(props)}  */}
            <PipelineCoverageSectionTable
              selectedTeam={props.selectedTeam}
              selectedRep={props.selectedRep}
              handleChangeLocation={handleChangeLocation}
              handleChangeLocationWon={handleChangeLocationWon}
              data={
                toggleValue === "ACV"
                  ? props[`PipelineCoverage${props.type}`].data
                  : props[`PipelineCoverage${props.type}`].dataWithThreshold
              }
              datav2={props[`PipelineCoverage${props.type}`].data}
              dataTest={
                props.isrepScoreCard
                  ? `rep-pipeline-coverage${props.type}`
                  : `pipeline-coverage${props.type}`
              }
              tableName={`Pipeline Coverage - ${props.type}`}
              idType={props.type === "CFQ" && "Current_Quarter_Pipeline_table"}
              type={props.type}
              db={props.db}
              splitup={props.splitup}
              qualifiedHeader={`Qualified Pipeline ${
                props.type === "CFQ" ? "current" : "next"
              } Qtr.`}
              isrepScoreCard={props.isrepScoreCard}
              tableID={
                props.type === "CFQ"
                  ? "Current_Quarter_Pipeline_table"
                  : props.tableID
              }
              Value_Label={props.Value_Label}
              showSeats={props[`PipelineCoverage${props.type}`].showSeats}
            />

            {props.db === "C0001" &&
              props.type === "CFQ" &&
              !props.isrepScoreCard &&
              props[`pipelineCoverageDealCategory${props.type}Data`] !== "" && (
                <Grid
                  style={{ padding: "0.5rem 0.5rem", marginTop: "1rem" }}
                  align="center"
                >
                  <Grid item container xs={12} justify="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant="h2"
                        style={{
                          width: "100%",
                          margin: "auto",
                          paddingTop: "1rem",
                        }}
                        align="center"
                      >{`${props.title} By Deal Category - ${
                        props.type === "NFQ"
                          ? `Next Quarter (${props.quarter})`
                          : props.type === "NFQ2"
                          ? `Current Quarter  + 2 (${props.quarter})`
                          : props.type === "NFQ3"
                          ? `Current Quarter  + 3 (${props.quarter})`
                          : `Current Quarter (${props.quarter})`
                      }`}</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>

                  <PipelineCoverageSectionDealTable
                    selectedTeam={props.selectedTeam}
                    selectedRep={props.selectedRep}
                    handleChangeLocation={handleChangeLocationDealCat}
                    handleChangeLocationWon={handleChangeLocationDealCatWon}
                    data={
                      toggleValue === "ACV"
                        ? props[`pipelineCoverageDealCategory${props.type}Data`]
                            .data
                        : props[`pipelineCoverageDealCategory${props.type}Data`]
                            .dataWithThreshold
                    }
                    datav2={
                      props[`pipelineCoverageDealCategory${props.type}Data`]
                        .data
                    }
                    dataTest={
                      props.isrepScoreCard
                        ? `rep-pipeline-coverage${props.type}-deal`
                        : `pipeline-coverage${props.type}-deal`
                    }
                    tableName={`Pipeline Coverage By Deal Category - ${props.type}`}
                    type={props.type}
                    db={props.db}
                    splitup={props.splitup}
                    qualifiedHeader={`Qualified Pipeline ${
                      props.type === "CFQ" ? "current" : "next"
                    } Qtr.`}
                    isrepScoreCard={props.isrepScoreCard}
                    Value_Label={props.Value_Label}
                  />
                </Grid>
              )}

            {props.pipelineCoverageDeals20KFlag === "Y" ? (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {props[`PipelineCoverageDeals20K${props.type}`] !== "" && (
                    <PipelineCoverageDealsTable
                      type={props.type}
                      fiscalQuarters={
                        props[`PipelineCoverageDeals20K${props.type}`]
                          .fiscalQuartersForFrontEnd
                      }
                      dealsSegmentRow={
                        props[`PipelineCoverageDeals20K${props.type}`]
                          .segmentRow
                      }
                      dealsTableData={
                        props[`PipelineCoverageDeals20K${props.type}`].table
                      }
                      selectedTeam={props.selectedTeam}
                      selectedRep={props.selectedRep}
                      handleChangeLocation={handleChangeLocationWon}
                      dataTest={`pipeline-coverage${props.type}deals20k`}
                    />
                  )}
                </Grid>
                <Grid item xs={8}>
                  {props[`pipelineCoverageCustType${props.type}Data`] !==
                    "" && (
                    <PipelineCoverageCustTypeTable
                      type={props.type}
                      segmentRow={
                        props[`pipelineCoverageCustType${props.type}Data`]
                          .segmentRow
                      }
                      splitup={
                        props[`pipelineCoverageCustType${props.type}Data`]
                          .splitup
                      }
                      table={
                        props[`pipelineCoverageCustType${props.type}Data`].table
                      }
                      selectedTeam={props.selectedTeam}
                      selectedRep={props.selectedRep}
                      handleChangeLocation={handleChangeLocationDeal}
                      tableName={`Pipeline Coverage CustType - ${props.type}`}
                      isrepScoreCard={props.isrepScoreCard}
                      Value_Label={props.Value_Label}
                    />
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  {props[`pipelineCoverageCustType${props.type}Data`] !==
                    "" && (
                    <PipelineCoverageCustTypeTable
                      type={props.type}
                      segmentRow={
                        props[`pipelineCoverageCustType${props.type}Data`]
                          .segmentRow
                      }
                      splitup={
                        props[`pipelineCoverageCustType${props.type}Data`]
                          .splitup
                      }
                      table={
                        props[`pipelineCoverageCustType${props.type}Data`].table
                      }
                      selectedTeam={props.selectedTeam}
                      selectedRep={props.selectedRep}
                      handleChangeLocation={handleChangeLocation}
                      tableName={`Pipeline Coverage CustType - ${props.type}`}
                      isrepScoreCard={props.isrepScoreCard}
                      Value_Label={props.Value_Label}
                      showSeats={
                        props[`pipelineCoverageCustType${props.type}Data`]
                          .showSeats
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            )}

            {/* {(props.pipelineCoverageDeals20KFlag === 'Y' && props[`PipelineCoverageDeals20K${props.type}`] !== '') && 
                   <PipelineCoverageDealsTable type={props.type} fiscalQuarters={props[`PipelineCoverageDeals20K${props.type}`].fiscalQuartersForFrontEnd} dealsSegmentRow={props[`PipelineCoverageDeals20K${props.type}`].segmentRow} dealsTableData={props[`PipelineCoverageDeals20K${props.type}`].table}  selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} handleChangeLocation={handleChangeLocationWon} dataTest={`pipeline-coverage${props.type}deals20k`} />}
                   {
                     props[`pipelineCoverageCustType${props.type}Data`] !== '' && <PipelineCoverageCustTypeTable type={props.type} segmentRow={props[`pipelineCoverageCustType${props.type}Data`].segmentRow} splitup = {props[`pipelineCoverageCustType${props.type}Data`].splitup} table = {props[`pipelineCoverageCustType${props.type}Data`].table}
                     selectedTeam={props.selectedTeam} 
                     selectedRep={props.selectedRep} 
                     handleChangeLocation={handleChangeLocation}  />
                   } */}
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>
      {location === "drilldownOnGraph" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={`Qualified Pipeline ${
              props.type === "CFQ"
                ? "Current"
                : props.type === "NFQ2"
                ? "Current + 2"
                : props.type === "NFQ3"
                ? "Current + 3"
                : "Next"
            } Quarter Close`}
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
            showOutliers={toggleValue === "ACV" ? false : true}
            outlierValue={
              props[`PipelineCoverageCFQ`].dataWithThreshold.filter((i) =>
                ["company", "team"].includes(i.name)
              )[0].outLayerValue
            }
            outliersData={props.drilldownOnGraphData}
          >
            <DrilldownSLD
              header={"Qualified Pipeline Current Quarter Close"}
              body={props.drilldownOnGraphData}
              showLowMidHigh={showLowMidHigh}
              showSLD={deals20KDrilldown ? false : true}
              handleSecondLevelDrilldown={handleSecondLevelDrilldown}
              type={props.type}
              toggleValue={toggleValue === "ACV" ? false : true}
              Value_Label={props.Value_Label}
            />
          </SingleLevelDrilldown>
        </Grid>
      )}
      {location === "RollingLocation" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={"Rolling 6-month pipeline coverage"}
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
          >
            <Drilldown
              header={"Rolling 6-month pipeline coverage"}
              body={props.drilldownOnGraphData}
              showLowMidHigh={showLowMidHigh}
              handleSecondLevelDrilldown={handleSecondLevelDrilldown}
              Value_Label={props.Value_Label}
            />
          </SingleLevelDrilldown>
        </Grid>
      )}
      {secondlevellocation === "drilldownOppId" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={`Qualified Pipeline ${
              props.type === "CFQ"
                ? "Current"
                : props.type === "NFQ2"
                ? "Current + 2"
                : props.type === "NFQ3"
                ? "Current + 3"
                : "Next"
            } Quarter Close`}
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
            stepBack={stepBack}
            count="2"
          >
            <ExpectedAcvPage
              data={props.ExpectedAcvData}
              opp={false}
              company={props.company}
              lowConfidenceKey={props.lowConfidenceKey}
              Value_Label={props.Value_Label}
            />
          </SingleLevelDrilldown>
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const {
    PipelineCoverageNFQ3,
    PipelineCoverageNFQ2,
    PipelineCoverageNFQ,
    PipelineCoverageCFQ,
    PipelineCoverageDeals20KNFQ,
    PipelineCoverageDeals20KNFQ2,
    PipelineCoverageDeals20KNFQ3,
    PipelineCoverageDeals20KCFQ,
    user,
    drilldownOnGraphData,
    ExpectedAcvData,
    pipelineCoverageCustTypeCFQData,
    pipelineCoverageCustTypeNFQData,
    pipelineCoverageCustTypeNFQ2Data,
    pipelineCoverageCustTypeNFQ3Data,
    pipelineCoverageDealCategoryCFQData,
  } = state.app;

  return {
    PipelineCoverageNFQ3,
    PipelineCoverageNFQ2,
    PipelineCoverageNFQ,
    PipelineCoverageCFQ,
    PipelineCoverageDeals20KNFQ,
    PipelineCoverageDeals20KCFQ,
    PipelineCoverageDeals20KNFQ3,
    PipelineCoverageDeals20KNFQ2,
    user,
    drilldownOnGraphData,
    ExpectedAcvData,
    pipelineCoverageCustTypeCFQData,
    pipelineCoverageCustTypeNFQData,
    pipelineCoverageCustTypeNFQ2Data,
    pipelineCoverageCustTypeNFQ3Data,
    pipelineCoverageDealCategoryCFQData,
    Value_Label: user.Value_Label,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getPipelineCoverageData: (filters, type) => {
    dispatch({ type: `get_generic_pipeline_coverage_data_${type}_request` });
    appService.getGenericPipelineCoverageData(filters, type).then(
      (json) => {
        dispatch({
          type: `get_generic_pipeline_coverage_data_${type}_success`,
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_generic_pipeline_coverage_data_${type}_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  getPipelineCoverageDeals20KData: (filters, type) => {
    dispatch({
      type: `get_generic_pipeline_coverage_deals20_data_${type}_request`,
    });
    appService.getGenericPipelineCoverageDeals20KData(filters, type).then(
      (json) => {
        dispatch({
          type: `get_generic_pipeline_coverage_deals20_data_${type}_success`,
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_generic_pipeline_coverage_deals20_data_${type}_failure`,
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_quota_attainment_data_deals20_failure",
            error,
          });
      }
    );
  },
  getPipelineCoverageCustTypeData: (filters, type) => {
    dispatch({
      type: `get_generic_pipeline_coverage_custType_data_${type}_request`,
    });
    appService.getPipelineCoverageCustTypeData(filters, type).then(
      (json) => {
        dispatch({
          type: `get_generic_pipeline_coverage_custType_data_${type}_success`,
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_generic_pipeline_coverage_custType_data_${type}_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  getPipelineCoverageDealCategoryData: (filters, type) => {
    dispatch({
      type: `get_generic_pipeline_coverage_deal_data_${type}_request`,
    });
    appService.getPipelineCoverageDealCategoryData(filters, type).then(
      (json) => {
        dispatch({
          type: `get_generic_pipeline_coverage_deal_data_${type}_success`,
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_generic_pipeline_coverage_custType_deal_${type}_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_deal_failure", error });
      }
    );
  },
  getPipelineCoverageDrilldown: (
    filters,
    key,
    timeFrame,
    deals20K,
    tableType
  ) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService
      .getPipelineCoverageGenericDrilldown(
        filters,
        key,
        timeFrame,
        deals20K,
        tableType
      )
      .then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
            a.ACV > b.ACV ? -1 : 1
          );
          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp =
              deals20K === "Deals20K"
                ? {
                    ...item,
                    OppNo: index + 1,
                  }
                : {
                    ...item,
                    OppNo: index + 1,
                  };
            return temp;
          });

          json.message = {
            ...json,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
  },

  getPipelineDealCategoryDrilldown: (
    filters,
    key,
    timeFrame,
    deals20K,
    tableType
  ) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService
      .getPipelineDealCategoryDrilldown(
        filters,
        key,
        timeFrame,
        deals20K,
        tableType
      )
      .then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
            a.ACV > b.ACV ? -1 : 1
          );
          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp =
              deals20K === "Deals20K"
                ? {
                    ...item,
                    OppNo: index + 1,
                  }
                : {
                    ...item,
                    OppNo: index + 1,
                  };
            return temp;
          });

          json.message = {
            ...json,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
  },

  getAttainmentSectionDrilldown: (filters, key, coverage) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getAttainmentSectionDrilldown(filters, key, coverage).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },

      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getWinACVDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getWinAcvMixAnalysisdrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  getExpectedAcvData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getExpectedAcvData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
});
const PipelineCoverageSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(PipelineCoverage);
export default PipelineCoverageSection;
