import * as d3 from "d3";
import "./D3Funnel.scss";
import {
  customHelperTooltip,
  customHelperTooltipUsingArray,
  customHelperTooltipUsingArrayWinRate,
  closedFunnelTooltip,
} from "../../util/CustomTooltips";
import { getResponsiveWidth } from "../NewFunnel/utils";
import {
  funnelWhatIfDataFormatter,
  funnelWhatIfDataFormatterFilter,
} from "../../util/dataFormatter";
import { store } from "../../util/store";

const D3Funnel = {};

D3Funnel.create = (el, config) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  console.log("D3FunnelData", config.Value_Label);
  console.log(config.data);
  d3.selection.prototype.last = function () {
    return d3.select(this.nodes()[this.size() - 1]);
  };

  // D3 Code to create the chart
  if (config.data) {
    config.data = config.data.filter((d) => d.label !== "-");
    if (config.tab === "What-if" && config.filterSelected) {
      config.data = funnelWhatIfDataFormatter(
        config.data,
        config.filterSelected
      );
    }

    console.log(config.data.filter((d) => d.label !== "-"));
    var wonLabel = config.data[config.data.length - 1].label;
    const handleChangeLocation = (location, stage, lastStage, key) => {
      config.handleChangeLocation(
        location,
        stage,
        lastStage,
        stage === wonLabel ? "cameTo" : key
      );
    };
    config.barColor = "#70ad47";
    config.barHeight =
      d3.select(el).node().getBoundingClientRect().height /
      (config.data.length * 2);
    config.margin = {
      top: 0,
      right: 80,
      bottom: 20,
      left: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 10
          : Math.max(...config.data.map((d) => d.label.length)) * 6 + 20
        : 100,
    };
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 100;
    }
    config.height = d3.select(el).node().getBoundingClientRect().height; //config.data ? config.data.length * 35 : 100//(config.barHeight * config.data.length + (config.data.length * 5) < 200 ? 200 : config.barHeight * config.data.length + (config.data.length * 5) + 60) - config.margin.top - config.margin.bottom;

    //x scale is 200% - 100% on left side, 100% on right side
    var x = d3.scaleBand().domain([0, 2]).range([0, config.width]);

    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr("height", d3.select(el).node().getBoundingClientRect().height);
    var chart = chartContainer
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );

    /*var middleLine = chart.append('line')
		 .attr('class', 'middle-line')
		 .attr('x1', config.width / 2)
		 .attr('y1', config.height)
		 .attr('x2', config.width / 2)
		 .attr('y2', 0);*/
    var tooltip = d3
      .select(el)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var rateRectsGroup = chart
      .selectAll("g.rates")
      .data(config.data)
      .enter()
      .append("g")
      .attr("class", "rates")
      .attr("transform", function (d, i) {
        var xPos,
          yPos = config.barHeight * i * 2;
        xPos = 0;
        return "translate(" + xPos + "," + yPos + ")";
      });

    var rateRectsFull = rateRectsGroup
      .append("rect")
      .attr("height", config.barHeight)
      .attr("width", config.width)
      .attr("class", "compare-bar")
      .attr("fill", "#bfbfbf")
      .style("cursor", "pointer")
      .on("click", (i) => {
        if (
          rateRectsFull.last()._groups[0][0].__data__.label === i.label &&
          config.tab !== "What-if"
        ) {
          handleChangeLocation("drilldownOnGraph", i.label, true, "lost");
        } else if (config.tab !== "What-if") {
          handleChangeLocation("drilldownOnGraph", i.label, false, "lost");
        }
      })
      .attr("transform", "translate(0, 0)");

    var rateRects = rateRectsGroup
      .append("rect")
      .attr("height", config.barHeight)
      .attr("width", function (d) {
        console.log(d.acvRate);
        return config.width * d.acvRate;
      })
      .attr("class", "actual-bar")
      .style("cursor", "pointer")
      .style("fill", config.barColor)
      .on("click", (i) => {
        if (
          rateRects.last()._groups[0][0].__data__.label === i.label &&
          config.tab !== "What-if"
        ) {
          handleChangeLocation("drilldownOnGraph", i.label, true, "lost");
        } else if (config.tab !== "What-if") {
          handleChangeLocation("drilldownOnGraph", i.label, false, "lost");
        }
      })
      .on("mousemove", (d) => {
        //console.log(d)
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            closedFunnelTooltip(
              wonLabel !== d.label ? "Opps Came to" : "",
              d.label,
              "acv",
              d.acv,
              config.barColor,
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //.attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(200).style("opacity", 0);
      })
      .attr("transform", function (d) {
        return (
          "translate(" + (config.width - config.width * d.acvRate) / 2 + ", 0)"
        );
      });

    var rateRectsLabel = rateRectsGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "category-value")
      .attr("transform", function (d, i) {
        var xPos = config.width / 2,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? config.barHeight / 2 - 5
              : config.barHeight / 2 - 7;
        return "translate(" + xPos + "," + yPos + ")";
      })
      //.attr('transform', 'translate(' + config.width / 2 + ', 0)')
      .append("tspan")
      .attr("dy", config.data.map((l) => l.label).length > 8 ? "0.87em" : "1em")
      .style("font-size", "0.80rem")
      .attr("font-weight", 600)
      .attr("cursor", "pointer")
      .attr("data-test", "funnel-value")
      .attr("fill", "white")
      .text(function (d) {
        return `${Currency_Symbol}${d3.format(",")(Math.round(d.acv))}`;
      })
      .on("mousemove", (d) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            closedFunnelTooltip(
              wonLabel !== d.label ? "Opps Came to" : "",
              d.label,
              "acv",
              d.acv,
              config.barColor,
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //.attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(200).style("opacity", 0);
      })
      .on("click", (i) => {
        if (
          rateRectsLabel.last()._groups[0][0].__data__.label === i.label &&
          config.tab !== "What-if"
        ) {
          handleChangeLocation("drilldownOnGraph", i.label, true, "lost");
        } else if (config.tab !== "What-if") {
          handleChangeLocation("drilldownOnGraph", i.label, false, "lost");
        }
      });

    var ratePercentageLabel = rateRectsGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "category-value-percentage")
      .attr("transform", function (d, i) {
        var xPos = config.width / 2,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? -config.barHeight
              : -config.barHeight * 0.9;
        return "translate(" + xPos + "," + yPos + ")";
      })
      //.attr('transform', 'translate(' + config.width / 2 + ', -20)')
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.80rem")
      .attr("font-weight", 600)
      .on("mousemove", (d, i) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            customHelperTooltipUsingArray(
              config.showTeamData
                ? [
                    {
                      helperText: `<span class="font-bold">${
                        config.data[i - 1].label
                      }</span> to <span class="font-bold">${
                        config.data[i].label
                      }</span> Conv. Rate`,
                      value: `${Math.round(d.diffacvRate * 100)}%`,
                    },
                    {
                      helperText: `Company Average `,
                      value: `${Math.round(d.diffacvRateCompany * 100)}%`,
                    },
                  ]
                : [
                    {
                      helperText: `<span class="font-bold">${
                        config.data[i - 1].label
                      }</span> to <span class="font-bold">${
                        config.data[i].label
                      }</span> Conv. Rate`,
                      value: `${Math.round(d.diffacvRate * 100)}%`,
                    },
                  ]
            )
          )
          // .html(customHelperTooltipUsingArray([{ helperText: '', value: '' }]))
          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) => {
            if (window.innerWidth > 4000) {
              // 4000 and above
              return `calc(${d3.event.pageX - 180 + "px"} - 73%)`;
            } else if (window.innerWidth > 3700) {
              // 4000 px to 3700px
              return `calc(${d3.event.pageX - 180 + "px"} - 63%)`;
            } else if (window.innerWidth > 3400) {
              //  3700 to 3400
              return `calc(${d3.event.pageX - 180 + "px"} - 57%)`;
            } else if (window.innerWidth > 3250) {
              // 3400 to  3250
              return `calc(${d3.event.pageX - 180 + "px"} - 50%)`;
            } else if (window.innerWidth > 2900) {
              // 3250 to 2900
              return `calc(${d3.event.pageX - 180 + "px"} - 40%)`;
            } else if (window.innerWidth > 2600) {
              // 2900 to 2600
              return `calc(${d3.event.pageX - 180 + "px"} - 30%)`;
            } else if (window.innerWidth > 2300) {
              // 2600 to 2300
              return `calc(${d3.event.pageX - 180 + "px"} - 20%)`;
            } else if (window.innerWidth > 2100) {
              // 1900 to 2300
              return `calc(${d3.event.pageX - 180 + "px"} - 10%)`;
            } else if (window.innerWidth > 1900) {
              // 1900 to 2300
              return `calc(${d3.event.pageX - 180 + "px"} - 5%)`;
            } else {
              // less than 1900
              return `calc(${d3.event.pageX - 180 + "px"})`;
            }
          })
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      })
      .text(function (d) {
        return `${Math.round(d.diffacvRate * 100)}% `;
      })
      .append("tspan")
      .style("fill", "#7f7f7f")
      .text(function (d) {
        return `${
          config.showTeamData
            ? "(" + Math.round(d.diffacvRateCompany * 100) + "%)"
            : ""
        }`;
      });

    var categoryLabels = rateRectsGroup
      .append("text")
      .attr("class", "bar-text")
      .attr(
        "text-anchor",
        d3.select(el).node().getBoundingClientRect().width < 400
          ? "start"
          : "end"
      )
      .attr("transform", function (d, i) {
        var xPos =
            d3.select(el).node().getBoundingClientRect().width < 400 ? 5 : -10,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? config.barHeight / 2 - 5
              : config.barHeight / 2 - 7;
        return "translate(" + xPos + "," + yPos + ")";
      })
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.80rem")
      .attr("font-weight", 400)
      .attr(
        "fill",
        d3.select(el).node().getBoundingClientRect().width < 400
          ? "white"
          : "black"
      )
      .style("cursor", "pointer")
      .on("click", (i) => {
        if (
          categoryLabels.last()._groups[0][0].__data__.label === i.label &&
          config.tab !== "What-if"
        ) {
          handleChangeLocation("drilldownOnGraph", i.label, true, "lost");
        } else if (config.tab !== "What-if") {
          handleChangeLocation("drilldownOnGraph", i.label, false, "lost");
        }
      })
      .on("mousemove", function (d, i) {
        d3.select(this).transition().style("opacity", "0.5");
      })
      .on("mouseout", function (d, i) {
        d3.select(this).transition().style("opacity", "1");
      })
      .text(function (d) {
        return d.label;
      });

    var categoryLabels2 = rateRectsGroup
      .append("text")
      .attr("class", "bar-text")
      .style("cursor", "pointer")
      .on("click", (i) => {
        if (
          categoryLabels.last()._groups[0][0].__data__.label === i.label &&
          config.tab !== "What-if"
        ) {
          handleChangeLocation("drilldownOnGraph", i.label, true, "lost");
        } else if (config.tab !== "What-if") {
          handleChangeLocation("drilldownOnGraph", i.label, false, "lost");
        }
      })
      .attr("text-anchor", "start")
      .attr("transform", function (d, i) {
        var xPos = config.width + 5,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? config.barHeight / 2 - 5
              : config.barHeight / 2 - 7;
        return "translate(" + xPos + "," + yPos + ")";
      })
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.80rem")
      .attr("font-weight", 600)
      .attr("fill", "black")
      .on("mousemove", (d) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            customHelperTooltipUsingArray(
              config.showTeamData
                ? [
                    {
                      helperText: `${config.Win_Rate_Prefix} from <span class="font-bold">${d.label}</span>`,
                      value: `${
                        Math.min(...config.data.map((d) => d.acv)) === 0 ||
                        d.acv === 0
                          ? 0
                          : Math.round(
                              (Math.min(...config.data.map((d) => d.acv)) /
                                d.acv) *
                                100
                            )
                      }%`,
                    },
                    {
                      helperText: `Company Average `,
                      value: `${
                        Math.min(...config.data.map((d) => d.acvCompay)) ===
                          0 || d.acvCompay === 0
                          ? "0%"
                          : Math.round(
                              (Math.min(
                                ...config.data.map((d) => d.acvCompay)
                              ) /
                                d.acvCompay) *
                                100
                            )
                      }%`,
                    },
                  ]
                : [
                    {
                      helperText: `${config.Win_Rate_Prefix} from <span class="font-bold">${d.label}</span>`,
                      value: `${
                        Math.min(...config.data.map((d) => d.acv)) === 0 ||
                        d.acv === 0
                          ? 0
                          : Math.round(
                              (Math.min(...config.data.map((d) => d.acv)) /
                                d.acv) *
                                100
                            )
                      }%`,
                    },
                  ]
            )
          )

          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX - 50)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      })
      .text(function (d) {
        return `${
          Math.min(...config.data.map((d) => d.acv)) === 0 || d.acv === 0
            ? 0
            : Math.round(
                (Math.min(...config.data.map((d) => d.acv)) / d.acv) * 100
              )
        }% `;
      })
      .append("tspan")
      .style("fill", "#7f7f7f")
      .text(function (d) {
        return `${
          config.showTeamData
            ? Math.min(...config.data.map((d) => d.acvCompay)) === 0 ||
              d.acvCompay === 0
              ? "(0%)"
              : "(" +
                Math.round(
                  (Math.min(...config.data.map((d) => d.acvCompay)) /
                    d.acvCompay) *
                    100
                ) +
                "%)"
            : ""
        }`;
      });
  }
};

D3Funnel.update = (el, config) => {
  config.barColor = "#4caf50";
  config.barHeight = 20;
  config.margin = {
    top: 0,
    right: 20,
    bottom: 20,
    left: 100,
  };
  if (!config.width) {
    config.width =
      d3.select(el).node().getBoundingClientRect().width -
      config.margin.left -
      config.margin.right;
  }
  config.height = 300;
  // D3 Code to update the chart
  var chartContainer = d3.select(el);
  var chart = chartContainer.select("g");
  var rateRectsGroup = chart
    .selectAll("g.rates")
    .data(config.data)
    .exit()
    .remove()
    .enter();

  var rateRectsFull = rateRectsGroup
    .append("rect")
    .attr("height", config.barHeight)
    .attr("width", config.width)
    .attr("class", "compare-bar")
    .attr("fill", "#ccc")
    .attr("transform", "translate(0, 0)");

  var rateRects = rateRectsGroup
    .append("rect")
    .attr("height", config.barHeight)
    .attr("width", function (d) {
      return config.width * d.acvRate;
    })
    .attr("class", "actual-bar")
    .style("fill", config.barColor)
    .attr("transform", function (d) {
      return (
        "translate(" + (config.width - config.width * d.acvRate) / 2 + ", 0)"
      );
    });

  var rateRectsLabel = rateRectsGroup
    .append("text")
    .attr("text-anchor", "middle")
    .attr("class", "category-value")
    .attr("transform", "translate(" + config.width / 2 + ", 0)")
    .append("tspan")
    .attr("dy", "1em")
    .text(function (d) {
      return `${d.acv} - ${(d.acvRate * 100).toFixed(2)}%`;
    });

  rateRectsGroup
    .select("rect.actual-bar")
    .transition()
    .duration(500)
    .attr("width", function (d) {
      return config.width * d.acvRate;
    })
    .attr("transform", function (d) {
      return (
        "translate(" + (config.width - config.width * d.acvRate) / 2 + ", 0)"
      );
    });

  rateRectsGroup
    .select("text.category-value")
    .select("tspan")
    .transition()
    .duration(500)
    .text(function (d) {
      return `${d.acv} - ${(d.acvRate * 100).toFixed(2)}%`;
    });
};

D3Funnel.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll("svg").remove();
  d3.select(".tooltip").remove();
};

export default D3Funnel;
