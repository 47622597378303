import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  withStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  getUniqueValues,
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getDefaultSalesType,
} from "../../util/customFunctions";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ScoreCardDrilldownBody from "../../RepPerformance/ScoreCard/ScoreCardDrilldownBody";
import WaterfallDrilldownBody from "../Waterfall/WaterfallDrilldownBody";
import WinAcvDrillDownDrilldownBody from "./WinAcvDrillDown";
import Drilldown from "../../components/Drilldown/Drilldown";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import FiscalQuarterFilter from "../../Filters/FiscalQuarterFilter";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import {
  colorsForRenewal,
  renewalsRow,
} from "../../CustomerSuccess/Pages/RenewalsCompleted/components/utils";

import RegionFilter from "../../Filters/Region_Filter";
import OpportunityType from "../../Filters/OpportunityType";
import Acct_SegmentFilter from "../../Filters/Acct_SegmentFilter";
import FilterContainer from "../../Filters/FilterContainer";
import { getMixKey } from "../../util/generateHeadings";
import { deSnake } from "../../util/dataFormatter";
import YearQuarterFilterCombo from "../../Filters/YearQuarterFilterCombo";
import DynamicTableSegment from "../../components/DyanmicTableSegment";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
import { replaceAll } from "../../components/xlsx-export/LabelCange";
import { replaceDynamicValueLabel } from "../../util/utils";
import NumberFormat from "react-number-format";
import YearMonthFilterCombo from "../../Filters/QuarterMonthYearFilter";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",

    justifyContent: "center",
    width: "95%",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "90%",
    margin: "auto",
    marginTop: "1rem",
  },
});
class WinACVAnalysis extends Component {
  state = {
    wonACVMix: ["All"],
    key: "",
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    closed_fiscal_quarter: ["All"],
    Acct_Vertical: ["All"],
    Apps_Cluster: ["All"],
    closed_fiscal_year: ["All"],
    Closed_Year_Month: ["All"],
    disabled: ["year", "month", "quarter"],
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    activeTimeFilter: "closed_fiscal_quarter",
    filtersForDrilldownCYQ: ["All"],
    ACV_Range: ["All"],
    Record_Type_Name__c: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    secondLevelLocation: null,
    Lead_Source: ["All"],
    Product_Line: ["All"],
    Partner_Opp: ["All"],
    Opportunity_Record_Type: ["All"],
    Account_Designation__c: ["All"],
    AGE_RANGE: ["All"],
    acv: 0,
    acvlt: 0,
  };
  componentDidMount() {
    this.props.setTable("");
    this.props.getfunnelCompareFilters(this.props.company);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      let solutioncat = ["All"];
      dealsTypes = getDefaultSalesType(
        this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
        "Type"
      );
      solutioncat = getDefaultSalesType(
        this.props.funnelCompareFilters.filter(
          (s) => s.type === "Solution_Cat"
        ),
        "Solution_Cat"
      );
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }
      this.setState({
        ...prevState,
        closed_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "closed_fiscal_quarter"
        ),
        filtersForDrilldownCYQ: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "closed_fiscal_quarter"
        ),
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        Solution_Cat: solutioncat,
      });
    }
  }
  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          Apps_Cluster: "Apps_Cluster",
          ACV_Range: "ACV_Range",
          Product_Line: "Product_Line",
        }
      : this.props.company === "C0008"
      ? {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Product_Line",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
          Partner_Opp: "Partner_Opp",
          Opportunity_Record_Type: "Opportunity_Record_Type",
          Account_Designation__c: "Account_Designation__c",
          AGE_RANGE: "AGE_RANGE",
          Record_Type_Name__c: "Record_Type_Name__c",
        };

  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleChangeLocation = (location, data, type, quarter) => {
    console.log(location, data, type, quarter, "filter-drilldown");

    const quarterFilter = this.props.currentFilters.filter(
      (item) => item.name === quarter
    );
    if (location !== "overview") {
      this.props.setTable("WinAcv");
      let filters;
      if (type === "Acct_Segment_Rollup") {
        filters = [
          {
            name: quarter,
            value:
              data.cell !== "Total"
                ? [data.cell]
                : quarterFilter.length > 0
                ? quarterFilter[0].value
                : this.props.funnelCompareFilters !== "" &&
                  this.props.funnelCompareFilters
                    .filter((y) => y.type === quarter && y[quarter] !== "")
                    .map((y) => y[quarter])
                    .filter((item) => item !== null),
            string: true,
          },
          { name: type, value: [data.row], string: true },
        ];
      } else {
        const uniqueStages = this.props.winACVMixAnalysisData
          .filter((item) => item.type === type)[0]
          .data.map((item) => item[type])
          .filter(getUniqueValues);
        filters = [
          {
            name: quarter,
            value:
              data.cell !== "Total"
                ? [data.cell]
                : quarterFilter.length > 0
                ? quarterFilter[0].value
                : this.props.funnelCompareFilters !== "" &&
                  this.props.funnelCompareFilters
                    .filter((y) => y.type === quarter && y[quarter] !== "")
                    .map((y) => y[quarter])
                    .filter((item) => item !== null),
            string: true,
          },
          {
            name: type,
            value:
              data.row !== "Total"
                ? data.row === "Others" && type === "Acct_Industry"
                  ? this.props.winACVMixAnalysisData.filter(
                      (item) => item.type === "Acct_Industry"
                    )[0].otherIndustries
                  : [data.row]
                : uniqueStages.includes("Others") && type === "Acct_Industry"
                ? [
                    ...uniqueStages.filter((c) => c !== "Others"),
                    ...this.props.winACVMixAnalysisData.filter(
                      (item) => item.type === "Acct_Industry"
                    )[0].otherIndustries,
                  ]
                : uniqueStages,
            string: true,
          },
        ];
      }

      let localFilters = this.props.currentFilters;
      console.log(localFilters);
      filters = [...filters, ...localFilters];
      if (type === "Apps_Cluster") {
        this.props.getPbiSummaryDrilldown(
          filters.filter((i) => i.name !== "Apps_Cluster"),
          {
            appsCluster: data.row !== "Total" ? data.row : "All",
            oppStatus: "won",
            type: "appsCluster",
          }
        );
      } else if (type === "Product_Line") {
        this.props.getPbiSummaryDrilldown(
          [
            ...filters.filter((i) => i.name !== "Product_Line"),
            ...localFilters.filter((i) => i.name === "Product_Line"),
          ],
          {
            appsCluster: data.row !== "Total" ? data.row : "All",
            oppStatus: "won",
            type: "productLine",
          }
        );
      } else {
        this.props.getWinACVDrilldown(filters);
      }

      let filtersForDrilldown = [
        {
          title:
            type === "Acct_Segment_Rollup"
              ? "Segment"
              : replaceDynamicValueLabel(
                  this.props.winACVMixAnalysisData.filter(
                    (i) => i.type === type
                  )[0].label,
                  this.props.Value_Label
                ),
          value: data.row !== "Total" ? data.row : "All",
        },
        {
          title:
            quarter === "Closed_Year_Month" ? "Closed Month" : deSnake(quarter),
          value:
            data.cell !== "Total"
              ? data.cell
              : this.state.filtersForDrilldownCYQ,
        },
      ];

      if (!this.state.Type.includes("All")) {
        filtersForDrilldown = [
          ...filtersForDrilldown,
          {
            title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
            value: this.state.Type,
          },
        ];
      }
      this.setState({
        ...this.state,
        location: location,
        filtersForDrilldown: filtersForDrilldown.map((it) => ({
          ...it,
          title: deSnake(it.title),
        })),
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    const processValue = (currentValue, newValue) => {
      if (newValue.length === 0) {
        return ["All"];
      }
      if (currentValue.length === 1 && currentValue.includes("All")) {
        const filteredValue = newValue.filter((v) => v !== "All");
        return filteredValue.length > 0 ? filteredValue : ["All"];
      }
      if (newValue.includes("All")) {
        return ["All"];
      }
      return newValue;
    };

    const activeTimeFilter = [
      "closed_fiscal_year",
      "closed_fiscal_quarter",
      "Closed_Year_Month",
    ].includes(name)
      ? name
      : this.state.activeTimeFilter;

    this.setState({
      ...this.state,
      [name]: name === "rep" ? [value] : processValue(this.state[name], value),
      activeTimeFilter,
    });
  };

  handleMixType = (e, value) => {
    this.setState({
      ...this.state,
      wonACVMix: [e.target.value],
    });
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
      secondLevelLocation: "",
    });
    this.props.setTable("");
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    var filterAcvValues = [
      ...this.props.lkpFilters,
      "Closed_Year_Month",
    ].filter((item) => item !== "acv" && item !== "acvlt");
    console.log("new_acv", filterAcvValues);

    let filters = filterAcvValues
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "team"
              ? true
              : this.props.funnelCompareFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelCompareFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    if (this.state.acv !== null && this.state.acv !== 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    if (!this.state.Record_Type_Name__c.includes("All")) {
      filters = [
        ...filters,
        {
          name: "Record_Type_Name__c",
          value: this.state.Record_Type_Name__c,
          string: true,
        },
      ];
    }
    this.setState({
      ...this.state,
      filtersForDrilldownCYQ: this.passFiltersForDrilldown(),
    });
    console.log(this.state.activeTimeFilter, "activeTimeFilter");
    this.props.getWinACVMixData(this.state.activeTimeFilter, filters);
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_quarter];
    }

    if (!this.state.closed_fiscal_year.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_year];
    }
    if (
      this.state.closed_fiscal_year.includes("All") &&
      this.state.closed_fiscal_quarter.includes("All")
    ) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };
  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.gridContainer}>
        {/* <Grid
          className={classes.grid}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Won ACV Mix Analysis
          </Typography>
          <Typography variant="body1">
            Analyze won ACV across multiple dimensions to spot trends and
            patterns.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          `Won ${this.props.Value_Label} Mix Analysis`,
          `Analyze won ${this.props.Value_Label} across multiple dimensions to spot trends and patterns.`
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid
            className={classes.grid}
            item
            container
            justify="flex-start"
            xs={12}
          >
            <Grid container item style={{ padding: 10 }} xs={4}>
              <YearMonthFilterCombo
                activeTimeFilter={this.state.activeTimeFilter}
                closedFiscalQuarterState={this.state.closed_fiscal_quarter}
                closedFiscalYear={this.state.closed_fiscal_year}
                Closed_Year_Month={this.state.Closed_Year_Month}
                handleChange={this.handleChange}
                funnelCompareFilters={this.props.funnelCompareFilters}
                tableID={"filter_cfq"}
              />
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Acct_Vertical
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : this.props.company === "C0008"
                        ? "Product Line"
                        : "Solution Category:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Acct_Vertical]}
                      onChange={this.handleChange}
                      name={this.variableCols.Acct_Vertical}
                      data-test={this.variableCols.Acct_Vertical}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Acct_Vertical &&
                              y[this.variableCols.Acct_Vertical] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Acct_Vertical] <
                            b[this.variableCols.Acct_Vertical]
                              ? -1
                              : a[this.variableCols.Acct_Vertical] >
                                b[this.variableCols.Acct_Vertical]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Acct_Vertical]}
                              value={y[this.variableCols.Acct_Vertical]}
                            >
                              {
                                y[
                                  this.props.company === "C0008"
                                    ? "label"
                                    : this.variableCols.Acct_Vertical
                                ]
                              }
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
                id="button_go"
              >
                GO
              </Button>
            </Grid>
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Opportunity Type:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003" ? "Region:" : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Adding ACV Range Filter */}
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.ACV_Range
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.Value_Label} Range
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.ACV_Range]}
                      onChange={this.handleChange}
                      name={this.variableCols.ACV_Range}
                      data-test={this.variableCols.ACV_Range}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.ACV_Range &&
                              y[this.variableCols.ACV_Range] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.ACV_Range]}
                              value={y[this.variableCols.ACV_Range]}
                            >
                              {y[this.variableCols.ACV_Range]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {this.props.company === "C0013" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.AGE_RANGE
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Age Range:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.AGE_RANGE]}
                        onChange={this.handleChange}
                        name={this.variableCols.AGE_RANGE}
                        data-test={this.variableCols.AGE_RANGE}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {console.log(
                          this.props.funnelCompareFilters
                            .filter(
                              (y) => y.type === this.variableCols.AGE_RANGE
                            )
                            .map((y) => y[this.variableCols.AGE_RANGE])
                        )}
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.AGE_RANGE &&
                                y[this.variableCols.AGE_RANGE] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.AGE_RANGE]}
                                value={y[this.variableCols.AGE_RANGE]}
                              >
                                {y[this.variableCols.AGE_RANGE]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {["C0011", "C0015", "C0001"].includes(this.props.company) && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Lead_Source
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Lead Source:
                      </InputLabel>
                      <Select
                        data-test="Lead_Source"
                        value={this.state[this.variableCols.Lead_Source]}
                        onChange={this.handleChange}
                        name={this.variableCols.Lead_Source}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Lead_Source &&
                                y[this.variableCols.Lead_Source] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Lead_Source]}
                                value={y[this.variableCols.Lead_Source]}
                              >
                                {y[this.variableCols.Lead_Source]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0010" && (
              <>
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Account_Designation__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Account Designation:
                        </InputLabel>
                        <Select
                          data-test="Account_Designation__c"
                          value={
                            this.state[this.variableCols.Account_Designation__c]
                          }
                          onChange={this.handleChange}
                          name={this.variableCols.Account_Designation__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols.Account_Designation__c &&
                                  y[
                                    this.variableCols.Account_Designation__c
                                  ] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={
                                    y[this.variableCols.Account_Designation__c]
                                  }
                                  value={
                                    y[this.variableCols.Account_Designation__c]
                                  }
                                >
                                  {y[this.variableCols.Account_Designation__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {this.props.company === "C0013" && (
              <>
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Partner_Opp
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Partner Opp:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={this.state[this.variableCols.Partner_Opp]}
                          onChange={this.handleChange}
                          name={this.variableCols.Partner_Opp}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Partner_Opp &&
                                  y[this.variableCols.Partner_Opp] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Partner_Opp]}
                                  value={y[this.variableCols.Partner_Opp]}
                                >
                                  {y[this.variableCols.Partner_Opp]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={2}
                  md={2}
                  lg={2}
                  style={{ paddingTop: 15, padding: 10 }}
                >
                  {/* Opportunity Record Type:*/}
                  <Grid
                    container
                    item
                    xs={12}
                    style={{ paddingTop: 8 }}
                    spacing={2}
                    component={Paper}
                  >
                    <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Opportunity_Record_Type
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opportunity Record Type:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={
                            this.state[
                              this.variableCols.Opportunity_Record_Type
                            ]
                          }
                          onChange={this.handleChange}
                          name={this.variableCols.Opportunity_Record_Type}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols.Opportunity_Record_Type &&
                                  y[
                                    this.variableCols.Opportunity_Record_Type
                                  ] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={
                                    y[this.variableCols.Opportunity_Record_Type]
                                  }
                                  value={
                                    y[this.variableCols.Opportunity_Record_Type]
                                  }
                                >
                                  {y[this.variableCols.Opportunity_Record_Type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {this.props.company === "C0008" && (
              <>
                {" "}
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.cross_sell1__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Sales Type:
                        </InputLabel>
                        <Select
                          data-test="cross_sell1__c"
                          value={this.state[this.variableCols.cross_sell1__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.cross_sell1__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.cross_sell1__c &&
                                  y[this.variableCols.cross_sell1__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.cross_sell1__c]}
                                  value={y[this.variableCols.cross_sell1__c]}
                                >
                                  {y[this.variableCols.cross_sell1__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[this.variableCols.Type].includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Type:
                        </InputLabel>
                        <Select
                          data-test="Type"
                          value={this.state[this.variableCols.Type]}
                          onChange={this.handleChange}
                          name={this.variableCols.Type}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Type &&
                                  y[this.variableCols.Type] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Type]}
                                  value={y[this.variableCols.Type]}
                                >
                                  {y[this.variableCols.Type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {this.props.company === "C0003" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Product_Line
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Product Cat.:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.Product_Line]}
                        onChange={this.handleChange}
                        name={this.variableCols.Product_Line}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Product_Line &&
                                y[this.variableCols.Product_Line] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Product_Line]}
                                value={y[this.variableCols.Product_Line]}
                              >
                                {y["label"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*ACV >= and ACV <= */}

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={4}
              md={4}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 12 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* C0010 */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Record_Type_Name__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        value={
                          this.state[this.variableCols.Record_Type_Name__c]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Record_Type_Name__c}
                        data-test={this.variableCols.Record_Type_Name__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type ===
                                  this.variableCols.Record_Type_Name__c &&
                                y[this.variableCols.Record_Type_Name__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Record_Type_Name__c]}
                                value={y[this.variableCols.Record_Type_Name__c]}
                              >
                                {y[this.variableCols.Record_Type_Name__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* C0017 */}
            {this.props.company === "C0017" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[this.variableCols.Type].includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opp. Type:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Type]}
                        onChange={this.handleChange}
                        name={"Type"}
                        data-test={this.variableCols.Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        <Grid className={classes.grid} item xs={12}>
          <Grid>
            {this.props.winACVMixAnalysisData !== "" ? (
              this.props.winACVMixAnalysisData.filter(
                (item) =>
                  item.type === "closed_fiscal_year" ||
                  item.type === "Closed_Year_Month" ||
                  item.type === "closed_fiscal_quarter"
              )[0] !== undefined ? (
                <Grid container>
                  {this.props.winACVMixAnalysisData
                    .filter(
                      (item) =>
                        item.type !== "closed_fiscal_year" &&
                        item.type !== "Closed_Year_Month" &&
                        item.type !== "closed_fiscal_quarter"
                    )
                    .map(({ type, data, label, UICols, timeline }, i) => {
                      return (
                        <Grid key={i} className={classes.grid} item xs={12}>
                          <DataVisualizer
                            chartName={`WinAcvAnalysis by ${type}`}
                            title={`Won ${
                              this.props.Value_Label
                            } mix by ${replaceAll(
                              label,
                              "acv",
                              `${this.props.Value_Label}`
                            )}`}
                            UICols={UICols}
                            type={type}
                            chartType="acv"
                            Value_Label={this.props.Value_Label}
                            winACVMixAnalysisData={
                              type === "Apps_Cluster" || type === "Product_Line"
                                ? data.filter((i) => i[type] !== "Total")
                                : data
                            }
                            handleChangeLocation={this.handleChangeLocation}
                            quarter={timeline}
                            tableComponent={
                              type === "Acct_Segment" ? (
                                <DynamicTableSegment
                                  type={type}
                                  quarter={timeline}
                                  winAcvData={data}
                                  Value_Label={this.props.Value_Label}
                                  handleChangeLocation={
                                    this.handleChangeLocation
                                  }
                                />
                              ) : undefined
                            }
                            unit="acv"
                            company={this.props.company}
                            clusterTotal={
                              type === "Apps_Cluster" || type === "Product_Line"
                                ? data.filter((i) => i[type] === "Total")
                                : []
                            }
                            sortByTotal={type !== "Acct_Segment" ? true : false}
                            label={label}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                <Grid container>
                  <Typography variant="h3">
                    Something went wrong. Unknown timeline for won acv mix
                    analysis
                  </Typography>
                </Grid>
              )
            ) : (
              <Card>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Won Opportunities"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={"Won Opportunities"}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    winAcvMixFilters,
    winAcvAnalysisData,
    user,
    drilldownOnGraphData,
    currentFilters,
    lkpFilters,
    ExpectedAcvData,
  } = state.app;
  console.log("data_debug", winAcvAnalysisData);

  return {
    funnelCompareFilters: winAcvMixFilters,
    winACVMixAnalysisData: winAcvAnalysisData,
    company: user.company,
    Value_Label: user.Value_Label,
    Currency_Symbol: user.Currency,
    drilldownOnGraphData,
    currentFilters,
    lkpFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },

  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_win_acv_filters_request" });
    appService.getWinACVMixAnalysisFilters().then(
      (json) => {
        var lkpFilters = Object.values(
          json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
        );
        json.message = json.message.computedFilters;
        json.lkpFilters = lkpFilters;
        dispatch({ type: "get_win_acv_mix_filters_success", json });
        dispatch({ type: "get_win_acv_mix_analysis_data_request" });
        let filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        const deaultOppType = getDefaultSalesType(
          json.message.filter((i) => i.type === "Type"),
          "Type"
        );
        const defaultSolutionCat = getDefaultSalesType(
          json.message.filter((i) => i.type === "Solution_Cat"),
          "Solution_Cat"
        );
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }

        filters =
          deaultOppType[0] === "All"
            ? filters
            : [...filters, { name: "Type", value: deaultOppType }];
        filters =
          defaultSolutionCat[0] === "All"
            ? filters
            : [...filters, { name: "Solution_Cat", value: defaultSolutionCat }];
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getWinAcvMixAnalysisData(
            "closed_fiscal_quarter",
            json.message.length === 0 ? [] : filters
          )
          .then(
            (json) => {
              dispatch({ type: "get_win_acv_mix_analysis_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_win_acv_mix_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_win_acv_mix_analysis_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_win_acv_mix_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_win_acv_mix_filters_failure", error });
      }
    );
  },
  getWinACVDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getWinAcvMixAnalysisdrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getPbiSummaryDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    console.log(meta);
    appService.getPbiSummaryDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.result.recordsets[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.result.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getWinACVMixData: (key, filters = []) => {
    dispatch({ type: "get_win_acv_mix_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getWinAcvMixAnalysisData(key, filters).then(
      (json) => {
        dispatch({ type: "get_win_acv_mix_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_win_acv_mix_analysis_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_win_acv_mix_analysis_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedWinACVAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WinACVAnalysis));
export default connectedWinACVAnalysis;
