import React from "react";
import { withSnackbar } from "notistack";

import {
  Avatar,
  Button,
  Box,
  IconButton,
  InputAdornment,
  CardMedia,
  Typography,
  colors,
  TextField,
  Fade,
  Grid,
  Checkbox,
  Collapse,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { appService } from "../App/app.service";
import * as Yup from "yup";
import { Formik } from "formik";
import Logo from "../components/Logo";
import ViewIcon from "@material-ui/icons/Visibility";
import OffIcon from "@material-ui/icons/VisibilityOff";
import { withRouter } from "react-router-dom";
import {
  passwordValidation,
  passwordValidationLabel,
} from "../Settings/AccountSettings/password-validaton";
import ResetPasswordFormikComponent from "../App/ResetPasswordFormikComponent";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ChangePasswordForm from "./ChangePasswordForm";
import AuthTemplate from "./AuthTemplate";

const viz_url = process.env.REACT_APP_VIZ_URL;

const styles = (theme) => ({
  root: {
    backgroundColor: "#4472c4",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
  },
  container: {
    height: "100%",
  },
  media: {
    borderRadius: 4,
    height: "60%",
    marginTop: "10%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pictureDiv: {
    textAlign: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    backgroundSize: "cover",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0,0,0,0), rgba(255,255,255,1)), url(https://skygeniwebapp.blob.core.windows.net/thumbnails/sales.jpg)",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: "50px",
  },
  formContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    margin: 30,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
  },
  errorMessageStyle: {
    textAlign: "left",
    paddingTop: "10px",
    color: "red",
  },
  formStyle: {
    padding: "10%",
    paddingTop: "0%",
  },
  rememberMeCheck: {
    padding: 0,
    marginRight: 10,
  },
  backButtonContainer: {
    textAlign: "left",
  },
  backButton: {
    textTransform: "none",
    padding: "0%",
    textAlign: "left",
    color: "#4472c4",
    justifyContent: "flex-start",
  },
});

class LoginView extends React.Component {
  state = {
    location: "login",
    forgotPassEmail: "",
    errorMessage: this.props.alert.returnCode ? this.props.alert.message : "",
    open: true,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loggedIn) {
      const redirectTo = localStorage.getItem("redirectAfterLogin");
      // console.log(redirectTo, 'XYZZZZZ')
      if (redirectTo) {
        localStorage.removeItem("redirectAfterLogin");
        this.props.history.push(redirectTo);
      } else {
        this.props.history.push("/");
      }
    }
    if (
      prevProps.forgotPasswordSuccess === false &&
      this.props.forgotPasswordSuccess === true
    ) {
      this.setState({
        ...this.state,
        location: "changePass",
      });
    }
    if (prevProps.forgotPassToken === "" && this.props.forgotPassToken !== "") {
      if (this.state.location === "forgotPass") {
        this.setState({
          ...this.state,
          location: "changePass",
        });
      }
    }
    if (
      this.props.alert !== "" &&
      this.props.alert.message ===
        "Password changed. Please sign in with new password."
    ) {
      if (this.state.location === "changePass") {
        this.setState({
          ...this.state,
          location: "login",
          forgotPassEmail: "",
        });
      }
    }

    if (this.props.alert.id !== prevProps.alert.id) {
      console.log(this.props.alert);
      /*
      For error code 600 // inactivity timeout happens only to alrady logged in users
1) Logout user
2) Show message as toast
      For error code 601 // Unknown company happens when trying to
1) Show message as box with close button
For error code 602 // Missing user, known company
1) Show message as box with close button
For error code 603 // Inactive, known user
1) Show message as box with close button
      */
      if (
        this.props.alert.returnCode == 604 ||
        this.props.alert.returnCode == 603 ||
        this.props.alert.returnCode == 601 ||
        this.props.alert.returnCode == 602 ||
        this.props.alert.returnCode == 600 ||
        this.props.alert.returnCode == 999
      ) {
        console.log(this.props.alert.returnCode);
        this.setState({
          ...this.state,
          open: true,
          errorMessage: this.props.alert.message,
        });
      } else if (this.props.alert.returnCode) {
        console.log(this.props.alert.returnCode);
        this.props.enqueueSnackbar(
          this.props.alert.message || "Something went wrong",
          {
            variant: this.props.alert.type || "error",
          }
        );
      }
    }
    if (this.props.persistentAlert !== "" && prevProps.persistentAlert === "") {
      this.persistentAlert = this.props.enqueueSnackbar(
        this.props.persistentAlert.message,
        {
          variant: this.props.persistentAlert.type,
          persist: true,
        }
      );
    }
  };

  handleChangeLocation = (location) => {
    if (location === "login") {
      this.setState({
        ...this.state,
        location: "login",
        forgotPassEmail: "",
        errorMessage: "",
        open: true,
      });
      return;
    }
    this.setState({ ...this.state, location: location, errorMessage: "" });
  };
  componentDidMount = () => {
    if (this.props.loggedIn) {
      const redirectTo = localStorage.getItem("redirectAfterLogin");
      console.log(redirectTo, "XYZZZZZ");
      if (redirectTo) {
        localStorage.removeItem("redirectAfterLogin");
        this.props.history.push(redirectTo);
      } else {
        this.props.history.push("/");
      }
      // this.props.history.push("/");
    }
  };
  onLoginSubmit = (values) => {
    this.setState({
      ...this.state,
      errorMessage: "",
      open: false,
    });
    this.props.login(values.email, values.password, values.checked);
  };
  render() {
    const { classes } = this.props;
    const { location, errorMessage, open } = this.state;
    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.container}
          alignItems="center"
          justify="center"
        >
          <Fade in={true} timeout={350}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box className={classes.formContainer}>
                {location === "login" && (
                  <AuthTemplate childrenStyle={{}}>
                    <Grid item>
                      <LoginForm
                        onSubmit={this.onLoginSubmit}
                        classes={classes}
                        errorMessage={errorMessage}
                        open={open}
                        setErrorMessage={(msg) =>
                          this.setState({ errorMessage: msg, open: false })
                        }
                        persistentAlert={this.props.persistentAlert}
                        handleChangeLocation={this.handleChangeLocation}
                      />
                    </Grid>
                  </AuthTemplate>
                )}
                {location === "forgotPass" && (
                  <AuthTemplate
                    title="Forgot Password"
                    subtitle="Please enter the email address associated with  your SkyGeni account."
                    childrenStyle={{}}
                  >
                    <ForgotPasswordForm
                      onSubmit={(values) => {
                        this.props.forgotPass(values.email);
                        this.setState({
                          ...this.state,
                          forgotPassEmail: values.email,
                          open: false,
                        });
                      }}
                      classes={classes}
                      errorMessage={errorMessage}
                      open={open}
                      handleChangeLocation={this.handleChangeLocation}
                      setErrorMessage={(msg) =>
                        this.setState({ errorMessage: msg, open: false })
                      }
                    />
                  </AuthTemplate>
                )}
                {location === "changePass" && (
                  <AuthTemplate childrenStyle={{ paddingTop: "1rem" }}>
                    <ChangePasswordForm
                      forgotPassEmail={this.state.forgotPassEmail}
                      changePass={this.props.changePass}
                      errorMessage={errorMessage}
                      open={open}
                      setState={this.setState}
                      persistentAlert={this.persistentAlert}
                      forgotPass={this.props.forgotPass}
                      classes={classes}
                      alert={this.props.alert}
                      changePasswordSuccess={this.props.changePasswordSuccess}
                      handleChangeLocation={this.handleChangeLocation}
                    />
                  </AuthTemplate>
                )}
              </Box>
            </Grid>
          </Fade>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.pictureDiv}
          >
            <Logo width="300px" height="100px" />
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    alert,
    persistentAlert,
    forgotPassToken,
    checked,
    loggedIn,
    changePasswordSuccess,
    forgotPasswordSuccess,
  } = state.app;

  return {
    alert,
    persistentAlert,
    forgotPassToken,
    checked,
    loggedIn,
    changePasswordSuccess,
    forgotPasswordSuccess,
  };
}

const mapDispatchToProps = (dispatch) => ({
  login: (username, password, checked) => {
    dispatch({ type: "login_request", username });
    appService.login(username, password).then(
      (json) => {
        if (checked) {
          localStorage.setItem(
            "skygeni-user-checked",
            JSON.stringify(username)
          );
          dispatch({ type: "add_checked", username });
        } else {
          localStorage.removeItem("skygeni-user-checked");
          dispatch({ type: "remove_checked" });
        }
        dispatch({ type: "login_success", json });
      },
      (error) => {
        console.log(error, "updating_app_error");
        if (error.isLoggedIn === false) {
          dispatch({ type: "udpating_app" });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (typeof error === "object")
          dispatch({ type: "login_failure", error });
        else dispatch({ type: "login_failure", error });
      }
    );
  },
  forgotPass: (username) => {
    dispatch({ type: "forgot_pass_request", username });
    appService.forgotPass(username).then(
      (json) => {
        dispatch({ type: "forgot_pass_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "forgot_pass_failure",
            error,
          });
        else dispatch({ type: "forgot_pass_failure", error });
      }
    );
  },
  changePass: (code, password, token, email) => {
    dispatch({ type: "change_pass_request" });
    appService.changePass(code, password, token, email).then(
      (json) => {
        dispatch({ type: "change_pass_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "change_pass_failure",
            error,
          });
        else dispatch({ type: "change_pass_failure", error });
      }
    );
  },
});

const connectedLoginView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withSnackbar(LoginView))));
export { connectedLoginView as LoginView };
