import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect, useRef } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  percentFormatterWithDecimal as percentFormatter,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";
// import RepBenchMarkExcelExportButton from "../../components/xlsx-export/RepBenchMark";
const useStyle = makeStyles((theme) => ({
  root: {},
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader1: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PipelineTable(props) {
  console.log("Time_series", props.firstCell);
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const uniqueQuarters = props.winAcvData
    .map((item) => item.quarter)
    .filter(getUniqueValues)
    .sort();
  let uniqueStages = props.uniqueNames;

  let uniqueQY = uniqueQuarters.filter((s) => s !== "Total");
  let uniqueStg = uniqueStages.filter((s) => s !== "Total");

  const tableHeader = props.drilldownHeader;

  useEffect(() => {
    const tableContainer = document.getElementById(
      `myTableContainer${props.firstCell}`
    );

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, [uniqueQuarters]);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);
  console.log("isOverflowing", isOverflowing);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id={`myTableContainer${props.firstCell}`}
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={props.dataTest}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader1}
                    className={
                      isSticky
                        ? props.tableHeader !== "Source of Qualified pipe"
                          ? `${stickyClasses.sticky} ${classes.repHeader1}`
                          : classes.repHeader1
                        : classes.repHeader1
                    }
                    colSpan={props.alphabetRequired ? 2 : 1}
                  >
                    {props.tableHeader1}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        className={classes.repHeader1}
                        colSpan={3}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {props.averageDevaitionCalculation !== undefined && (
                    <TableCell className={classes.repHeader1} colspan={4}>
                      {" "}
                    </TableCell>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader1}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow style={{ fontWeight: 600 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader1}
                    className={
                      isSticky
                        ? props.tableHeader !== "Source of Qualified pipe"
                          ? `${stickyClasses.sticky} ${classes.repHeader1}`
                          : classes.repHeader1
                        : classes.repHeader1
                    }
                    colSpan={props.alphabetRequired ? 2 : 1}
                    style={{ width: "200px" }}
                  >
                    {props.tableHeader}
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          className={classes.repHeader1}
                          colSpan={1}
                          style={
                            props.firstCell === "count"
                              ? { minWidth: 50 }
                              : { minWidth: 90 }
                          }
                        >
                          {props.cellHeader1 ? props.cellHeader1 : "Projected"}{" "}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.repHeader1}
                          colSpan={1}
                          style={{ minWidth: 90 }}
                        >
                          {/* {props.cellHeader2 ? props.Value_Label : "Actual"} */}
                          {props.cellHeader2 ? props.cellHeader2 : "Actual"}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.repHeader1}
                          colSpan={1}
                        >
                          {props.cellHeader3 ? props.cellHeader3 : "Conv.%"}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {props.averageDevaitionCalculation !== undefined && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                      >
                        Average
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                      >
                        STD.DEV.P
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                      >
                        Sqrt of # of Obs.
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                      >
                        Std. Error
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
                {uniqueStages.map((row, i) => {
                  const averageData =
                    props.averageDevaitionCalculation !== undefined &&
                    props.averageDevaitionCalculation.filter(
                      (it) => it[props.splitType] === row
                    )[0];
                  const generateKey =
                    row.split(" ")[0] === "W:"
                      ? "wonInQuarter"
                      : row.split(" ")[0] === "A:"
                      ? "Actual"
                      : row.split(" ")[0] === "B:"
                      ? "Won"
                      : row.split(" ")[0] === "C:"
                      ? "PCDNotin"
                      : row.split(" ")[0] === "A:"
                      ? "Actual"
                      : "Total";
                  let cell1 =
                    props.tableHeader === "Source of Qualified pipe"
                      ? generateKey
                      : props.cellHeader1;
                  cell1 = props.key1 ? props.key1 : cell1;
                  let cell2 =
                    props.tableHeader === "Source of Qualified pipe"
                      ? generateKey
                      : props.cellHeader2;
                  cell2 = props.key2 ? props.key2 : cell2;
                  const category = row.split(":");
                  return (
                    <StyledTableRow key={i}>
                      {props.alphabetRequired && row !== "Total" ? (
                        <>
                          <TableCell
                            align="left"
                            className={`${classes.repNumber} ${classes.fontBold}`}
                            // className={
                            //   isSticky
                            //     ? `${stickyClasses.sticky} ${classes.repNumber} ${classes.fontBold}`
                            //     : `${classes.repNumber} ${classes.fontBold}`
                            // }
                            style={
                              isSticky && i % 2 === 1
                                ? { background: "#f5f5f5" }
                                : {}
                            }
                            colSpan={1}
                          >
                            {category[0]}:
                          </TableCell>
                          <TableCell
                            align="left"
                            className={`${classes.repText} ${classes.fontBold}`}
                            // className={
                            //   isSticky
                            //     ? `${stickyClasses.sticky} ${classes.repText} ${classes.fontBold}`
                            //     : `${classes.repText} ${classes.fontBold}`
                            // }
                            style={
                              isSticky && i % 2 === 1
                                ? { background: "#f5f5f5", maxWidth: "390px" }
                                : { maxWidth: "390px" }
                            }
                            // style={{ maxWidth: "390px" }}
                            colSpan={1}
                            id="rep"
                          >
                            {category[1]}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell
                          align="left"
                          // className={`${classes.repText} ${classes.fontBold}`}
                          className={
                            // isSticky
                            //   ? `${stickyClasses.sticky} ${classes.repText} ${classes.fontBold}`
                            //   : `${classes.repText} ${classes.fontBold}`
                            isSticky
                              ? props.tableHeader !== "Source of Qualified pipe"
                                ? `${stickyClasses.sticky}  ${classes.repText} ${classes.fontBold}`
                                : ` ${classes.repText} ${classes.fontBold}`
                              : ` ${classes.repText} ${classes.fontBold}`
                          }
                          style={
                            isSticky && i % 2 === 1
                              ? { background: "#f5f5f5", width: "200px" }
                              : { width: "200px" }
                          }
                          colSpan={props.alphabetRequired ? 2 : 1}
                          // style={{ width: "200px" }}
                        >
                          {row}
                        </TableCell>
                      )}

                      {uniqueQuarters.map((cell, j) => {
                        const cellData = props.winAcvData.filter(
                          (item) =>
                            item.quarter === cell &&
                            item[props.splitType] === row
                        )[0];
                        return (
                          <React.Fragment key={j}>
                            {props.disableColumnTotal && row === "Total" ? (
                              <>
                                <TableCell
                                  align="right"
                                  className={
                                    cell === "Total" || row === "Total"
                                      ? `${classes.repNumber} ${classes.fontBold}`
                                      : classes.repNumber
                                  }
                                  colSpan={1}
                                >
                                  {cellData !== undefined
                                    ? props.firstCell === "count"
                                      ? countOrACVFormatter(
                                          Math.round(cellData[props.firstCell]),
                                          false
                                        )
                                      : countOrACVFormatter(
                                          Math.round(cellData[props.firstCell]),
                                          true
                                        )
                                    : "-"}{" "}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={
                                    cell === "Total" || row === "Total"
                                      ? `${classes.repNumber} ${classes.fontBold}`
                                      : classes.repNumber
                                  }
                                  colSpan={1}
                                >
                                  {cellData !== undefined
                                    ? countOrACVFormatter(
                                        Math.round(cellData[props.secondCell]),
                                        true
                                      )
                                    : "-"}{" "}
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row:
                                          row == "Total"
                                            ? props.tableHeader ===
                                                "Stage at quarter start" ||
                                                props.tableHeader === 
                                                "Stage at month start" ||
                                              props.tableHeader ===
                                                "Stage at Time" ||
                                              props.tableHeader ===
                                                "Qualified Pipeline"
                                              ? uniqueStg
                                              : props.uniqueStages
                                            : props.tableHeader ===
                                                "Stage at quarter start" ||
                                                props.tableHeader === "Stage at month start" ||
                                              props.tableHeader ===
                                                "Stage at Time" ||
                                              props.tableHeader ===
                                                "Qualified Pipeline"
                                            ? [row]
                                            : props.uniqueStages,
                                        cell:
                                          cell == "Total" ? uniqueQY : [cell],
                                      },
                                      cell1,
                                      tableHeader
                                    )
                                  }
                                  align="right"
                                  className={
                                    cell === "Total" || row === "Total"
                                      ? `${classes.repNumber} ${classes.fontBold}`
                                      : classes.repNumber
                                  }
                                  colSpan={1}
                                >
                                  {cellData !== undefined
                                    ? props.firstCell === "count"
                                      ? countOrACVFormatter(
                                          Math.round(cellData[props.firstCell]),
                                          false
                                        )
                                      : countOrACVFormatter(
                                          Math.round(cellData[props.firstCell]),
                                          true
                                        )
                                    : "-"}{" "}
                                </TableCell>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row:
                                          row == "Total"
                                            ? props.tableHeader ===
                                                "Stage at quarter start" ||
                                                props.tableHeader === "Stage at month start" ||
                                              props.tableHeader ===
                                                "Stage at Time" ||
                                              props.tableHeader ===
                                                "Qualified Pipeline"
                                              ? uniqueStg
                                              : props.uniqueStages
                                            : props.tableHeader ===
                                                "Stage at quarter start" ||
                                                props.tableHeader  === "Stage at month start" ||
                                              props.tableHeader ===
                                                "Stage at Time" ||
                                              props.tableHeader ===
                                                "Qualified Pipeline"
                                            ? [row]
                                            : props.uniqueStages,
                                        cell:
                                          cell == "Total" ? uniqueQY : [cell],
                                      },
                                      cell2,
                                      tableHeader
                                    )
                                  }
                                  align="right"
                                  className={
                                    cell === "Total" || row === "Total"
                                      ? `${classes.repNumber} ${classes.fontBold}`
                                      : classes.repNumber
                                  }
                                  colSpan={1}
                                >
                                  {cellData !== undefined
                                    ? countOrACVFormatter(
                                        Math.round(cellData[props.secondCell]),
                                        true
                                      )
                                    : "-"}{" "}
                                </TableCell>
                              </>
                            )}

                            <TableCell
                              align="right"
                              className={
                                cell === "Total" || row === "Total"
                                  ? `${classes.repNumber} ${classes.fontBold}`
                                  : classes.repNumber
                              }
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? percentFormatter(cellData[props.thirdCell])
                                : "-"}{" "}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                      {props.averageDevaitionCalculation !== undefined && (
                        <React.Fragment>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation("drilldownOnGraph", {
                                row,
                              })
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {averageData !== undefined
                              ? percentFormatter(averageData["average"])
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation("drilldownOnGraph", {
                                row,
                              })
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {averageData !== undefined
                              ? percentFormatter(averageData["std"])
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation("drilldownOnGraph", {
                                row,
                              })
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {averageData !== undefined
                              ? percentFormatter(averageData["sqrt"])
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation("drilldownOnGraph", {
                                row,
                              })
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {averageData !== undefined
                              ? percentFormatter(averageData["stdErr"])
                              : "-"}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
