import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./PizzaBoxes.css"; // For CSS styles
import { Grid } from "@material-ui/core";


const PizzaBoxesGraph = (props) => {
  const svgRef = useRef();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth <= 2000 ? window.innerWidth * 0.85 : window.innerWidth * 0.62,
    height: window.innerHeight,
  });

  const handleChangeLocation = (location, obj) => {
    console.log(location, obj, 'DRILL')
    props.handleChangeLocation(
      location,
      obj
    );
  };

  useEffect(() => {
    // Resize event listener to update dimensions dynamically
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth <= 2000 ? window.innerWidth * 0.85 : window.innerWidth * 0.62,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const { width, height } = dimensions;

    // Data for the pizza boxes with sizes and background colors
    const data = props.data;

    const boxHeight = 20; // Height of each pizza box
    const boxGap = 10; // Gap between boxes (vertical)
    const margin = { top: 20, right: 20, bottom: 70, left: 50 };

    // Calculate the maximum height needed for the SVG based on the data
    const maxItemsPerColumn = Math.max(...data.map(d => d.values.length));
    const svgHeight = maxItemsPerColumn * (boxHeight + boxGap) + margin.top + margin.bottom;

    // Dynamically calculate box width based on the container width and number of data points
    const availableWidth = width - margin.left - margin.right;
    const w = window.innerWidth <= 2000 ? 0.9 : 0.9
    const boxWidth = (availableWidth / data.length - boxGap) * w; // Shrink the box width to 70%

    // Create the SVG canvas with the calculated height
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", svgHeight); // Adjusted height

    // Clear previous content
    svg.selectAll("*").remove();

    // X scale for types (e.g., Big Data Analytics, Risk, etc.)
    const xScale = d3.scaleBand()
      .domain(data.map(d => d.type))
      .range([margin.left, width - margin.right])
      // .padding(0.05); // Decreased padding between horizontal groups

      // Create a tooltip div and add it to the DOM
const tooltip = d3.select("body").append("div")
.attr("class", "tooltip_PizzaBOX");



    // Stack boxes upwards
    data.forEach(d => {
      const x = xScale(d.type);
      let y = svgHeight - margin.bottom; // Adjusted to use svgHeight

      d.values.forEach(item => {
        y -= boxHeight + boxGap;

        svg.append("rect")
          .attr("x", x)
          .attr("y", y)
          .attr("width", boxWidth) // Dynamically calculated box width
          .attr("height", boxHeight)
          .attr("fill", item.bg || "white")
          .attr("stroke", "black")
          .style("cursor", "pointer")
          .on("click", function(event) { // Use event to handle context
            // Ensure you have access to `item` within the event handler
            handleChangeLocation("drilldownAreaOfFocus", {BU: d.type, Product_Group: item.value, Account_ID: item.Account_ID, bg: item.bg}); // Pass item directly
          })
          .append("title") // Add title element for tooltip
          .text(item.value)
          ;

         const t =  window.innerWidth <= 2000 ? 30 : 20
         const approximateCharWidth = 8; // Approximate width of a single character in pixels
        const maxChars = Math.floor(boxWidth / approximateCharWidth); // Calculate the maximum characters based on box width

         const truncatedText = item.value.length > maxChars 
         ? `${item.value.slice(0, maxChars - 3)}...` // Add ellipsis if text exceeds max length
         : item.value;

          // const truncatedText = item.value.length > t ? `${item.value.slice(0, t)}...` : item.value;

        svg.append("text")
          .attr("x", x + boxWidth / 2)
          .attr("y", y + boxHeight / 2)
          .attr("fill", item.clr || "black")
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "middle")
          .style("font-size", "0.85rem")
          .style("font-weight", 500)
          .style("cursor", "pointer")
          .text(truncatedText)
          .on("click", function(event) { // Use event to handle context
            // Ensure you have access to `item` within the event handler
            handleChangeLocation("drilldownAreaOfFocus", {BU: d.type, Product_Group: item.value, Account_ID: item.Account_ID, bg: item.bg}); // Pass item directly
          })
          .append("title") // Add title element for tooltip
          .text(item.value)
         ; 

          // Add top-right corner shade with orange color
          if (item.pentgon) {
            svg.append("polygon")
              .attr("points", `${x + boxWidth - 20},${y} ${x + boxWidth},${y} ${x + boxWidth},${y + 20}`)
              .attr("fill", "#FF7000");
          }
      });

      

      // Add a green background for type label
      svg.append("rect")
        .attr("x", x)
        .attr("y", svgHeight - margin.bottom)
        .attr("width", boxWidth) // Dynamically calculated box width
        .attr("height", boxHeight)
        .attr("fill", "#4471c4");

      svg.append("text")
        .attr("x", x + boxWidth / 2)
        .attr("y", svgHeight - margin.bottom + boxHeight / 2)
        .attr("fill", "white")
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .style("font-size", "0.85rem")
        .style("font-weight", 600)
        .text(d.type);
    });

   // Add legends at the bottom center
const legendWidth = 600; // Total width of both legends and their text
const legendX = (width - legendWidth) / 2; // Center-align legends
const legendY = svgHeight - 25; // Set a slightly larger margin above legends

// Legend 1: Blue-filled box
svg.append("rect")
  .attr("x", legendX - 70)
  .attr("y", legendY)
  .attr("width", 20)
  .attr("height", 15)
  .attr("fill", "#008000");

svg.append("text")
  .attr("x", legendX + 30 - 70)
  .attr("y", legendY + 12)
  .attr("fill", "black")
  .style("font-size", "0.85rem")
  .text("In use at this account");

// Add the second legend (Blue-filled box with orange top-right corner)
svg.append("rect")
  .attr("x", legendX + 100) // Slight adjustment to the spacing for the second legend
  .attr("y", legendY)
  .attr("width", 20)
  .attr("height", 15)
  .attr("fill", "#008000");

svg.append("polygon")
  .attr("points", `${legendX + 105},${legendY} ${legendX + 120},${legendY} ${legendX + 120},${legendY + 15}`)
  .attr("fill", "#FF7000");


svg.append("text")
  .attr("x", legendX + 130)
  .attr("y", legendY + 12)
  .attr("fill", "black")
  .style("font-size", "0.85rem")
  .text("End of sale product in use at this account");

// Add the third legend (Green-filled box for "Area of Focus")
svg.append("rect")
  .attr("x", legendX + 400)  // Keep this position for the "Area of Focus" legend
  .attr("y", legendY)
  .attr("width", 20)
  .attr("height", 15)
  .attr("fill", "#FFBF00");

svg.append("text")
  .attr("x", legendX + 430) // Adjusted to create space after the previous legend
  .attr("y", legendY + 12)
  .attr("fill", "black")
  .style("font-size", "0.85rem")
  .text("Targeted in 2025");

  }, [dimensions, props.data]);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ overflowY: "auto", width: "100%"}}> {/* Added height here */}
    <Grid item xs={12} style={{display: "grid",
      placeItems: "center",}}> {/* Wrap the SVG in a Grid item */}
      <svg ref={svgRef}></svg>
    </Grid>
  </Grid>
  );
};

export default PizzaBoxesGraph;
