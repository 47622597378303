import React from "react";
import {
  Card,
  Paper,
  CardContent,
  InputAdornment,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Link,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import FunnelDrilldownBody from "./FunnelDrilldownBody";
import StyledTableRow from "../../components/StyledTableRow";
// import FunnelDrilldownOnOpenBody from './FunnelDrilldownOnOpenBody'
import { AgGridReact } from "ag-grid-react";
import {
  countOrACVFormatter,
  getDefaultSelectedFiscalQuarters,
  getDefaultSalesType,
} from "../../util/customFunctions";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import addHeaderdescription from "../../components/HeaderDescription";
import { store } from "../../util/store";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

const styles = (theme) => ({
  root: {},
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    //  pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  content: { width: "100%" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    cursor: "pointer",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    cursor: "pointer",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  // filterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  // disabledFilterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  mainTable: {
    // maxWidth: '100%',
    // [theme.breakpoints.up(1250)]: {
    //     width: 1000,
    // },
    // [theme.breakpoints.up(1900)]: {
    //     width: 1400,
    // }
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  // formControl: {
  //     maxWidth: 250
  // },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(value.toFixed(1));
};

const stringNumFormatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(Math.round(value));
};

const currencyFormatter = (value, Currency_Symbol) => {
  if (_isNaN(value) || value === null) return 0;
  return `${Currency_Symbol}` + d3.format(",")(Math.round(value));
};
const formatWithPercent = (value) => {
  if (_isNaN(value) || value === null) return 0 + " %";
  return Math.round(value) + " %";
};

class Funnel extends React.Component {
  tableRef = React.createRef();
  tableContainerRef = React.createRef();

  state = {
    Opportunity_Record_Type: ["All"],
    Record_Type_Name__c: ["All"],
    isSticky: false,
    isScrollingHorizontally: false,
    closed_year: ["All"],
    closed_month: ["All"],
    closed_fiscal_quarter: ["All"],
    Closed_Year_Month: ["All"],
    Projected_Close_Year_Month: ["All"],
    Open_Year_Month: ["All"],
    activeTimeFilter: "closed_fiscal_quarter",
    projected_close_calendar_year: ["All"],
    projected_close_calendar_month: ["All"],
    projected_close_fiscal_quarter: ["All"],
    projected_close_date_year: ["All"],
    projected_close_date_month: ["All"],
    projected_close_date_fiscal_quarter: ["All"],
    open_calendar_year: ["All"],
    open_calendar_month: ["All"],
    open_fiscal_quarter: ["All"],
    validated_year: ["All"],
    validated_month: ["All"],
    validated_fiscal_quarter: ["All"],
    rep: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    Cust_Type: ["All"],
    Region: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    Booking_Type_NL_CS_US: ["All"],
    disabled: [
      // 'year', 'month', 'quarter', 'team', 'acv'
    ],
    repTeam: "rep",
    acv: null,
    acvlt: 0,
    acvPreset: "All",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    uniqueQuarters: ["All"],
    drilldownStage: "",
    drilldownQuarter: "",
    location: "",
    filtersForDrilldown: [],
    drilldownStageFor: ["Won", "Closed Won", "Win"],
    secondLevelLocation: null,
    Product_Line: ["All"],
    Prior_Year_Opp_Manually_Inserted: ["All"],
    Executive_Proposal__c: ["All"],

    // data: {

    // },
  };
  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          quarter: "validated_fiscal_quarter",
          year: "validated_year",
          month: "validated_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          Product_Line: "Product_Line",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          LeadSource: "Lead_Source",
          quarter: "open_fiscal_quarter",
          year: "open_calendar_year",
          month: "open_calendar_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Product_Line: "Product_Line",
          Prior_Year_Opp_Manually_Inserted: "Prior_Year_Opp_Manually_Inserted",
          Executive_Proposal__c: "Executive_Proposal__c",
        };

  filters = [
    "closed_year",
    "closed_month",
    "closed_fiscal_quarter",
    "Closed_Year_Month",
    "Open_Year_Month",
    "Projected_Close_Year_Month",
    "Booking_Type_NL_CS_US",
    this.variableCols.projectedMonth,
    this.variableCols.projectedYear,
    this.variableCols.projectedQuarter,
    this.variableCols.year,
    this.variableCols.month,
    this.variableCols.quarter,
    "rep",
    "team",
    this.variableCols.CustomerSegment,
    this.variableCols.CustomerType,
    this.variableCols.Industry,
    "Acct_Segment",
    this.variableCols.LeadSource,
    "Opp_Created_By",
    "Solution_Cat",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "Prior_Year_Opp_Manually_Inserted",
    "Product_Line",
    "Executive_Proposal__c",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  handleScroll = (event) => {
    console.log("handleScroll", event);
    const target = event.target;
    if (target.scrollLeft > 0) {
      this.setState({ isSticky: true });
    } else {
      this.setState({ isSticky: false });
    }
    console.log("isScrollingHorizontally", this.state.isSticky);
  };

  componentDidMount() {
    this.props.getFunnelFilters(this.props.company);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      let temp = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters,
        "closed_fiscal_quarter"
      );
      var salesTypes = ["All"];
      let dealsTypes = ["All"];
      let priorYearDeals = ["All"];
      let solutioncat = ["All"];
      dealsTypes = getDefaultSalesType(
        this.props.funnelFilters.filter((s) => s.type === "Type"),
        "Type"
      );
      solutioncat = getDefaultSalesType(
        this.props.funnelFilters.filter((s) => s.type === "Solution_Cat"),
        "Solution_Cat"
      );
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelFilters.filter((s) => s.type === "cross_sell1__c"),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelFilters.filter((s) => s.type === "Type"),
          "Type"
        );
        priorYearDeals = getDefaultSalesType(
          this.props.funnelFilters.filter(
            (s) => s.type === "Prior_Year_Opp_Manually_Inserted"
          ),
          "Prior_Year_Opp_Manually_Inserted"
        );
      }

      this.setState({
        ...prevState,
        closed_fiscal_quarter: temp,
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        Solution_Cat: solutioncat,
        Prior_Year_Opp_Manually_Inserted: priorYearDeals,
        filtersForDrilldown: [],
        uniqueQuarters: temp,
      });
    }
    if (
      this.props.funnelData !== prevProps.funnelData &&
      prevProps.funnelData !== ""
    ) {
      let uniqueQuarters = [];
      if (this.props.funnelData) {
        this.props.funnelData.map((i) => {
          if (
            !uniqueQuarters.includes(i.closed_fiscal_quarter) &&
            i.closed_fiscal_quarter
          ) {
            uniqueQuarters = [...uniqueQuarters, i.closed_fiscal_quarter];
          }
        });

        this.setState({
          ...this.state,
          uniqueQuarters: uniqueQuarters.sort(),
        });
      }
    }
  }

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    // if (!this.state.closed_fiscal_quarter.includes('All')) {
    //     filtersArray = [...filtersArray, { title: "Closed Fiscal Quarter", value: this.state.drilldownQuarter }]
    // }
    // if (this.state.closed_fiscal_quarter.includes('All') && this.state.drilldownQuarter !== "") {
    //     filtersArray = [...filtersArray, { title: "Closed Fiscal Quarter", value: this.state.drilldownQuarter }]
    // }
    if (!this.state.closed_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Calendar Year", value: this.state.closed_year },
      ];
    }
    if (!this.state.closed_month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.closed_month.map((i) => this.months[i - 1]),
        },
      ];
    }

    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opp. Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.Executive_Proposal__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proposal Desk Review",
          value: this.state.Executive_Proposal__c,
        },
      ];

    if (this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Greater Than`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.acvlt !== null && this.state.acvlt !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Less Than`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt
            ),
        },
      ];

    if (!this.state[this.variableCols.projectedQuarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Fiscal Qtr.",
          value: this.state[this.variableCols.projectedQuarter],
        },
      ];

    if (!this.state[this.variableCols.projectedYear].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Year:",
          value: this.state[this.variableCols.projectedYear],
        },
      ];

    if (!this.state.cross_sell1__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Sales Type", value: this.state.cross_sell1__c },
      ];
    if (!this.state.Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
          value: this.state.Type,
        },
      ];

    if (!this.state.Prior_Year_Opp_Manually_Inserted.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Prior Year Deals",
          value: this.state.Prior_Year_Opp_Manually_Inserted,
        },
      ];

    if (!this.state.Product_Line.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Product Cat.",
          value: this.props.funnelFilters
            .filter(
              (y) =>
                y.type === this.variableCols.Product_Line &&
                y[this.variableCols.Product_Line] !== "" &&
                this.state.Product_Line.includes(y.Product_Line)
            )
            .map((item) => item.label),
        },
      ];

    if (!this.state[this.variableCols.projectedMonth].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Month",
          value: this.state[this.variableCols.projectedMonth].map(
            (i) => this.months[i - 1]
          ),
        },
      ];

    if (!this.state[this.variableCols.Industry].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state[this.variableCols.Industry],
        },
      ];

    if (!this.state[this.variableCols.CustomerSegment].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Cust. Segment",
          value: this.state[this.variableCols.CustomerSegment],
        },
      ];

    if (!this.state[this.variableCols.CustomerType].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value: this.state[this.variableCols.CustomerType],
        },
      ];

    if (!this.state[this.variableCols.quarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Qualifying Fiscal Qtr.:"
              : "Open Fiscal Qtr.:",
          value: this.state[this.variableCols.quarter],
        },
      ];

    if (!this.state[this.variableCols.year].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Qualifying Fiscal Year:"
              : "Open Fiscal Year:",
          value: this.state[this.variableCols.year],
        },
      ];

    if (!this.state[this.variableCols.month].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Qualifying Fiscal Month:"
              : "Open Fiscal Month:",
          value: this.state[this.variableCols.month].map(
            (i) => this.months[i - 1]
          ),
        },
      ];

    if (!this.state[this.variableCols.LeadSource].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Lead Source",
          value: this.state[this.variableCols.LeadSource],
        },
      ];

    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state[this.variableCols.deal_category__c].includes("All")
    )
      filtersArray = [
        ...filtersArray,
        {
          title: "Deal Category",
          value: this.state[this.variableCols.deal_category__c],
        },
      ];

    if (!this.state.Opportunity_Record_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];

    if (!this.state.Record_Type_Name__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opp Record Type",
          value: this.state.Record_Type_Name__c,
        },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    return filtersArray;
  };
  containsMonth = (str) => str.includes("_Month");
  containsQuarter = (str) => str.includes("_quarter");
  handleChange = (e) => {
    let activeTimeFilter = this.state.activeTimeFilter;
    if (e.target.name === "Closed_Year_Month") {
      activeTimeFilter = "Closed_Year_Month";
    } else if (e.target.name === "closed_fiscal_quarter") {
      activeTimeFilter = "closed_fiscal_quarter";
    }
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        activeTimeFilter,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        activeTimeFilter,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        console.log("mapfilters", f);
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "team"
              ? true
              : f === "Opportunity_Record_Type"
              ? true
              : f === "Record_Type_Name__c"
              ? true
              : this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : "string",
          // string: true
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
    console.log("payload", filters);
    this.props.getFunnelDataNew(filters, this.state.activeTimeFilter);
  };

  orderRepsByLastName = () => {
    let temp = this.props.funnelFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeLocation = (location, stage, quarter) => {
    console.log(location);
    if (stage !== "" && quarter !== "") {
      this.setState({
        ...this.state,
        drilldownStage: stage,
        drilldownQuarter: quarter,
        location: "drilldownOnGraph",
      });

      let filters = this.filters
        .map((f) => {
          return {
            name: f,
            value:
              f === "rep"
                ? this.state[f].includes("All")
                  ? "All"
                  : this.getSelectedRepIDs()
                : this.state[f],
            string:
              f === "team"
                ? true
                : f === "Opportunity_Record_Type"
                ? true
                : f === "Record_Type_Name__c"
                ? true
                : this.props.funnelFilters.filter(
                    (i) => i.type === f && i[f] === this.state[f][0]
                  )[0]
                ? this.props.funnelFilters.filter(
                    (i) => i.type === f && i[f] === this.state[f][0]
                  )[0].string === "Y"
                : true,
          };
        })
        .filter(
          (f) =>
            (!Array.isArray(f.value) && f.value !== "All") ||
            !f.value.includes("All")
        )
        .filter((f) => !this.state.disabled.includes(f.name));
      // if(location !== 'overview'){
      if (this.state.acv !== null) {
        filters = [
          ...filters,
          {
            name: "acv",
            value:
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv,
            string: false,
          },
        ];
      }
      if (this.state.acvlt !== null && this.state.acvlt !== 0) {
        filters = [
          ...filters,
          {
            name: "acvlt",
            value:
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acvlt.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt,
            string: false,
          },
        ];
      }
      this.props.getDrilldownData(
        filters,
        stage,
        quarter,
        this.state.activeTimeFilter
      );
    } else {
      this.props.clearDrilldownData();
      this.setState({
        ...this.state,
        drilldownStage: "",
        drilldownQuarter: "",
        location: "",
      });
    }
    // }else{
    //     this.props.clearDrilldownData()
    // }
  };

  // defaultColDef = {
  //     flex: 1,
  //     cellClass: 'cell-wrap-text',
  //     autoHeight: true,
  //     sortable: true,
  //     resizable: true,
  //     columnDefs: [],
  //     rowData: [],
  //     containerWidth: '100%',
  //     colResizeDefault: 'shift',
  //     getRowHeight: function (params) {
  //         return params.data.rowHeight;
  //     }
  // }

  // gridApi = null
  // columnApi = null
  // params = null

  // gridReady = params => {
  //     this.gridApi = params.api
  //     this.columnApi = params.columnApi
  //     this.params = params
  // }
  onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      this.columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
      secondLevelLocation: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    console.log("checck_quareter", this.state.uniqueQuarters.length);
    const { classes } = this.props;
    const { isSticky } = this.state;

    console.log("this.props.funnelData", this.props.funnelData);
    console.log("descriptionTableData", this.props.descriptionTableData);

    let currentColumn = 0;
    const stageNames =
      this.props.winRateStages !== ""
        ? this.props.winRateStages.filter((x) => x !== "Open")
        : "";

    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Win Rate Analysis
          </Typography>
          <Typography variant="body1">
            Examine win rates from various stages over time and across
            dimensions to identify trends and track the impact of rep enablement
            initiatives or campaigns.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          `${this.props.Win_Rate_Prefix} Trends`,
          `Examine ${this.props.Win_Rate_Prefix}s from various stages over time and across dimensions to identify trends and track the impact of rep enablement initiatives or campaigns.`
        )}

        {this.props.funnelFilters !== "" ? (
          <>
            {/* closed dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.Closed_Year_Month[0] !== "All"}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Closed Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      name="closed_fiscal_quarter"
                      multiple
                      data-test="closed_fiscal_quarter"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "closed_fiscal_quarter" ? (
                            <MenuItem
                              key={y["closed_fiscal_quarter"]}
                              value={y["closed_fiscal_quarter"]}
                            >
                              {y["closed_fiscal_quarter"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.closed_fiscal_quarter.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Closed_Year_Month.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Closed Month:
                    </InputLabel>
                    <Select
                      value={this.state.Closed_Year_Month}
                      onChange={this.handleChange}
                      name="Closed_Year_Month"
                      multiple
                      data-test="Closed_Year_Month"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "Closed_Year_Month" ? (
                            <MenuItem
                              key={y["Closed_Year_Month"]}
                              value={y["Closed_Year_Month"]}
                            >
                              {y["Closed_Year_Month"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Rep Team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.funnelFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    data-test="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      data-test="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={12}
                lg={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  disabled={this.state.acvError !== ""}
                >
                  GO
                </Button>
              </Grid>
            </Grid>

            {/* projected close dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.Projected_Close_Year_Month[0] !== "All"
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.projectedQuarter
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.projectedQuarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.projectedQuarter}
                      multiple
                      data-test="projected_close_fiscal_quarter"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === this.variableCols.projectedQuarter ? (
                            <MenuItem
                              key={y[this.variableCols.projectedQuarter]}
                              value={y[this.variableCols.projectedQuarter]}
                            >
                              {y[this.variableCols.projectedQuarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state[this.variableCols.projectedQuarter][0] !==
                      "All"
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color:
                          !this.state.projected_close_date_fiscal_quarter.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Projected Close Month:
                    </InputLabel>
                    <Select
                      value={this.state.Projected_Close_Year_Month}
                      onChange={this.handleChange}
                      name="Projected_Close_Year_Month"
                      multiple
                      data-test="Projected_Close_Year_Month"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "Projected_Close_Year_Month" ? (
                            <MenuItem
                              key={y["Projected_Close_Year_Month"]}
                              value={y["Projected_Close_Year_Month"]}
                            >
                              {y["Projected_Close_Year_Month"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* industry, customer segment, customer type */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={12}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                {/* <Grid item xs={12} style={{ paddingBottom: 8 }}>
                <Typography variant='body1'>Customer</Typography>
            </Grid> */}

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      data-test="industry"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      Cust. Segment:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      data-test="customer_segment"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      data-test="customer_type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Adding Acv Value */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={4}
              md={4}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 4 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Open/Validated dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.Open_Year_Month[0] !== "All"}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.quarter].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}{" "}
                      Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.quarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.quarter}
                      data-test="quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === this.variableCols.quarter ? (
                            <MenuItem
                              key={y[this.variableCols.quarter]}
                              value={y[this.variableCols.quarter]}
                            >
                              {y[this.variableCols.quarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      !this.state[this.variableCols.quarter].includes("All")
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.open_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}{" "}
                      Month:
                    </InputLabel>
                    <Select
                      value={this.state.Open_Year_Month}
                      onChange={this.handleChange}
                      name="Open_Year_Month"
                      multiple
                      data-test="Open_Year_Month"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "Open_Year_Month" ? (
                            <MenuItem
                              key={y["Open_Year_Month"]}
                              value={y["Open_Year_Month"]}
                            >
                              {y["Open_Year_Month"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Lead source, opportunity created by, solution cat */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={12}
              lg={this.props.company === "C0003" ? 6 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                {/* <Grid item xs={12} style={{ paddingBottom: 8 }}>
                <Typography variant='body1'>Filters</Typography>
            </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.LeadSource
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      Lead Source:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.LeadSource]}
                      onChange={this.handleChange}
                      name={this.variableCols.LeadSource}
                      data-test="lead_source"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.LeadSource &&
                              y[this.variableCols.LeadSource] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.LeadSource] <
                            b[this.variableCols.LeadSource]
                              ? -1
                              : a[this.variableCols.LeadSource] >
                                b[this.variableCols.LeadSource]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.LeadSource]}
                              value={y[this.variableCols.LeadSource]}
                            >
                              {y[this.variableCols.LeadSource]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Opp_Created_By.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      Opportunity created by:
                    </InputLabel>
                    <Select
                      value={this.state.Opp_Created_By}
                      onChange={this.handleChange}
                      name="Opp_Created_By"
                      data-test="opp_created-by"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Opp_Created_By" &&
                              y["Opp_Created_By"] !== ""
                          )
                          .sort((a, b) =>
                            a["Opp_Created_By"] < b["Opp_Created_By"]
                              ? -1
                              : a["Opp_Created_By"] > b["Opp_Created_By"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Opp_Created_By"]}
                              value={y["Opp_Created_By"]}
                            >
                              {y["Opp_Created_By"]}
                            </MenuItem>
                          ))}{" "}
                    </Select>
                  </FormControl>
                </Grid>
                {!["C0003", "C0008"].includes(this.props.company) && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        value={this.state.Solution_Cat}
                        onChange={this.handleChange}
                        name="Solution_Cat"
                        data-test="solution_category"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Solution_Cat" &&
                                y["Solution_Cat"] !== ""
                            )
                            .sort((a, b) =>
                              a["Solution_Cat"] < b["Solution_Cat"]
                                ? -1
                                : a["Solution_Cat"] > b["Solution_Cat"]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y["Solution_Cat"]}
                                value={y["Solution_Cat"]}
                              >
                                {y["Solution_Cat"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.props.company === "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    {/* <OppotunityType
                                    Booking_Type_NL_CS_US={this.state.Booking_Type_NL_CS_US}
                                    handleChange={this.handleChange}
                                    funnelCompareFilters={this.props.funnelFilters}
                                /> */}
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Booking_Type_NL_CS_US.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opportunity Type:
                      </InputLabel>
                      <Select
                        value={this.state.Booking_Type_NL_CS_US}
                        onChange={this.handleChange}
                        name="Booking_Type_NL_CS_US"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Booking_Type_NL_CS_US" &&
                                y["Booking_Type_NL_CS_US"] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y["Booking_Type_NL_CS_US"]}
                                value={y["Booking_Type_NL_CS_US"]}
                              >
                                {y["Booking_Type_NL_CS_US"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.props.company === "C0008" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Product_Line.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Product Line:
                      </InputLabel>
                      <Select
                        value={this.state.Product_Line}
                        onChange={this.handleChange}
                        name="Product_Line"
                        data-test="Product_Lineegory"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Product_Line" &&
                                y["Product_Line"] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y["Product_Line"]}
                                value={y["Product_Line"]}
                              >
                                {y["label"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/*  */}
            {this.props.company === "C0013" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Opportunity_Record_Type" &&
                                y.Opportunity_Record_Type !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Opportunity_Record_Type}
                                value={y.Opportunity_Record_Type}
                              >
                                {y.Opportunity_Record_Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* C010 */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChange}
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Record_Type_Name__c" &&
                                y.Record_Type_Name__c !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Record_Type_Name__c}
                                value={y.Record_Type_Name__c}
                              >
                                {y.Record_Type_Name__c}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0017" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp. Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state.Type}
                        onChange={this.handleChange}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter((y) => y.type === "Type" && y.Type !== "")
                            .map((y) => (
                              <MenuItem key={y.Type} value={y.Type}>
                                {y.Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/*  */}

            {this.props.company === "C0008" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={4}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.cross_sell1__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Sales Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state[this.variableCols.cross_sell1__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.cross_sell1__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.cross_sell1__c &&
                                y[this.variableCols.cross_sell1__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.cross_sell1__c]}
                                value={y[this.variableCols.cross_sell1__c]}
                              >
                                {y[this.variableCols.cross_sell1__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[this.variableCols.Type].includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state[this.variableCols.Type]}
                        onChange={this.handleChange}
                        name={this.variableCols.Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {["C0003"].includes(this.props.company) && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Product_Line
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Product Cat.:
                      </InputLabel>
                      <Select
                        data-test="Product_Line"
                        value={this.state[this.variableCols.Product_Line]}
                        onChange={this.handleChange}
                        name={this.variableCols.Product_Line}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Product_Line &&
                                y[this.variableCols.Product_Line] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Product_Line]}
                                value={y[this.variableCols.Product_Line]}
                              >
                                {y["label"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {["C0008"].includes(this.props.company) && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Prior_Year_Opp_Manually_Inserted
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Prior Year Deals:
                      </InputLabel>
                      <Select
                        data-test="Prior_Year_Opp_Manually_Inserted"
                        value={
                          this.state[
                            this.variableCols.Prior_Year_Opp_Manually_Inserted
                          ]
                        }
                        onChange={this.handleChange}
                        name={
                          this.variableCols.Prior_Year_Opp_Manually_Inserted
                        }
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type ===
                                  this.variableCols
                                    .Prior_Year_Opp_Manually_Inserted &&
                                y[
                                  this.variableCols
                                    .Prior_Year_Opp_Manually_Inserted
                                ] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={
                                  y[
                                    this.variableCols
                                      .Prior_Year_Opp_Manually_Inserted
                                  ]
                                }
                                value={
                                  y[
                                    this.variableCols
                                      .Prior_Year_Opp_Manually_Inserted
                                  ]
                                }
                              >
                                {
                                  y[
                                    this.variableCols
                                      .Prior_Year_Opp_Manually_Inserted
                                  ]
                                }
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {["C0001"].includes(this.props.company) && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Executive_Proposal__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Proposal Desk Review:
                      </InputLabel>
                      <Select
                        data-test="Executive_Proposal__c"
                        value={
                          this.state[this.variableCols.Executive_Proposal__c]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Executive_Proposal__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type ===
                                  this.variableCols.Executive_Proposal__c &&
                                y[this.variableCols.Executive_Proposal__c] !==
                                  ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Executive_Proposal__c]}
                                value={
                                  y[this.variableCols.Executive_Proposal__c]
                                }
                              >
                                {y[this.variableCols.Executive_Proposal__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          container
          style={{ marginLeft: 5, marginBottom: 10, marginTop: 5 }}
        >
          {this.props.funnelData && this.props.funnelData !== "" ? (
            <Grid
              item
              xs={12}
              sm={6}
              style={{ marginBottom: 10, paddingRight: 18, paddingLeft: 3 }}
            >
              <Card raised style={{ margin: "10px 0px" }}>
                <Table data-test="win_rate_opportunities" size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Total opportunities:</TableCell>
                      <TableCell align="right">
                        {formatter(
                          this.props.descriptionTableData
                            .totalOpportunitiesCount
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Currently Open:</TableCell>
                      <TableCell align="right">
                        {formatter(
                          this.props.descriptionTableData.currentlyOpenCount
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        {this.props.company === "C0002"
                          ? "Won:"
                          : "Closed Won:"}
                      </TableCell>
                      <TableCell align="right">
                        {formatter(
                          this.props.descriptionTableData.closedWonCount
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        {this.props.company === "C0002"
                          ? "Lost:"
                          : "Closed Lost / Disqualified:"}
                      </TableCell>
                      <TableCell align="right">
                        {formatter(
                          this.props.descriptionTableData.closedLostCount
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} style={{ marginBottom: 5, padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={140}
                  style={{ margin: "20px 0px" }}
                />
              </div>
            </Grid>
          )}

          {this.props.funnelData && this.props.funnelData !== "" ? (
            <Grid
              item
              xs={12}
              sm={6}
              style={{ marginBottom: 10, paddingRight: 7 }}
            >
              <Card raised style={{ margin: "10px 0px" }}>
                <Table data-test="win_rate_acv" size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Total opportunities:</TableCell>
                      <TableCell align="right">
                        {currencyFormatter(
                          this.props.descriptionTableData.totalOpportunitiesAcv,
                          this.props.Currency_Symbol
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Currently Open:</TableCell>
                      <TableCell align="right">
                        {currencyFormatter(
                          this.props.descriptionTableData.currentlyOpenAcv,
                          this.props.Currency_Symbol
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        {this.props.company === "C0002"
                          ? "Won:"
                          : "Closed Won:"}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormatter(
                          this.props.descriptionTableData.closedWonAcv,
                          this.props.Currency_Symbol
                        )}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        {this.props.company === "C0002"
                          ? "Lost:"
                          : "Closed Lost / Disqualified:"}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormatter(
                          this.props.descriptionTableData.closedLostAcv,
                          this.props.Currency_Symbol
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} style={{ marginBottom: 5, padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={140}
                  style={{ margin: "20px 0px" }}
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{ marginLeft: 5, marginBottom: 10, marginTop: 5 }}
        >
          <Typography variant="h2">
            {`${this.props.Win_Rate_Prefix}s by ${
              this.props.Value_Label
            } from stage for each ${
              this.props.timeline === "Closed_Year_Month"
                ? "closed month"
                : "closed quarter"
            } - Opps & ${this.props.Value_Label} flowing past each stage`}
          </Typography>
        </Grid>

        {/* Main Table */}
        {this.props.funnelData && this.props.funnelData !== "" ? (
          <Grid
            item
            container
            spacing={1}
            component={Paper}
            // style={{
            //   marginTop: 0,
            //   width: "auto",
            //   maxWidth:
            //     window.innerWidth < 1600 ? window.innerWidth - 120 : 1600,
            //   marginLeft: 5,
            // }}
          >
            <Grid item xs={12}>
              <div
                className={classes.root}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CardContent
                  className={classes.content}
                  // style={{ padding: 10 }}
                  raised
                  style={{
                    margin: "10px 0px",
                    maxWidth: "100%",
                    overflowX: "auto",
                  }}
                >
                  {this.props.uniqueTimeline.length >= 4 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                        // marginRight: "10px",
                      }}
                    >
                      <CopyTablesObject tableRef={this.tableRef} />
                    </div>
                  ) : null}
                  <Grid container justify="center">
                    <TableContainer
                      style={{
                        width: "auto",
                        borderLeft: isSticky ? "0.5px solid lightgrey" : "",
                      }}
                      onScroll={this.handleScroll}
                    >
                      {this.props.uniqueTimeline.length <= 3 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                            // marginRight: "10px",
                          }}
                        >
                          <CopyTablesObject tableRef={this.tableRef} />
                        </div>
                      ) : null}
                      <Table
                        ref={this.tableRef}
                        data-test="win_rate_acv_by_stage"
                        size="small"
                        aria-label="html table"
                        style={{ tableLayout: "auto", width: "initial" }}
                      >
                        <TableHead>
                          <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                            <TableCell
                              align="center"
                              // className={classes.repHeader}
                              className={
                                isSticky
                                  ? `${classes.sticky} ${classes.repHeader}`
                                  : classes.repHeader
                              }
                              colSpan={1}
                            >
                              {this.props.timeline === "Closed_Year_Month"
                                ? "Closed Month"
                                : "Closed Fiscal Quarter"}
                            </TableCell>
                            {this.props.uniqueTimeline.map((i, index) => {
                              currentColumn = index;
                              return (
                                <TableCell
                                  key={i}
                                  align="center"
                                  style={
                                    index % 2 === 0
                                      ? {
                                          background: "#4471c4",
                                          color: "white",
                                        }
                                      : {
                                          background: "#5b9bd5",
                                          color: "white",
                                        }
                                  }
                                  className={classes.repHeader}
                                  colSpan={3}
                                >
                                  {i}
                                </TableCell>
                              );
                            })}
                            {this.props.uniqueTimeline.length > 1 && (
                              <React.Fragment>
                                <TableCell
                                  align="center"
                                  style={
                                    (currentColumn + 1) % 2 === 0
                                      ? {
                                          background: "#4471c4",
                                          color: "white",
                                        }
                                      : {
                                          background: "#5b9bd5",
                                          color: "white",
                                        }
                                  }
                                  className={classes.repHeader}
                                  colSpan={3}
                                >
                                  {"Total"}
                                </TableCell>
                              </React.Fragment>
                            )}

                            {this.props.uniqueTimeline.length === 0 && (
                              <TableCell
                                rowSpan={8}
                                className={classes.repHeader}
                                style={{ width: 200, textAlign: "center" }}
                              >
                                No data available
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                            <TableCell
                              align="center"
                              // className={classes.repHeader}
                              className={
                                isSticky
                                  ? `${classes.sticky} ${classes.repHeader}`
                                  : classes.repHeader
                              }
                              colSpan={1}
                            >
                              Stage
                            </TableCell>
                            {this.props.uniqueTimeline.map((i) => {
                              return (
                                <React.Fragment key={i}>
                                  <TableCell
                                    align="center"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    # of Opps
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    {/* ACV */}
                                    {`${this.props.Value_Label}`}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    {/* Win Rate by ACV */}
                                    {`${this.props.Win_Rate_Prefix} by ${this.props.Value_Label}`}
                                  </TableCell>
                                </React.Fragment>
                              );
                            })}
                            {this.props.uniqueTimeline.length > 1 && (
                              <React.Fragment>
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  colSpan={1}
                                >
                                  # of Opps
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  colSpan={1}
                                >
                                  {/* ACV */}
                                  {`${this.props.Value_Label}`}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  colSpan={1}
                                >
                                  {/* Win Rate by ACV */}
                                  {`${this.props.Win_Rate_Prefix} by ${this.props.Value_Label}`}
                                </TableCell>
                              </React.Fragment>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stageNames.map((i, stageIndex) => {
                            let rowTotalCount = 0;
                            let rowTotalAcv = 0;
                            let rowTotalWinRate = 0;
                            return (
                              <StyledTableRow key={i}>
                                <TableCell
                                  align="left"
                                  // className={classes.repText}
                                  className={
                                    isSticky
                                      ? `${classes.sticky} ${classes.repText}`
                                      : classes.repText
                                  }
                                  style={
                                    isSticky && stageIndex % 2 === 1
                                      ? { background: "#f5f5f5" }
                                      : {}
                                  }
                                  colSpan={1}
                                >
                                  {i}
                                </TableCell>

                                {this.props.uniqueTimeline.map((j) => {
                                  const winTable = [].concat.apply(
                                    [],
                                    this.props.funnelTableData
                                  );
                                  const tempCount = winTable
                                    .filter(
                                      (x) =>
                                        x.stage_name === i &&
                                        x[this.props.timeline] === j
                                    )
                                    .map((x) => x.count)[0];
                                  const tempAcv = winTable
                                    .filter(
                                      (x) =>
                                        x.stage_name === i &&
                                        x[this.props.timeline] === j
                                    )
                                    .map((x) => x.acv)[0];
                                  const tempWinrate = winTable
                                    .filter(
                                      (x) =>
                                        x.stage_name === i &&
                                        x[this.props.timeline] === j
                                    )
                                    .map((x) => x.winRate)[0];
                                  rowTotalCount = winTable
                                    .filter(
                                      (x) =>
                                        x.stage_name === i &&
                                        x[this.props.timeline] === "total"
                                    )
                                    .map((x) => x.count)[0];
                                  rowTotalAcv = winTable
                                    .filter(
                                      (x) =>
                                        x.stage_name === i &&
                                        x[this.props.timeline] === "total"
                                    )
                                    .map((x) => x.acv)[0];
                                  rowTotalWinRate = winTable
                                    .filter(
                                      (x) =>
                                        x.stage_name === i &&
                                        x[this.props.timeline] === "total"
                                    )
                                    .map((x) => x.winRate)[0];
                                  return (
                                    <>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                        onClick={() =>
                                          this.handleChangeLocation(
                                            "drilldownOnGraph",
                                            i,
                                            j
                                          )
                                        }
                                      >
                                        {tempCount === undefined ||
                                        tempCount === null ||
                                        _isNaN(tempCount)
                                          ? "-"
                                          : countOrACVFormatter(
                                              tempCount,
                                              false
                                            )}{" "}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumberACV}
                                        colSpan={1}
                                        onClick={() =>
                                          this.handleChangeLocation(
                                            "drilldownOnGraph",
                                            i,
                                            j
                                          )
                                        }
                                      >
                                        {tempAcv === undefined ||
                                        tempAcv === null ||
                                        _isNaN(tempAcv)
                                          ? "-"
                                          : countOrACVFormatter(tempAcv, true)}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumberACV}
                                        colSpan={1}
                                        onClick={() =>
                                          this.handleChangeLocation(
                                            "drilldownOnGraph",
                                            i,
                                            j
                                          )
                                        }
                                      >
                                        {tempWinrate === undefined ||
                                        tempWinrate === null ||
                                        _isNaN(tempWinrate)
                                          ? "-"
                                          : Math.round(tempWinrate * 100) + "%"}
                                      </TableCell>
                                    </>
                                  );
                                  //     let tempCount = 0
                                  //     let tempACV = 0
                                  //     this.props.funnelData.map(k => {
                                  //         if (this.props.company === 'C0003') {
                                  //             if (k[this.props.timeline] === j) {
                                  //                 switch (i) {
                                  //                     case 'Qualifying':
                                  //                         tempCount += (k && k.count ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Solutioning':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Qualifying' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Qualifying' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Proposing':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Qualifying' && k.stage_name !== 'Solutioning' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Qualifying' && k.stage_name !== 'Solutioning' ? (k.acv) : 0)
                                  //                         break

                                  //                     case 'Closing':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Qualifying' && k.stage_name !== 'Solutioning' && k.stage_name !== 'Proposing' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Qualifying' && k.stage_name !== 'Solutioning' && k.stage_name !== 'Proposing' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Win':
                                  //                         tempCount += (k && k.count && k.stage_name === 'Win' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name === 'Win' ? (k.acv) : 0)
                                  //                         break
                                  //                 }
                                  //             }
                                  //         }
                                  //         else if (this.props.company === 'C0002') {
                                  //             if (k[this.props.timeline] === j) {
                                  //                 switch (i) {
                                  //                         case 'Suspect':
                                  //                         tempCount += (k && k.count ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Qualify':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Demo':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualify' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualify' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Proposal':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualify' && k.stage_name !== 'Demo' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualify' && k.stage_name !== 'Demo' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Negotiate':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualify' && k.stage_name !== 'Demo' && k.stage_name !== 'Proposal' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualify' && k.stage_name !== 'Demo' && k.stage_name !== 'Proposal' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Won':
                                  //                         tempCount += (k && k.count && k.stage_name === 'Won' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name === 'Won' ? (k.acv) : 0)
                                  //                         break

                                  //                 }
                                  //             }
                                  //         }
                                  //         else {

                                  //             if (k[this.props.timeline] === j) {
                                  //                 switch (i) {
                                  //                     case 'Suspect':
                                  //                         tempCount += (k && k.count ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Qualified':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Proposed':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualified' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualified' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Selected':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualified' && k.stage_name !== 'Proposed' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualified' && k.stage_name !== 'Proposed' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Committed':
                                  //                         tempCount += (k && k.count && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualified' && k.stage_name !== 'Proposed' && k.stage_name !== 'Selected' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name !== 'Suspect' && k.stage_name !== 'Qualified' && k.stage_name !== 'Proposed' && k.stage_name !== 'Selected' ? (k.acv) : 0)
                                  //                         break
                                  //                     case 'Closed Won':
                                  //                         tempCount += (k && k.count && k.stage_name === 'Closed Won' ? (k.count) : 0)
                                  //                         tempACV += (k && k.acv && k.stage_name === 'Closed Won' ? (k.acv) : 0)
                                  //                         break
                                  //                 }
                                  //             }
                                  //         }
                                  //     })
                                  //     rowTotalCount += tempCount
                                  //     rowTotalAcv += tempACV
                                  //     // k.stage_name === i && k[this.props.timeline] === j
                                  //     // let tempCount = temp.length > 0 ? formatter(temp[0].count) : 0
                                  //     // let tempACV = temp.length > 0 ? currencyFormatter(temp[0].acv) : currencyFormatter(0)
                                  //     // ?this.props.funnelData.filter(k => k.stage_name === "Closed" && k[this.props.timeline] === j)[0].acv/tempACV:0
                                  //     return (
                                  //         <React.Fragment key={j}>
                                  //             <TableCell align='right' className={classes.repNumber} colSpan={1} onClick={() => this.handleChangeLocation(i, j)}>{formatter(tempCount)} </TableCell>
                                  //             <TableCell align='right' className={classes.repNumberACV} colSpan={1} onClick={() => this.handleChangeLocation(i, j)}>{currencyFormatter(tempACV)}</TableCell>
                                  //             <TableCell align='right' className={classes.repNumberPercent} colSpan={1} onClick={() => this.handleChangeLocation(i, j)}>{formatWithPercent(this.props.funnelData.filter(k => (k.stage_name === "Closed Won" || k.stage_name === "Win" || k.stage_name === "Won") && k[this.props.timeline] === j)[0] ? this.props.funnelData.filter(k => (k.stage_name === "Closed Won" || k.stage_name === "Win" || k.stage_name === "Won") && k[this.props.timeline] === j)[0].acv / tempACV * 100 : 0)}</TableCell>
                                  //         </React.Fragment>
                                  //     )

                                  // })
                                })}

                                {this.props.uniqueTimeline.length > 1 && (
                                  <React.Fragment>
                                    <TableCell
                                      align="right"
                                      className={classes.repNumber}
                                      colSpan={1}
                                      // onClick={() => this.handleChangeLocation("drilldownOnGraph",i, this.props.uniqueTimeline)}
                                      style={{ cursor: "default" }}
                                    >
                                      {rowTotalCount === undefined ||
                                      rowTotalCount === null ||
                                      _isNaN(rowTotalCount)
                                        ? "-"
                                        : countOrACVFormatter(
                                            rowTotalCount,
                                            false
                                          )}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.repNumberACV}
                                      colSpan={1}
                                      // onClick={() => this.handleChangeLocation("drilldownOnGraph",i, this.props.uniqueTimeline)}
                                      style={{ cursor: "default" }}
                                    >
                                      {rowTotalAcv === undefined ||
                                      rowTotalAcv === null ||
                                      _isNaN(rowTotalAcv)
                                        ? "-"
                                        : countOrACVFormatter(
                                            rowTotalAcv,
                                            true
                                          )}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.repNumberPercent}
                                      colSpan={1}
                                      // onClick={() => this.handleChangeLocation("drilldownOnGraph",i, this.props.uniqueTimeline)}
                                      style={{ cursor: "default" }}
                                    >
                                      {rowTotalWinRate === undefined ||
                                      rowTotalWinRate === null ||
                                      _isNaN(rowTotalWinRate)
                                        ? "-"
                                        : Math.round(rowTotalWinRate * 100) +
                                          "%"}
                                    </TableCell>
                                  </React.Fragment>
                                )}
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </CardContent>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            container
            spacing={1}
            component={Paper}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="80%"
              height={230}
              style={{ margin: "20px 0px" }}
            />
          </Grid>
        )}

        {this.state.location === "drilldownOnGraph" &&
          this.state.drilldownStage !== "" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={
                  this.state.drilldownStageFor.includes(
                    this.state.drilldownStage
                  )
                    ? `${this.state.drilldownStage} Opportunities`
                    : `Opportunities lost at ${this.state.drilldownStage} stage`
                }
                back={this.handleChangeLocation}
                filters={[
                  {
                    title:
                      this.props.timeline === "Closed_Year_Month"
                        ? "Closed Month"
                        : "Closed Fiscal Quarter",
                    value: this.state.drilldownQuarter,
                  },
                  ...this.state.filtersForDrilldown,
                ]}
              >
                <Drilldown
                  header={`Opportunities won at ${this.state.drilldownStage} during ${this.state.drilldownQuarter}`}
                  body={this.props.drilldownData}
                  company={this.props.company}
                  Value_Label={this.props.Value_Label}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function sortAscending(data) {
  return data.sort((a, b) => {
    const parseValue = (str) => {
      if (str.includes("-Q")) {
        // Parse quarters format (YYYY-QX)
        const [year, quarter] = str.split("-Q").map(Number);
        return { year, value: quarter, type: "quarter" };
      } else if (str.includes("-")) {
        // Parse months format (YYYY-MM)
        const [year, month] = str.split("-").map(Number);
        return { year, value: month, type: "month" };
      }
      throw new Error(
        "Invalid format: data must be in 'YYYY-QX' or 'YYYY-MM' format"
      );
    };

    const parsedA = parseValue(a);
    const parsedB = parseValue(b);

    // Compare years first (ascending order)
    if (parsedA.year !== parsedB.year) {
      return parsedA.year - parsedB.year;
    }

    // If years are the same, compare quarters or months (ascending order)
    return parsedA.value - parsedB.value;
  });
}

function mapStateToProps(state) {
  const {
    winRateFilters,
    winRateData,
    winRateDrilldownData,
    persistentAlert,
    user,
    winRateStages,
    updatingFunnelData,
    ExpectedAcvData,
  } = state.app;
  console.log(
    "winRateFilters",
    winRateFilters !== ""
      ? winRateFilters.filter((it) => it.type === "Open_Year_Month")
      : ""
  );
  return {
    funnelFilters: winRateFilters,
    funnelData: winRateData.result,
    descriptionTableData: winRateData.descriptionTableData,
    funnelTableData: winRateData.computedResult,
    drilldownData: winRateDrilldownData,
    timeline: winRateData.timeline,
    uniqueTimeline:
      winRateData.uniqueTimeline !== undefined
        ? sortAscending(winRateData.uniqueTimeline)
        : winRateData.uniqueTimeline,
    company: user.company,
    Value_Label: user.Value_Label,
    Currency_Symbol: user.Currency,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    updatingFunnelData,
    winRateStages,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: (company) => {
    dispatch({ type: "get_win_rate_analysis_filters_request" });
    appService.getWinRateAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_win_rate_analysis_filters_success", json });
        dispatch({ type: "get_win_rate_analysis_data_request" });
        const deaultOppType = getDefaultSalesType(
          json.message.filter((i) => i.type === "Type"),
          "Type"
        );
        const defaultSolutionCat = getDefaultSalesType(
          json.message.filter((i) => i.type === "Solution_Cat"),
          "Solution_Cat"
        );
        let filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          const dealsPriorYearFilters = getDefaultSalesType(
            json.message.filter(
              (s) => s.type === "Prior_Year_Opp_Manually_Inserted"
            ),
            "Prior_Year_Opp_Manually_Inserted"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
          filters =
            dealsPriorYearFilters[0] === "All"
              ? filters
              : [
                  ...filters,
                  {
                    name: "Prior_Year_Opp_Manually_Inserted",
                    value: dealsPriorYearFilters,
                  },
                ];
        }
        filters =
          deaultOppType[0] === "All"
            ? filters
            : [...filters, { name: "Type", value: deaultOppType }];
        filters =
          defaultSolutionCat[0] === "All"
            ? filters
            : [...filters, { name: "Solution_Cat", value: defaultSolutionCat }];
        appService
          .getWinRateAnalysisData(filters, "closed_fiscal_quarter")
          .then(
            (json) => {
              dispatch({ type: "get_win_rate_analysis_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_win_rate_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({ type: "get_win_rate_analysis_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_win_rate_analysis_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_win_rate_analysis_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters, key) => {
    dispatch({ type: "get_win_rate_analysis_data_request" });
    appService.getWinRateAnalysisData(filters, key).then(
      (json) => {
        dispatch({ type: "get_win_rate_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_win_rate_analysis_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_win_rate_analysis_data_failure", error });
      }
    );
  },

  getDrilldownData: (filters, stage, quarter, key) => {
    dispatch({ type: "get_win_rate_analysis_drilldown_data_request" });
    appService
      .getWinRateAnalysisDrilldownData(filters, stage, quarter, key)
      .then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].map(
            (item, index) => {
              let x = {
                ...item,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              ...json.message,
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
              columns: json.message.columns,
            },
          };
          dispatch({
            type: "get_win_rate_analysis_drilldown_data_success",
            json,
          });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_win_rate_analysis_drilldown_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({
              type: "get_win_rate_analysis_drilldown_data_failure",
              error,
            });
        }
      );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedFunnel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Funnel));
export { connectedFunnel as WinRate };
