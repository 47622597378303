import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  withStyles,
  Paper,
  InputLabel,
  Select,
  TextField,
  Card,
  TableRow,
  TableCell,
} from "@material-ui/core";
import React, { PureComponent } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSalesType,
  countOrACVFormatter,
} from "../../util/customFunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RepBenchmarkingTable from "./BenchmarkingTable";
import TopRepsTable from "./TopRepsTable";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
    width: "3.6666%",
  },
  rankCol: {
    maxWidth: "1rem",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "white",
    background: "#4472C4",
    width: "3.6666%",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    width: "20%",
    color: "white",
    background: "#4472C4",
  },
  tableCard: {
    padding: "1rem 0rem",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  repBenchmarkingGrid: {
    padding: `0 ${window.innerWidth < 1368 ? 4 : 5}rem`,
  },
  colHeaderNew: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    fontWeight: "inherit",
    backgroundColor: "inherit",
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}
class RepBenchmarking extends PureComponent {
  state = {
    Opportunity_Record_Type: ["All"],
    Record_Type_Name__c: ["All"],
    Type: ["All"],
    year: ["all"],
    month: ["all"],
    quarter: ["all"],
    closed_fiscal_quarter: ["All"],
    disabled: ["year", "month", "quarter"],
    timeFrame: "quarter",
    topReps: [],
    topCalculated: false,
    topRepsTable: [],
    benchmarks: [],
    benchmarks_acv: [],
    acv: null,
    acvlt: null,
    firstStage: null,
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    Opp_Created_By: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Lead_Source: ["All"],
    Solution_Cat: ["All"],
    Region: ["All"],
    Cust_Type: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    team: ["All"],
    secondLevelLocation: null,
    filtersForDrilldown: [],
    Opp_Value: ["All"],
    ACV_Value: ["All"],
  };
  filters = [
    "closed_fiscal_quarter",
    "Acct_Vertical",
    "Acct_Segment",
    "Acct_Size_Segment",
    "Acct_Industry",
    "Opp_Created_By",
    "Booking_Type_NL_CS_US",
    "Lead_Source",
    "Solution_Cat",
    "Region",
    "Cust_Type",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "team",
    "Opp_Value",
    "ACV_Value",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
  ];

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          OppCreatedBy: "Opp_Created_By",
          SolutionCat: "Booking_Type_NL_CS_US",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          LeadSource: "Lead_Source",
          OppCreatedBy: "Opp_Created_By",
          SolutionCat: "Solution_Cat",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Record_Type_Name__c: "Record_Type_Name__c",
        };

  componentDidMount = () => {
    const params = localStorage.getItem("params");
    this.props.getfunnelCompareFilters(JSON.parse(params), this.props.company);
    //window.addEventListener('resize', this.onZoom)
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      let solutioncat = ["All"];
      dealsTypes = getDefaultSalesType(
        this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
        "Type"
      );
      solutioncat = getDefaultSalesType(
        this.props.funnelCompareFilters.filter(
          (s) => s.type === "Solution_Cat"
        ),
        "Solution_Cat"
      );
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }

      let OppVal = getDefaultSalesType(
        this.props.funnelCompareFilters.filter((s) => s.type === "Opp_Value"),
        "Opp_Value"
      );

      let ACVVal = getDefaultSalesType(
        this.props.funnelCompareFilters.filter((s) => s.type === "ACV_Value"),
        "ACV_Value"
      );

      this.setState({
        ...prevState,
        closed_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "closed_fiscal_quarter"
        ),
        cross_sell1__c: salesTypes,
        Opp_Value: OppVal,
        ACV_Value: ACVVal,
        Type: dealsTypes,
        Solution_Cat: solutioncat,
      });
    }
    if (this.props.currentFilters !== "" && prevProps.currentFilters === "") {
      const currentFilters = this.props.currentFilters;
      this.setState({
        ...this.state,
        closed_fiscal_quarter: currentFilters.filter(
          (f) => f.name == "closed_fiscal_quarter"
        )[0].value,
        acv:
          currentFilters.filter((f) => f.name == "acv")[0] &&
          d3.format(",")(
            String(currentFilters.filter((f) => f.name == "acv")[0].value)
          ),
      });
      console.log(this.acv);
    }
    console.log("newacv", this.state.closed_fiscal_quarter, this.state.acv);
  }

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (![null, undefined, ""].includes(this.state.acv)) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (![null, undefined, ""].includes(this.state.acvlt)) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.props.getfunnelCompareData(filters);

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
  };

  handleChangeLocation = (location, stage, lastStage, key, rep) => {
    var wonLabel = this.props.funnelCompareData.wonStage;
    //  console.log(stage, wonLabel)
    var header =
      lastStage || stage === wonLabel
        ? `${stage.replace(/_/g, " ")} Opportunities`
        : key === "lost"
        ? `Lost / Disqualified from ${
            Array.isArray(stage) ? "Open" : stage.replace(/_/g, " ")
          } stage`
        : key === "cameTo"
        ? `Came to ${stage.replace(/_/g, " ")} stage`
        : key === "movedOn"
        ? `Moved Forward from ${stage.replace(/_/g, " ")} stage`
        : `Opportunities lost at ${stage.replace(/_/g, " ")} stage`;

    let repFilters = [];
    if (rep) {
      repFilters = [
        { title: "Rep", value: typeof rep === "string" ? [rep] : rep },
      ];
    } else {
      repFilters = [{ title: "Rep", value: ["All"] }];
    }
    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage,
      lastStage: lastStage,
      header,
      repFilters: [
        {
          title: "Rep",
          value:
            typeof rep === "string" ? [rep] : rep.length === 1 ? rep : ["All"],
        },
      ],
      filtersForDrilldown: [...this.state.filtersForDrilldown],
    });

    let filters = this.props.currentFilters;
    if (rep !== "All") {
      filters = [
        ...filters,
        { name: "Owner_Name", value: typeof rep === "string" ? [rep] : rep },
      ];
    }

    if (location !== "overview") {
      this.props.getClosedDrilldownOnGraph(key, {
        stage: Array.isArray(stage) ? stage : [stage],
        filters: filters,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Qtr.",
          value: this.state.closed_fiscal_quarter.sort(),
        },
      ];
    }

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.Record_Type_Name__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opp Record Type", value: this.state.Record_Type_Name__c },
      ];

    if (!this.state.acv !== null && this.state.acv !== undefined)
      filtersArray = [
        ...filtersArray,
        {
          title: "ACV Greater Than",
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.Acct_Industry.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value:
            this.props.company === "C0003"
              ? this.state.Acct_Vertical
              : this.state.Acct_Industry,
        },
      ];

    if (!this.state.Cust_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value:
            this.props.company === "C0003"
              ? this.state.Region
              : this.state.Cust_Type,
        },
      ];
    if (!this.state.Lead_Source.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (!this.state.Solution_Cat.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    if (!this.state.Opportunity_Record_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];

    if (!this.state.deal_category__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];

    if (!this.state.Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0008" ? "Deal Type" : "Opp. Type",
          value: this.state.Type,
        },
      ];

    return filtersArray;
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
      repFilters: [],
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  onOppValueChange = (event, value) => {
    this.setState({
      ...this.state,
      Opp_Value: value,
    });
  };

  onACVValueChange = (event, value) => {
    this.setState({
      ...this.state,
      ACV_Value: value,
    });
  };

  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.repBenchmarkingGrid} xs={12}>
        {/* <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
          <Typography variant="h2" align="center" style={{ marginBottom: 5 }}>
            Rep Benchmarking Analysis
          </Typography>
          <Typography variant="body1">
            Benchmark conversion rates by sales stage for each rep against the
            cohort of top reps to identify blind spots for proactive, targeted
            intervention.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Rep Benchmarking Analysis",
          "Benchmark conversion rates by sales stage for each rep against the cohort of top reps to identify blind spots for proactive, targeted intervention."
        )}

        {this.props.funnelCompareFilters !== "" ? (
          <>
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Closed Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      name={"closed_fiscal_quarter"}
                      multiple
                      data-test="closed-fiscal-quarter"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === "closed_fiscal_quarter" &&
                              y.closed_fiscal_quarter !== undefined
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.closed_fiscal_quarter}
                              value={y.closed_fiscal_quarter}
                            >
                              {y.closed_fiscal_quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={3}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters.filter(
                        (s) => s.type === "acvFilter"
                      )[0].acv
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "4px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters.filter(
                        (s) => s.type === "acvFilter"
                      )[0].acv
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} <= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "4px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== "" &&
                              this.state.acvlt !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      data-test="industry"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      data-test="customer_segment"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      data-test="customer_type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
                style={{
                  fontSize: "3.2rem",
                  padding: "6px 20px",
                  marginBottom: "10px",
                }}
              >
                GO
              </Button>
            </Grid>

            <Grid container item xs={12}>
              {/* Lead source, opportunity created by, solution cat */}
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={12}
                md={6}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.LeadSource
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Lead Source:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.LeadSource]}
                        onChange={this.handleChange}
                        name={this.variableCols.LeadSource}
                        data-test="lead_source"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.LeadSource &&
                                y[this.variableCols.LeadSource] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.LeadSource] <
                              b[this.variableCols.LeadSource]
                                ? -1
                                : a[this.variableCols.LeadSource] >
                                  b[this.variableCols.LeadSource]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.LeadSource]}
                                value={y[this.variableCols.LeadSource]}
                              >
                                {y[this.variableCols.LeadSource]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.OppCreatedBy
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opportunity created by:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.OppCreatedBy]}
                        onChange={this.handleChange}
                        name={this.variableCols.OppCreatedBy}
                        data-test="opp_created_by"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.OppCreatedBy &&
                                y[this.variableCols.OppCreatedBy] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.OppCreatedBy] <
                              b[this.variableCols.OppCreatedBy]
                                ? -1
                                : a[this.variableCols.OppCreatedBy] >
                                  b[this.variableCols.OppCreatedBy]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.OppCreatedBy]}
                                value={y[this.variableCols.OppCreatedBy]}
                              >
                                {y[this.variableCols.OppCreatedBy]}
                              </MenuItem>
                            ))}{" "}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.SolutionCat
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Opportunity Type"
                          : "Solution Category"}
                        :
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.SolutionCat]}
                        onChange={this.handleChange}
                        name={this.variableCols.SolutionCat}
                        data-test="solution_category"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.SolutionCat &&
                                y[this.variableCols.SolutionCat] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.SolutionCat] <
                              b[this.variableCols.SolutionCat]
                                ? -1
                                : a[this.variableCols.SolutionCat] >
                                  b[this.variableCols.SolutionCat]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.SolutionCat]}
                                value={y[this.variableCols.SolutionCat]}
                              >
                                {y[this.variableCols.SolutionCat]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={12}
                md={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={this.props.funnelCompareFilters
                        .filter((f) => f.type === "Opp_Value")
                        .map((f) => f.Opp_Value)}
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onOppValueChange}
                      value={this.state.Opp_Value}
                      data-test="Opp_Value"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Pursued Opps >= :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: "0px", marginBottom: "0px" }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                parseInt(this.state.Opp_Value) > 0
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                {/* <InputAdornment position="start">
                                  $
                                </InputAdornment> */}
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={12}
                md={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={this.props.funnelCompareFilters
                        .filter((f) => f.type === "ACV_Value")
                        .map((f) => countOrACVFormatter(f.ACV_Value))}
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onACVValueChange}
                      value={this.state.ACV_Value}
                      data-test="ACV_Value"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Pursued ${this.props.Value_Label} >= :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: "0px", marginBottom: "0px" }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                parseInt(this.state.ACV_Value) > 0
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  {/* $ */}
                                  {this.props.Currency_Symbol}
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                            style: {
                              paddingTop: "0.35rem", // Add top padding here
                              ...params.InputProps.style, // Preserve any existing styles
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={12}
                md={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        label="Team"
                        data-test="team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter((i) => i.type === "team")
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}
              {this.props.company === "C0013" && (
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  md={2}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Opportunity_Record_Type.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opportunity Record Type:
                        </InputLabel>
                        <Select
                          data-test="Opportunity_Record_Type"
                          value={this.state.Opportunity_Record_Type}
                          onChange={this.handleChange}
                          name="Opportunity_Record_Type"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === "Opportunity_Record_Type" &&
                                  y.Opportunity_Record_Type !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y.Opportunity_Record_Type}
                                  value={y.Opportunity_Record_Type}
                                >
                                  {y.Opportunity_Record_Type}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* C0010 Opp Record Type */}
              {this.props.company === "C0010" && (
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  md={2}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Record_Type_Name__c.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opp Record Type:
                        </InputLabel>
                        <Select
                          data-test="Record_Type_Name__c"
                          value={this.state.Record_Type_Name__c}
                          onChange={this.handleChange}
                          name="Record_Type_Name__c"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === "Record_Type_Name__c" &&
                                  y.Record_Type_Name__c !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y.Record_Type_Name__c}
                                  value={y.Record_Type_Name__c}
                                >
                                  {y.Record_Type_Name__c}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {this.props.company === "C0017" && (
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  md={2}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Type.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opp. Type:
                        </InputLabel>
                        <Select
                          data-test="Type"
                          value={this.state.Type}
                          onChange={this.handleChange}
                          name="Type"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter((y) => y.type === "Type" && y.Type !== "")
                              .map((y) => (
                                <MenuItem key={y.Type} value={y.Type}>
                                  {y.Type}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {this.props.company === "C0001" && (
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  md={2}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.deal_category__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Category:
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.deal_category__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.deal_category__c}
                          data-test="deal_category__c"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols.deal_category__c &&
                                  y[this.variableCols.deal_category__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.deal_category__c]}
                                  value={y[this.variableCols.deal_category__c]}
                                >
                                  {y[this.variableCols.deal_category__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {this.props.company === "C0008" && (
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  md={4}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.cross_sell1__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Sales Type:
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.cross_sell1__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.cross_sell1__c}
                          data-test="cross_sell1__c"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.cross_sell1__c &&
                                  y[this.variableCols.cross_sell1__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.cross_sell1__c]}
                                  value={y[this.variableCols.cross_sell1__c]}
                                >
                                  {y[this.variableCols.cross_sell1__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[this.variableCols.Type].includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Type:
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.Type]}
                          onChange={this.handleChange}
                          name={this.variableCols.Type}
                          data-test="Type"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Type &&
                                  y[this.variableCols.Type] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Type]}
                                  value={y[this.variableCols.Type]}
                                >
                                  {y[this.variableCols.Type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        <Grid item xs={12} container className={classes.tableCard}>
          {console.log(this.props)}
          {this.props.funnelCompareData !== "" ? (
            <RepBenchmarkingTable
              title="Benchmark vs Top Reps"
              isConversionTable={true}
              highlightOnThreshold={true}
              benchmarkConversionRates={
                <>
                  <TableRow style={{ fontWeight: 600 }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convCount * 100)}%`}</TableCell>
                      )
                    )}
                    <TableCell align="center" className={classes.colHeaderNew}>
                      Top Reps
                    </TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convACV * 100)}%`}</TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow style={{ border: "none" }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                    <TableCell align="center"></TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                  </TableRow>
                </>
              }
              benchmarkingCondition={(item) =>
                item >= 20
                  ? { background: "#70ad47", color: "white", fontWeight: 600 }
                  : item <= -20
                  ? { background: "#ed7c31", color: "white", fontWeight: 600 }
                  : {}
              }
              titleColumns={[
                {
                  label: "By Opportunity Count",
                  background: "#bf8f00",
                  color: "white",
                },
                { label: "", background: "white", color: "white" },
                {
                  label: `By ${this.props.Value_Label}`,
                  background: "#548235",
                  color: "white",
                },
              ]}
              primaryColumns={this.props.stages
                .map((item, index) =>
                  index < this.props.stages.length - 1
                    ? {
                        from: this.props.stages[index],
                        to: this.props.stages[index + 1],
                      }
                    : undefined
                )
                .filter((item) => item !== undefined)}
              data={
                this.props.funnelCompareData.conversionRatesAgainstBenchmarks
              }
              teamData={
                this.props.funnelCompareData
                  .conversionRatesAgainstBenchmarksTeam
              }
            />
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container className={classes.tableCard}>
          {this.props.funnelCompareData !== "" ? (
            <RepBenchmarkingTable
              title="Conversion rates from stage to stage"
              isConversionTable={true}
              benchmarkConversionRates={
                <>
                  <TableRow style={{ fontWeight: 600 }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convCount * 100)}%`}</TableCell>
                      )
                    )}
                    <TableCell align="center" className={classes.colHeaderNew}>
                      Top Reps
                    </TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convACV * 100)}%`}</TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow style={{ border: "none" }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                    <TableCell align="center"></TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                  </TableRow>
                </>
              }
              titleColumns={[
                {
                  label: "By Opportunity Count",
                  background: "#bf8f00",
                  color: "white",
                },
                { label: "", background: "white", color: "white" },
                {
                  label: `By ${this.props.Value_Label}`,
                  background: "#548235",
                  color: "white",
                },
              ]}
              primaryColumns={this.props.stages
                .map((item, index) =>
                  index < this.props.stages.length - 1
                    ? {
                        from: this.props.stages[index],
                        to: this.props.stages[index + 1],
                      }
                    : undefined
                )
                .filter((item) => item !== undefined)}
              data={
                this.props.funnelCompareData.oppsConversionRatesStageToStage
              }
              teamData={
                this.props.funnelCompareData.oppsConversionRatesStageToStageTeam
              }
            />
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container className={classes.tableCard}>
          {this.props.funnelCompareData !== "" ? (
            <RepBenchmarkingTable
              title="Opportunities flowing from stage to stage"
              titleColumns={[
                {
                  label: "By Opportunity Count",
                  background: "#bf8f00",
                  color: "white",
                },
                { label: "", background: "white", color: "white" },
                {
                  label: `By ${this.props.Value_Label}`,
                  background: "#548235",
                  color: "white",
                },
              ]}
              primaryColumns={this.props.stages}
              isConversionTable={false}
              data={this.props.funnelCompareData.oppsFlowingFromStagetoStage}
              teamData={
                this.props.funnelCompareData.oppsFlowingFromStagetoStageTeam
              }
            />
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container className={classes.tableCard}>
          {this.props.funnelCompareData !== "" ? (
            <TopRepsTable
              title="Top Reps"
              benchmarkReps={this.props.funnelCompareData.benchmarkReps}
              data={this.props.funnelCompareData.topRepsTable}
              firstStage={this.props.funnelCompareData.firstStage}
              secondStage={this.props.funnelCompareData.secondStage}
              wonStage={this.props.funnelCompareData.wonStage}
              handleChangeLocation={this.handleChangeLocation}
              Value_Label={this.props.Value_Label}
              Win_Rate_Prefix={this.props.Win_Rate_Prefix}
            />
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={[
                ...this.state.filtersForDrilldown,
                ...this.state.repFilters,
              ]}
              // filters = {[]}
            >
              <Drilldown
                header={this.state.header}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}

        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    repBenchMarkingFilters,
    funnelCompareData,
    persistentAlert,
    repPerformanceData,
    user,
    currentFilters,
    drilldownOnGraphData,
    company,
    ExpectedAcvData,
  } = state.app;
  let lastStage = funnelCompareData.wonStage;
  var stages = null;
  if (funnelCompareData !== "") {
    stages = funnelCompareData.oppsFlowingFromStagetoStage
      .map((item) => item.stage)
      .filter(getUniqueValues)
      .reverse();
  }

  return {
    repPerformanceData,
    funnelCompareFilters: repBenchMarkingFilters,
    funnelCompareData,
    persistentAlert,
    sortedReps: funnelCompareData[0]
      ? [
          ...new Set(
            funnelCompareData[0]
              .filter((y) => y.stage === lastStage)
              .sort((a, b) => b.acv - a.acv)
              .map((j) => j.rep)
          ),
        ]
      : "",
    company: user.company,
    Value_Label: user.Value_Label,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    Currency_Symbol: user.Currency,
    stages,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getfunnelCompareData: (filters) => {
    dispatch({ type: "get_funnelCompare_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getRepBenchmarkingData(filters).then(
      (json) => {
        dispatch({ type: "get_funnelCompare_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnelCompare_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnelCompare_data_failure", error });
      }
    );
  },
  getfunnelCompareFilters: (paramsFilters, company) => {
    dispatch({ type: "get_repbenchmarking_filters_request" });
    appService.getRepBenchmarkingFilters().then(
      (json) => {
        dispatch({ type: "get_repbenchmarking_filters_success", json });
        let filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];

        if (paramsFilters) {
          filters = JSON.parse(paramsFilters);
        } else if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }

        const oppValues = getDefaultSalesType(
          json.message.filter((s) => s.type === "Opp_Value"),
          "Type"
        );

        const ACVValues = getDefaultSalesType(
          json.message.filter((s) => s.type === "ACV_Value"),
          "Type"
        );

        if (oppValues[0] !== "All") {
          filters = [...filters, { name: "Opp_Value", value: oppValues }];
        }

        if (ACVValues[0] !== "All") {
          filters = [...filters, { name: "ACV_Value", value: ACVValues }];
        }
        const deaultOppType = getDefaultSalesType(
          json.message.filter((s) => s.type === "Type"),
          "Type"
        );
        const defaultSolutionCat = getDefaultSalesType(
          json.message.filter((s) => s.type === "Solution_Cat"),
          "Solution_Cat"
        );

        filters =
          deaultOppType[0] === "All"
            ? filters
            : [...filters, { name: "Type", value: deaultOppType }];
        filters =
          defaultSolutionCat[0] === "All"
            ? filters
            : [...filters, { name: "Solution_Cat", value: defaultSolutionCat }];
        dispatch({ type: "current_selected_filters", filters });
        dispatch({ type: "get_funnelCompare_data_request" });
        appService.getRepBenchmarkingData(filters).then(
          (json) => {
            dispatch({ type: "get_funnelCompare_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_funnelCompare_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_funnelCompare_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnelCompare_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnelCompare_filters_failure", error });
      }
    );
  },
  getClosedDrilldownOnGraph: (key, filters) => {
    console.log(key, filters);
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.closedDrilldownOnGraph(key, filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedRepBenchmarking = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RepBenchmarking));
export default connectedRepBenchmarking;
