import moment from "moment";
import * as d3 from "d3";
import { updatedScheme10 } from "./chartColorScheme";
import { capitalize, TableRow, withStyles } from "@material-ui/core";
import { filter } from "d3";
import { store } from "./store";
export const customTickFormat = function (d) {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  //Logic to reduce big numbers
  var limits = [1000000000000000, 1000000000000, 1000000000, 1000000, 1000];
  var shorteners = ["Q", "T", "B", "M", "K"];
  for (var i in limits) {
    if (d > limits[i]) {
      if (shorteners[i] !== "K")
        return (
          `${Currency_Symbol}` + (d / limits[i]).toFixed(1) + shorteners[i]
        );
      else
        return `${Currency_Symbol}` + (d / limits[i]).toFixed() + shorteners[i];
    }
  }
  // return '$' + d;
};
export const convertToThousands = (value) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;
  return `${Currency_Symbol}${d3.format(",")(Math.round(value / 1000))}`;
};

export const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

export const stageFormattter = (stageName) => {
  if (
    (stageName.split("_").includes("ACV") ||
      stageName.split("_").includes("Opps")) &&
    /\(([^)]+)\)/.exec(stageName.split("_").join(" ")) !== null
  ) {
    var stageNameAcv = stageName.split("_").join(" ");
    if (stageName.split("_").includes("Opps")) {
      return /\(([^)]+)\)/.exec(stageNameAcv)[1] + " No of Opps";
    }
    return stageName.split("_").includes("%")
      ? /\(([^)]+)\)/.exec(stageNameAcv)[1] + " ACV %"
      : /\(([^)]+)\)/.exec(stageNameAcv)[1] + " ACV";
  }
  var stageName = stageName.split("_").join(" ");

  return /\(([^)]+)\)/.exec(stageName) !== null
    ? /\(([^)]+)\)/.exec(stageName)[1]
    : stageName;
};
function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}
export const getWeekStartOrMonthStart = (date, month) => {
  if (month) {
    var mom = moment(date);
    var calculatedMoment =
      mom.date() < 15 ? mom.set("date", 1) : mom.add(1, "month").set("date", 1);

    return calculatedMoment.valueOf();
  } else {
    var mom = moment(date);
    var calculatedMom = mom.day() >= 1 ? mom.add(7 - mom.day(), "date") : mom;

    return calculatedMom.valueOf();
  }
};
export const stageSortingFunction = (data, company, stage) => {
  var preferenceDictionary;

  const sortStage = stage.map((item, i) => ({ [item.stage_name]: i + 1 }));
  //  const computedSortStage = {...sortStage}
  const temp = stage.reduce(
    (obj, item, i) => Object.assign(obj, { [item.stage_name]: i + 1 }),
    {}
  );
  preferenceDictionary = temp;
  // for(const )

  return data.sort(
    (a, b) => preferenceDictionary[a.stage] - preferenceDictionary[b.stage]
  );
};

export const getYaxisFormatter = (minVal, maxVal, d) =>
  maxVal - minVal < 4000 ? d3.format(".3s")(d) : d3.format(".2s")(d);
export const isNullOrZero = (num) => (num === 0 || num === null ? "-" : num);

export const getHeaderForDrilldown = (table) => {
  if (table === "ytd") {
    return "Year to Date Attainment";
  } else if (table === "qtd") {
    return "Quarter To Date Attainment";
  } else if (table === "currentQP") {
    return "Qualified Pipeline Current Quarter Close";
  } else if (table === "nextQP") {
    return "Qualified Pipeline Next Quarter Close";
  } else if (table === "MQO31") {
    return "MQOs generated in the last 31 days";
  } else if (table === "MQO60") {
    return "MQOs generated in the last 60 days";
  } else if (table === "MQO90") {
    return "MQOs generated in the last 90 days";
  } else if (table === "nqp") {
    return "New Qualified Pipeline";
  } else if (typeof table.split("_")[1] === "string") {
    const [key, stage] = table.split("_");
    return stage === "Closed Won"
      ? `${stage} Opportunities`
      : `Opportunities lost at ${stage} stage`;
  } else if (typeof table.split("|")[1] === "string") {
    const [key, days] = table.split("|");
    let header;
    if (key === "newCreated") {
      header = "Created";
    } else if (key === "newCreatedQualified") {
      header = "Created and Qualified";
    }
    return `${header} in the last ${days} days`;
  } else {
    return table
      .split(" ")
      .map((it) => capitalize(it))
      .join(" ");
  }
};
export function getUniqueValues(value, index, self) {
  return self.indexOf(value) === index;
}
export const replaceHyphens = (str) => str.replace(/-/g, "_");
export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

export const orderRepsByLastName = (reps) => {
  reps = reps
    .map((r) => r.Full_Name)
    .map((rep) => {
      const res = rep.split(" ", 2);
      return {
        firstName: res[0],
        lastName: res[1],
        fullName: rep,
        sortName: `${res[1]} ${res[0]}`,
      };
    });
  function compare(a, b) {
    if (a.sortName < b.sortName) {
      return -1;
    }
    if (a.sortName > b.sortName) {
      return 1;
    }
    return 0;
  }
  reps.sort(compare);
  reps = [
    { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
    ...reps,
  ];
  const menuItems = reps.map((rep, index) => {
    return rep.fullName;
  });
  return menuItems;
};
export const dateSubractorInISO = (dateString, numDays) =>
  new Date(new Date(dateString).getTime() - 86400000 * numDays).toISOString();
export const dateSubtractorMoment = ({ year, month, date }, numDays) =>
  moment([year, month, date]).subtract(numDays, "days");
export const dateSubractorDateInISO = (dateString, numDays) => {
  let customDate;
  if (typeof dateString === "string") {
    var [monthFromString, dayFromString, yearFromString] =
      dateString.split("-");
    monthFromString = parseInt(monthFromString) - 1;
    dayFromString = parseInt(dayFromString);
    yearFromString = parseInt(yearFromString);
    customDate = Date.UTC(yearFromString, monthFromString, dayFromString);
    return new Date(customDate - 86400000 * numDays).toISOString();
  } else {
    return new Date(dateString.getTime() - 86400000 * numDays).toISOString();
  }
};
export const isdateGreater = (dateA, dateB) =>
  new Date(dateA).getTime() > new Date(dateB).getTime() ? true : false;
export const isdateLesser = (dateA, dateB) =>
  new Date(dateA).getTime() > new Date(dateB).getTime() ? true : false;
export const dateFormatterCalenderControl = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  })
    .format(params)
    .replaceAll("/", "-");
};
export const dateFormatter = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  })
    .format(new Date(params))
    .replaceAll("/", "-");
};
export const dateFormatterV3 = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  }).format(new Date(params));
};
export const dateFormatterMonthInWords = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "short",
    day: "numeric",
    year: "numeric",
  })
    .format(new Date(params))
    .replaceAll("/", "-");
};
export const customDateFormatter = (dateString) => {
  let customDate = new Date(dateString);

  let { day, month, year } = new Intl.DateTimeFormat("en", {
    timeZone: "UTC",
    day: "2-digit",
    month: "short",
    year: "numeric",
  })
    .formatToParts(customDate)
    .reduce((acc, part) => {
      if (part.type != "literal") {
        acc[part.type] = part.value;
      }
      return acc;
    }, Object.create(null));
  return `${month}${
    new Intl.DateTimeFormat("en", { timeZone: "UTC", month: "short" }).format(
      customDate
    ) ===
    new Intl.DateTimeFormat("en", { timeZone: "UTC", month: "long" }).format(
      customDate
    )
      ? ""
      : "."
  } ${("0" + day).slice(-2)}, ${year}`;
};

export const dateFormatterMomentV4 = (date) => {
  const splitDateT = date.split("T");
  const splitDate = splitDateT[0].split("-");
  const momentDate = moment([
    parseInt(splitDate[0]),
    parseInt(splitDate[1]) - 1,
    parseInt(splitDate[2]),
  ]);
  const formattedDate = momentDate.format("MMM. DD, YYYY");
  return formattedDate;
};

export const customDateFormatterNew = (dateString) => {
  const dateSplit = dateString.split("-");
  const dateArr = [
    parseInt(dateSplit[2]),
    parseInt(dateSplit[0]) - 1,
    parseInt(dateSplit[1]),
  ];
  let customDate = moment(dateArr);

  let { day, month, year } = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })
    .formatToParts(customDate)
    .reduce((acc, part) => {
      if (part.type != "literal") {
        acc[part.type] = part.value;
      }
      return acc;
    }, Object.create(null));
  return `${month}${
    new Intl.DateTimeFormat("en", { timeZone: "UTC", month: "short" }).format(
      customDate
    ) ===
    new Intl.DateTimeFormat("en", { timeZone: "UTC", month: "long" }).format(
      customDate
    )
      ? ""
      : "."
  } ${("0" + day).slice(-2)}, ${year}`;
};

export const UTCToDateString = (dateString) => dateString.split("T")[0];
export const staticCustSegment = [
  { value: "<=$25M", displayValue: "<=$25M" },
  { value: "$25-50M", displayValue: "$25M-50M" },
  { value: "$50-250M", displayValue: "$50M-250M" },
  { value: "$250-500M", displayValue: "$250M-500M" },
  { value: "$500M-2B", displayValue: "$500M-2B" },
  { value: ">$2B", displayValue: ">$2B" },
];

// export const countOrACVFormatter = (value, isAcv) =>
//   isAcv
//     ? `$${d3.format(",")(Math.round(value))}`
//     : `${d3.format(",")(Math.round(value))}`;

export const countOrACVFormatter = (value, isAcv) => {
  //For Adding Currency Symbol
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  return isAcv
    ? `${Currency_Symbol}${d3.format(",")(Math.round(value))}`
    : `${d3.format(",")(Math.round(value))}`;
};

export const countOrACVFormatterThousand = (value, isAcv) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;
  return isAcv
    ? `${Currency_Symbol}${d3.format(",")(Math.round(value / 1000))}K`
    : `${d3.format(",")(Math.round(value))}`;
};
export const countOrACVFormatterThousandNew = (value, isAcv) =>
  isAcv
    ? `${d3.format(",")(Math.round(value / 1000))}`
    : `${d3.format(",")(Math.round(value))}`;
export const countOrACVFormatterMillion = (value, isAcv) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;
  return isAcv
    ? `${Currency_Symbol}${(value / 1000000).toFixed(2)}M`
    : `${d3.format(",")(Math.round(value))}`;
};
export const countOrACVFormatterMillionFixedOne = (value, isAcv) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;
  return isAcv
    ? `${Currency_Symbol}${(value / 1000000).toFixed(1)}M`
    : `${d3.format(",")(Math.round(value))}`;
};
export const floatFormatter = (data) => parseFloat(data.toFixed(2));
export const percentageFormatter = (value) => `${Math.round(value * 100)}%`;

export const sum = (input) => {
  if (toString.call(input) !== "[object Array]") return false;

  var total = 0;
  for (var i = 0; i < input.length; i++) {
    if (_isNaN(input[i])) {
      continue;
    }
    total += Number(input[i]);
  }
  return total;
};
export const getCSVFileNameString = (filename) => `SkyGeni-Export.csv`;
export const csvDateFormatter = (date) => date.replace("T", " ").slice(0, -1);
export const getNewQualifiedLabelName = (numOfWeeks) =>
  `Added in the past ${numOfWeeks} weeks`;
export const quarterToDateLabelName = (isNumber, acv) =>
  `Total ${isNumber ? "#" : "ACV"} of Opps >= ${countOrACVFormatterThousand(
    acv,
    true
  )}:`;
export const getAverageLabelName = (type) => `Avg ${type} created per week:`;
export const dateFormatterMoment = ({ year, month, date }) =>
  moment([year, month - 1, date]).format("MM-DD-YYYY");

export const dateFormatterMomentV2 = (date) => {
  const splitDate = date.split("-");
  const momentDate = moment([
    parseInt(splitDate[0]),
    parseInt(splitDate[1]) - 1,
    parseInt(splitDate[2]),
  ]);
  const formattedDate = momentDate.format("MM/DD/YYYY");
  return formattedDate;
};

export const dateFormatterMomentV3 = (date) => {
  const splitDateT = date.split("T");
  const splitDate = splitDateT[0].split("-");
  const momentDate = moment([
    parseInt(splitDate[0]),
    parseInt(splitDate[1]) - 1,
    parseInt(splitDate[2]),
  ]);
  const formattedDate = momentDate.format("MM/DD/YYYY");
  return formattedDate;
};

export const getTimingArr = (params, timingFormating, splitter = " ") => {
  const [date, time] = params.split(splitter);
  var [year, month, dt] = date.split("-");
  if (timingFormating) {
    var [hour, min, sec] = time.split(":");
    sec = sec.split(".")[0];
    return [year, month, dt, hour, min, sec].map((it) => parseInt(it));
  }
  return [year, month, dt].map((it) => parseInt(it));
};
export const agGridDateFormatter = (x) => {
  if (x.value === null) {
    return "";
  } else {
    let tparts = x.value.split("T");
    let parts = tparts[0].split("-");
    return (
      parseInt(parts[1]) + "/" + parseInt(parts[2]) + "/" + parseInt(parts[0])
    );
  }
};
export const percentFormatter = (data) => `${Math.round(data * 100)}%`;
export const percentFormatterWithDecimal = (data) =>
  `${(data * 100).toFixed(1)}%`;
export const percentFormatterWithoutConversion = (data) =>
  `${Math.round(data)}%`;
export const dateOppPyramidFormatter = (d) => {
  let tparts = d.split("T");
  let parts = tparts[0].split("-");
  return new Date(+parts[0], +parts[1], +parts[0]);
};

//returns fiscal quarters which are selected by default
export const getDefaultSelectedFiscalQuarters = (filters, fiscalQuarter) => {
  let selected_by_default_fiscal_quarters = [];
  selected_by_default_fiscal_quarters = filters
    .filter(
      (q) =>
        q.selected_by_default === "Y" && q[`${fiscalQuarter}`] !== undefined
    )
    .map((x) => x[fiscalQuarter]);
  return selected_by_default_fiscal_quarters;
};

//returns the inital whatif data
export const getwhatidData = (funnelData) => {
  let whatifData;
  if (funnelData !== undefined && funnelData !== "") {
    whatifData = funnelData;
  }
  return whatifData;
};

//returns stage sequence which the stage selected by default
export const getDefaultSelectedStage = (filters) => {
  let selected_by_default_stage;
  selected_by_default_stage = filters.filter(
    (q) => q.selected_by_default === "Y"
  )[0].sequence;
  return selected_by_default_stage;
};

export const getDefaultSelectedStageV2 = (filters, type) => {
  let selected_by_default_stage;
  selected_by_default_stage = filters.filter(
    (q) => q.selected_by_default === "Y" && q.type === type
  )[0].sequence;
  return selected_by_default_stage;
};

export const getDefaultACV = (acv) => {
  let acvValue = "";
  if ([null, undefined, ""].includes(acv) || acv !== []) {
    if (acv.filter((f) => f.Selected_by_Default === "Y").length > 0) {
      acvValue = acv.filter((f) => f.Selected_by_Default === "Y")[0].acv;
    } else {
      return acvValue;
    }
  }
  return acvValue;
};

export const getDefaultThreshold = (thresholdValues) => {
  let acvValue = 0;
  console.log(thresholdValues);
  if (
    [null, undefined, ""].includes(thresholdValues) ||
    thresholdValues !== []
  ) {
    acvValue = thresholdValues.filter((f) => f.Selected_by_Default === "Y")[0]
      .THRESHOLDS_VALUES;
  }
  return acvValue;
};

export const getCustomSortedColoredLegends = (data, type, key) => {
  var legends = [];
  let stages = data.map((s) => s[type]).filter(getUniqueValues);
  if (type === "FQ_Moved") {
    stages = stages.filter((s) => s !== "Grand Total");
  }
  const dataTotal = stages
    .map((stage) => {
      return {
        value: data
          .filter((item) => item[type] === stage)
          .reduce((a, b) => a + b[key], 0),
        stage,
      };
    })
    .sort((a, b) => (a.value > b.value ? -1 : 1));
  stages = dataTotal.map((s) => s.stage).filter(getUniqueValues);
  stages = [...stages, "Total"];
  if (type === "FQ_Moved") {
    stages = [">= 1 Quarter"];
  }

  stages.map((s, i) => {
    legends.push({
      text: s,
      color: updatedScheme10[i > 9 ? (i > 19 ? i - 20 : i - 10) : i],
    });
  });
  return legends;
};

export const getCustomSortedColoredLegendsV2 = (data, type, key) => {
  var legends = [];
  let stages = data.map((s) => s[type]).filter(getUniqueValues);
  if (type === "FQ_Moved") {
    stages = stages.filter((s) => s !== "Grand Total");
  }
  const dataTotal = stages
    .map((stage) => {
      return {
        value: data
          .filter((item) => item[type] === stage)
          .reduce((a, b) => a + b[key], 0),
        stage,
      };
    })
    .sort((a, b) => (a.value > b.value ? -1 : 1));
  stages = dataTotal.map((s) => s.stage).filter(getUniqueValues);
  stages = [...stages];
  if (type === "FQ_Moved") {
    stages = [">= 1 Quarter"];
  }

  stages.map((s, i) => {
    legends.push({
      text: s,
      color: updatedScheme10[i > 9 ? (i > 19 ? i - 20 : i - 10) : i],
    });
  });
  return legends;
};

export const getStageNames = (filters, slectedStageSequence) => {
  let stage_names = [];
  filters.map((i) => {
    if (i.sequence !== undefined) {
      if (i.sequence >= slectedStageSequence)
        stage_names = [...stage_names, i.stage_name];
    }
  });
  return stage_names;
};

//select default sales type
export const getDefaultSalesType = (filters, type) => {
  if ([null, undefined, ""].includes(filters)) {
    return ["All"];
  }
  let salesTypes = [];
  console.log(filters, "Thresholds");
  salesTypes = filters
    .filter((q) => q.selected_by_default === "Y")
    .map((s) => s[type]);
  console.log(salesTypes);
  return salesTypes.length > 0 ? salesTypes : ["All"];
};

export const countOrACVFormatterRoundToNearestN = (value, N, isAcv) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;
  return isAcv
    ? `${Currency_Symbol}${d3.format(",")(Math.round(value / N) * N)}`
    : `${d3.format(",")(Math.round(value / N) * N)}`;
};
export const countOrACVFormatterMillionNew = (value, isAcv) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  return isAcv
    ? `${Currency_Symbol}${d3.format(",")(Math.round(value / 1000000))}M`
    : `${d3.format(",")(Math.round(value))}`;
};

// export const getCSVFileNameString = filename => `${filename}.skygeni-exports.csv`

export const roundUp = (number, digits) => {
  var factor = Math.pow(10, digits);
  return Math.ceil(number * factor) / factor;
};

export const dateFormatterv2 = (d) => {
  const date = new Date(d);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Adding 1 as months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const dateFormatterv3 = (d) => {
  const date = new Date(d);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 as months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
};

export const roundToNearestN = (value, N) => Math.round(value / N) * N;

export const getDefaultTopDeals = (filters, type) => {
  let selected_by_default_stage;
  selected_by_default_stage = filters.filter(
    (q) => q.selected_by_default === "Y" && q.type === type
  )[0].no_of_top_deals;
  return selected_by_default_stage;
};
