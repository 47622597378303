import React, { useRef, useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AgGridReact } from "ag-grid-react";

import "./FunnelDrilldownBody.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import {
  Grid,
  Card,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import "./OpenDrilldownBody.css";
import { store } from "../../util/store";
// import './FunnelDrilldownBody.scss';

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "40px",
    paddingBottom: "30px",
  },
  tableHeader: {
    fontSize: "0.85rem",
    fontWeight: 500,
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    fontSize: "0.85rem",
    whiteSpace: "normal",
  },
  svg: {
    marginTop: "15px",
    marginLeft: "40px",
    marginBottom: "35px",
    width: "400px",
    height: "170px",
    overflow: "visible",
  },
  marginForGraph: {
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 75,
    },
  },
}));

const OpenDrilldownBody = (props) => {
  const classes = useStyle();
  const myRef = useRef();

  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };

  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const onCellClicked = (e) => {
    if (e.data.count && e.data.count !== 0 && e.data.Name !== "Total") {
      props.handleChangeLocation("drilldownOnOpen", e.data.Name, false);
    }
  };

  const onGraphClicked = (e) => {
    if (e.count && e.count !== 0) {
      props.handleChangeLocation("drilldownOnOpen", e.Name, false);
    }
  };

  const onGraphLabelClicked = (e) => {
    let temp = props.data.filter((i) => i.Name === e)[0];

    if (temp.count && temp.count !== 0) {
      props.handleChangeLocation("drilldownOnOpen", temp.Name, false);
    }
  };
  const currencyFormatter = (params) => {
    return `${Currency_Symbol} ${d3.format(",")(Math.round(params.value))}`;
  };

  useEffect(() => {
    if (props.data) {
      // console.log(Math.round((Math.max(...props.data.map(i => i.total_acv ? i.total_acv : 0))) / 1000000)*1000000 )

      let heighDisplacementForLegend = -3;

      function wrap(text, width) {
        text.each(function () {
          var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(" "));
            // if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
            // }
          }
          if (text.text().length > 10 && heighDisplacementForLegend !== 28) {
            heighDisplacementForLegend = 28;
          }
        });
      }

      var container = d3.select(myRef.current),
        width = window.innerWidth < 500 ? 250 : 400,
        height = 170,
        tickSize = 0,
        yTicks = 5,
        xTicks = 2,
        axisPadding = 5;
      var svg = container;
      svg
        .selectAll("*")
        .remove()
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      const xScale = d3
        .scaleBand()
        //no of points
        .domain(props.data.map((v) => v.Name))
        //pixel stretch
        .range([0, width])
        .padding(0.5);

      const yScale = d3
        .scaleLinear()
        .domain([
          0,
          Math.ceil(
            Math.max(
              ...props.data.map((i) => (i.total_acv ? i.total_acv : 0))
            ) / 1000000
          ) * 1000000,
        ])
        .range([height, 0]);
      const yScale2 = d3
        .scaleLinear()
        .domain([
          0,
          Math.ceil(
            Math.max(...props.data.map((i) => (i.count ? i.count : 0))) / 10
          ) * 10,
        ])
        .range([height, 0]);

      const xAxis = d3
        .axisBottom(xScale)
        .ticks(xTicks)
        .tickSizeInner(tickSize)
        .tickSizeInner(tickSize);
      const yAxis = d3
        .axisLeft(yScale)
        .ticks(yTicks)
        .tickFormat(function (d) {
          return `${Currency_Symbol} ${d3.format(".2s")(d)}`.toUpperCase();
        })
        .tickSizeInner(tickSize)
        .tickSizeInner(tickSize);
      const yAxis2 = d3
        .axisRight(yScale2)
        .ticks(yTicks)
        .tickSizeInner(tickSize)
        .tickSizeInner(tickSize);

      function make_y_gridlines() {
        return d3.axisLeft(yScale).ticks(yTicks);
      }
      svg
        .append("g")
        .attr("class", "grid")
        .call(make_y_gridlines().tickSize(-width).tickFormat(""));

      svg
        .selectAll("rect")
        .data(props.data)
        .join("rect")
        .attr("transform", "scale(1,-1)")

        .attr("x", (value) => xScale(value.Name))
        .attr("y", (value) => -height)
        .attr("width", xScale.bandwidth())
        .attr("height", (d) => {
          return height - yScale(d.total_acv);
        })
        .attr("fill", "#70ad47")
        .style("opacity", "0.9")
        .attr("cursor", "pointer")
        .on("click", (i) => onGraphClicked(i))
        .on("mouseover", function (d, i) {
          d3.select(this).transition().style("opacity", "0.7");
        })
        .on("mouseout", function (d, i) {
          d3.select(this).transition().style("opacity", "0.9");
        });

      svg
        .selectAll("circle")
        .data(props.data)
        .join("rect")
        //   .attr('r',v => 20)
        .attr("x", (value) => xScale(value.Name) + (xScale.bandwidth() / 2 - 5))
        .attr("y", (value) => yScale2(value.count) - 3)
        .attr("width", 10)
        .attr("height", (d) => 10)
        .attr("fill", "#4472c4")
        .attr("stroke", "#adadad")
        .attr("stroke-width", "1px")
        .attr("cursor", "pointer")
        .on("click", (i) => onGraphClicked(i))
        .on("mouseover", function (d, i) {
          d3.select(this).transition().style("opacity", "0.5");
        })
        .on("mouseout", function (d, i) {
          d3.select(this).transition().style("opacity", "1");
        });

      svg
        .append("g")
        .attr("class", "x axis")
        .style("font-size", "0.85rem")
        .attr("transform", `translate(0,${height + 0})`)
        .attr("cursor", "pointer")
        .call(xAxis)
        .selectAll("text")
        .call(wrap, xScale.bandwidth())
        .style("cursor", "pointer")
        // .attr("transform", `translate(0,${22})`)
        .on("click", (i) => onGraphLabelClicked(i))
        .on("mouseover", function (d, i) {
          d3.select(this).transition().style("opacity", "0.5");
        })
        .on("mouseout", function (d, i) {
          d3.select(this).transition().style("opacity", "1");
        });

      // Add the Y Axis
      svg
        .append("g")
        .attr("class", "y axis")
        .style("font-size", "0.85rem")
        .style("transform", `translateX(${0}px)`)
        .call(yAxis)
        .selectAll("text")
        .attr("transform", `translate(-10,0)`);

      svg
        .append("g")
        .attr("class", "y axis 2")
        .style("font-size", "0.85rem")
        .style("transform", `translateX(${width}px)`)
        .call(yAxis2)
        .selectAll("text")
        .attr("transform", `translate(10,0)`);

      svg
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("x", width === 400 ? 100 : 30)
        .attr("y", 215 + heighDisplacementForLegend)
        .style("fill", "#70ad47");
      svg
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("x", width === 400 ? 200 : 120)
        .attr("y", 215 + heighDisplacementForLegend)
        .style("fill", "#4472c4");
      svg
        .append("text")
        .style("font-size", "0.85rem")
        .attr("x", width === 400 ? 120 : 50)
        .attr("y", 224 + heighDisplacementForLegend)
        .text(props.Value_Label);
      svg
        .append("text")
        .style("font-size", "0.85rem")
        .attr("x", width === 400 ? 220 : 140, 220)
        .attr("y", 224 + heighDisplacementForLegend)
        .text("Opportunities");
    }
  });

  return (
    <div style={{ marginTop: "40px", marginBottom: "40px" }}>
      <Grid container className={classes.root}>
        {props.data ? (
          <React.Fragment>
            <Grid
              item
              container
              sm={12}
              md={6}
              justify="center"
              alignContent="space-between"
            >
              <div
                className="ag-theme-custom-react"
                style={{
                  height: "200px",
                  width: window.innerWidth < 500 ? "250px" : "450px",
                }}
              >
                <AgGridReact
                  columnDefs={[
                    {
                      headerName: "Stage",
                      field: "Name",
                      minWidth: 170,
                      sortable: true,
                      cellStyle: {
                        "padding-left": "5px",
                        "padding-top": "3px",
                        "padding-bottom": "3px",
                        cursor: "pointer",
                      },
                      cellClass: classes.tableCell,
                      headerClass: classes.tableHeader,
                    },
                    {
                      headerName: "Opportunities",
                      field: "count",
                      type: "rightAligned",
                      minWidth: 100,
                      sortable: true,
                      filter: true,
                      cellStyle: {
                        "padding-right": "5px",
                        "padding-top": "3px",
                        "padding-bottom": "3px",
                        cursor: "pointer",
                      },
                      valueFormatter: (item) => (item.value ? item.value : 0),
                      headerClass: classes.tableHeader,
                      sortingOrder: ["desc", null],
                    },
                    {
                      headerName: props.Value_Label,
                      field: "total_acv",
                      type: "rightAligned",
                      minWidth: 100,
                      sortable: true,
                      filter: true,
                      cellStyle: {
                        "padding-right": "5px",
                        "padding-top": "3px",
                        "padding-bottom": "3px",
                        cursor: "pointer",
                      },
                      valueFormatter: currencyFormatter,
                      headerClass: classes.tableHeader,
                      sortingOrder: ["desc", "asc"],
                    },
                  ]}
                  gridAutoHeight={true}
                  headerHeight={35}
                  rowData={props.data}
                  onFirstDataRendered={onFirstDataRendered}
                  scrollbarWidth={0}
                  onCellClicked={onCellClicked}
                  defaultColDef={defaultColDef}
                  onColumnResized={onColumnResized}
                  enableCellTextSelection={true}
                  autoHeight={true}
                  colResizeDefault={"shift"}
                  onGridReady={gridReady}
                  pinnedBottomRowData={[
                    {
                      Name: "Total",
                      count: props.data.reduce(
                        (total, item) => total + item.count,
                        0
                      ),
                      total_acv: props.data.reduce(
                        (total, item) => total + item.total_acv,
                        0
                      ),
                      Number: "2",
                    },
                  ]}
                ></AgGridReact>
              </div>
            </Grid>

            <Grid
              item
              container
              sm={12}
              md={6}
              className={classes.marginForGraph}
              justify="center"
              alignContent="center"
            >
              <svg ref={myRef} className={classes.svg}>
                <g className="x-axis"></g>
                <g className="y-axis"></g>
                <g className="y-axis-2"></g>
              </svg>
              {/* <Grid container direction="row" justify="center" alignContent='center' style={{}}>

                                <Grid item style={{ marginRight: '50px', marginLeft: '50px' }}>
                                    <Grid container direction="row" justify="space-between" alignContent='center'>
                                        <div style={{ height: '10px', width: '10px', marginRight: '8px', marginTop: '3px', background: '#70ad47' }}></div>
                                        <Typography variant="body2" style={{ textTransform: 'capitalize' }}>{"ACV"}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="space-between" alignContent='center'>
                                        <div style={{ height: '10px', width: '10px', marginRight: '8px', marginTop: '3px', background: '#4472c4' }}></div>
                                        <Typography variant="body2" style={{ textTransform: 'capitalize' }}>{"Opportunities"}</Typography>

                                    </Grid>

                                </Grid>
                            </Grid> */}
            </Grid>
          </React.Fragment>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
};

export default OpenDrilldownBody;
