import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import OffIcon from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  passwordValidation,
  passwordValidationLabel,
} from "./password-validaton";
import ResetPasswordFormikComponent from "../../App/ResetPasswordFormikComponent";
let AccountSettings = (props) => {
  useEffect(() => {
    props.getSettings();
  }, []);
  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      spacing={1}
      direction="column"
    >
      <Grid item md={7}>
        <Box mt={0} mb={2}>
          <ResetPasswordFormikComponent
            type="changepassword"
            title="Change Password"
            changePass={props.changePass}
            errorMessage={""}
            open={false}
            alert={{}}
            classes={{}}
            setState={{}}
            persistentAlert={{}}
            handleChangeLocation={{}}
            forgotPass={{}}
            forgotPassEmail={{}}
          />
        </Box>
        <Divider variant="fullWidth" />
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  const { persistentAlert, alert, settings, version, settingsPage } = state.app;

  return {
    persistentAlert,
    location: settingsPage,
    alert,
    settings,
    version,
  };
}
const mapDispatchToProps = (dispatch) => ({
  changePass: (oldpassword, newpassword) => {
    dispatch({ type: "change_pass_loggedin_request" });
    appService.changePassLoggedIn(oldpassword, newpassword).then(
      (json) => {
        dispatch({ type: "change_pass_loggedin_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "change_password_loggedin_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "change_password_loggedin_failure", error });
      }
    );
  },
  getSettings: () => {
    dispatch({ type: "get_settings_request" });
    appService.getSettings().then(
      (json) => {
        dispatch({ type: "get_settings_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_settings_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_settings_failure", error });
      }
    );
  },
});
AccountSettings = connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
export default AccountSettings;
