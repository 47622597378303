import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { sort } from "d3-array";
import React, { Component } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import { countOrACVFormatter, customDateFormatterNew, getDefaultSelectedFiscalQuarters, getUniqueValues } from "../../util/customFunctions";
import addHeaderdescription from "../../components/HeaderDescription";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD"
import PizzaBoxesGraph from "./PizzaBoxesGraph";
import { Autocomplete } from "@material-ui/lab";
import DataTable from "./DataTable";
import DataTablev2 from "./DataTablev2";
import DataTablev3 from "./DataTablev3";
import SingleLevelDrilldownForAreaOfFocus from "../../components/Drilldown/SingleLevelDrilldownForAreaOfFocus";


const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class ProductsByAccount extends Component {
  state = {
      Account_Name:['All'],
    location: null,
    secondLevelLocation: null,
    toggleValue: "Acv",
    showWeeklyAvg: false,
    header: 'POCs Summary',
    tab: 'account',
    BU: ['All'],
    Product_Name_SFDC: ['All'],
    Region: ['All'],
    ModalOpen: false,
    updateFiltersObj: [],
    filtersValue: {},
    selectedValue: '',
    Region_Account_Name: '',
    Account_NameU: ['All'],
    RegionU: ['All']
  };
  componentDidMount = () => {
    this.props.getfunnelCompareFilters();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
  

        const defaultBUName = getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters.BU,
          "BU"
        );

        const defaultRegion = getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters.Region,
          "Region"
        );

       let defaultPG = this.props.funnelCompareFilters.productGroup.filter(f => f.BU === defaultBUName[0]).map(f => f.Product_Name_SFDC)[0][0]
       defaultPG = typeof defaultPG === 'string' ? [defaultPG] : defaultPG

       let defaultAccountName = this.props.funnelCompareFilters.Account_Name.filter(f => f.Region === defaultRegion[0]).map(f => f.Account_Name)[0][0]
       defaultAccountName = typeof defaultAccountName === 'string' ? [defaultAccountName] : defaultAccountName

      this.setState({
        ...this.state,
        Account_Name: defaultAccountName,
        BU: defaultBUName,
        Region: defaultRegion,
        Product_Name_SFDC: defaultPG,
        Region_Account_Name: defaultRegion,
        Account_NameU: defaultAccountName,
        RegionU: defaultRegion
      });
    }
  }

  handleChangeTab = (e) => {
    this.handleGo();
    // console.log(e.target.value);
    this.setState({
      ...this.state,
      tab: e.target.value,
    });
  };

  handleChange = (event, value) => {
      const selectedAccount = value ? [value] : ['All']; // Set selected value or default to 'All'
      this.setState({
        Account_Name: selectedAccount, // Update state with selected value
      });
    };

    handleChangeBU = (event, value) => {
      const selectedAccount = value ? [value] : ['All']; // Set selected value or default to 'All'
      console.log(selectedAccount, this.props.funnelCompareFilters.productGroup.filter(f => f.BU === value).map(f => f.Product_Name_SFDC), 'PGGGGGGG')
      const pg = this.props.funnelCompareFilters.productGroup.filter(f => f.BU === value).map(f => f.Product_Name_SFDC)[0][0]
      this.setState({
        BU: selectedAccount, // Update state with selected value
        Product_Name_SFDC: typeof pg === 'string' ? [pg] : pg
      });
    };

    handleChangeRegion = (event, value) => {
      const selectedAccount = value ? [value] : ['All']; // Set selected value or default to 'All'
      this.setState({
        Region: selectedAccount, // Update state with selected value
      });
    };

    handleChangeRegionNew = (event, value) => {
      const selectedAccount = value ? [value] : ['All']; // Set selected value or default to 'All'
      // console.log(selectedAccount, this.props.funnelCompareFilters.productGroup.filter(f => f.BU === value).map(f => f.Product_Name_SFDC), 'PGGGGGGG')
      const pg = this.props.funnelCompareFilters.Account_Name.filter(f => f.Region === value).map(f => f.Account_Name)[0][0]
      // console.log(this.props.funnelCompareFilters.Account_Name.filter(f => f.Region === value).map(f => f.Account_Name), 'PGGGGGGGGGGGGGGGGGGGG')
      this.setState({
        Region_Account_Name: selectedAccount, // Update state with selected value
        Account_Name: typeof pg === 'string' ? [pg] : pg
      });
    };

    handleChangeNew = (e) => {

      this.setState({
        ...this.state,
        [e.target.name]:
           e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value, // Ensure at least "All" is selected if no other value exists
      });
    };
  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  handleModalClose = () => {
    // setModalOpen(false);
    this.setState({
      ...this.state,
      ModalOpen: false
    })
  };

handleDrilldownChange = (value) => {
    console.log("Selected Value in Parent:", value);
  };

  handleGo = () => {
    var filters = [];
    let regionFilters = []
    if(this.state.Account_Name[0] !== 'All'){
      const obj = {name: 'Account_Name', value: typeof this.state.Account_Name === 'string' ? [this.state.Account_Name] : this.state.Account_Name}
     filters = [...filters, obj] 
  }

  if(this.state.BU[0] !== 'All'){
    const obj = {name: 'BU', value: typeof this.state.BU === 'string' ? [this.state.BU] : this.state.BU}
    filters = [...filters, obj] 
  }

  if(this.state.Region[0] !== 'All'){
    const obj = {name: 'Region', value: typeof this.state.Region === 'string' ? [this.state.Region] : this.state.Region}
    regionFilters = [...regionFilters, obj] 
  }

  if(this.state.Region_Account_Name[0] !== 'All'){
    const obj = {name: 'Region', value: typeof this.state.Region_Account_Name === 'string' ? [this.state.Region_Account_Name] : this.state.Region_Account_Name}
    filters = [...filters, obj] 
  }

  if(this.state.Product_Name_SFDC[0] !== 'All'){
    const obj = {name: 'Product_Name_SFDC', value: typeof this.state.Product_Name_SFDC === 'string' ? [this.state.Product_Name_SFDC] : this.state.Product_Name_SFDC}
    filters = [...filters, obj] 
  }


    this.setState({ ...this.state, Account_NameU: this.state.Account_Name, RegionU: this.state.RegionU });
    // if(this.state.tab === 'Region'){
    // }else{
      this.props.getCallsCompletedDataByRegion(regionFilters)
      this.props.getCallsCompletedData(filters);
    // }
  };

  handleAreaOfFocus = (location, obj)=> {
    let h = []
    if(obj.bg === '#008000'){
      let text = `Mark ${obj.Product_Group} as NOT Sold to this Account`
      h.push({label:text, value: 'Not_Active'})
      this.setState({ ...this.state,
      location: location,
      ModalOpen: true,
      drilldownHeader: h,
      updateFiltersObj: obj
      })
    }else{
      if(obj.bg === '#FFBF00'){
        let t = `Remove ${obj.Product_Group} as Targeted in 2025`
        h.push({label:t, value: 'Remove_Targeted'})
        obj.set = 'remove'
      }else{
        let t1 = `Mark ${obj.Product_Group} as Targeted in 2025`
        h.push({label:t1, value: 'Set_Target'})
        let t2 = `Mark ${obj.Product_Group} as Sold to this Account`
        h.push({label:t2, value: 'Set_Active'})
        obj.set = 'set'
      }
     
      
    this.setState({ ...this.state,
      location: location,
      ModalOpen: true,
    drilldownHeader: h,
    updateFiltersObj: obj
     });
    }
    // this.props.getCallsCompletedUpdateData(filters, updateFilters)
  }


  handleRadioChange = (e) => {
    console.log(e.target.value, this.state.updateFiltersObj, 'RADIOCHANGE')  
    const val = e.target.value

    let obj = {}
    if(val === 'Set_Target'){
       obj = {
        name: 'Area_Of_Focus',
        value: 'Yes'
       }
    }

    if(val === 'Set_Active'){
      obj = {
        name: 'Active_At_Account',
        value: 'Yes'
       }
    }

    if(val === 'Remove_Targeted'){
      obj = {
        name: 'Area_Of_Focus',
        value: 'No'
       }
    }

    if(val === 'Not_Active'){
      obj = {
        name: 'Active_At_Account',
        value: 'No'
       }
    }

    this.setState({...this.state,
      filtersValue: obj,
      selectedValue: val
    })

  } 
    
   


  handleContinue = () => {
    console.log( 'RADIOCHANGE')

 
      var filters = [];
      let regionFilters = []
      let updateFilters = []
      if(this.state.Account_NameU[0] !== 'All'){
        const obj = {name: 'Account_Name', value: typeof this.state.Account_NameU === 'string' ? [this.state.Account_Name] : this.state.Account_NameU}
       filters = [...filters, obj] 
       updateFilters = [...updateFilters, obj]
    }
  
    if(this.state.BU[0] !== 'All'){
      const obj = {name: 'BU', value: typeof this.state.BU === 'string' ? [this.state.BU] : this.state.BU}
      filters = [...filters, obj] 
    }
  
    if(this.state.Region[0] !== 'All'){
      const obj = {name: 'Region', value: typeof this.state.RegionU === 'string' ? [this.state.RegionU] : this.state.RegionU}
      regionFilters = [...regionFilters, obj] 
    }

    if(this.state.Region_Account_Name[0] !== 'All'){
      const obj = {name: 'Region', value: typeof this.state.Region_Account_Name === 'string' ? [this.state.Region] : this.state.Region_Account_Name}
      filters = [...filters, obj] 
      updateFilters = [...updateFilters, obj]
    }
  
    if(this.state.Product_Name_SFDC[0] !== 'All'){
      const obj = {name: 'Product_Name_SFDC', value: typeof this.state.Product_Name_SFDC === 'string' ? [this.state.Product_Name_SFDC] : this.state.Product_Name_SFDC}
      filters = [...filters, obj] 
    }

    const BU = {name: 'BU', value: typeof this.state.updateFiltersObj.BU === 'string' ? [this.state.updateFiltersObj.BU] : this.state.updateFiltersObj.BU}
    const pg = {name: 'Product_Group', value: typeof this.state.updateFiltersObj.Product_Group === 'string' ? [this.state.updateFiltersObj.Product_Group] : this.state.updateFiltersObj.Product_Group}
    const accountID = {name: 'Account_ID', value: typeof this.state.updateFiltersObj.Account_ID === 'string' ? [this.state.updateFiltersObj.Account_ID] : this.state.updateFiltersObj.Account_ID}
 
    updateFilters = [...updateFilters, BU, pg, accountID, this.state.filtersValue]
    this.setState({
      ...this.state,
      location: '',
      ModalOpen: false,
      selectedValue: ''
    })
    this.props.getCallsCompletedUpdateData(filters, updateFilters)
  } 


  handleCancel = () => {
    this.setState({
      ...this.state,
      location: '',
      ModalOpen: false,
       selectedValue: ''
    })
  }
    

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };
  handleChangeLocation = (location, quarter, key) => {
    if (location !== "overview") {
      let filters = [];
      let drildownFilters = [];

      let key2 = 'active'
      if(key === 'Pocs Active at Qtr. Start'){
        key2 = `active`
      }

      if(key === 'POCs Started during Qtr.'){
        key2 = `start`
      }

      if(key === 'POCs Ended during Qtr.'){
        key2 = `end`
      }

     if(quarter){
      const obj = {name: 'quarter', value: typeof quarter === 'string' ? [quarter] : quarter}
      filters = [obj]
     }

      filters = [
        ...filters,
      ];

      drildownFilters = [
        ...drildownFilters,
        {
          title: "Earliest Start Quarter",
          value:
            this.state.POC_Start_Fiscal_Quarter
        },
        {
          title: "Latest Start Quarter",
          value:
            this.state.POC_End_Fiscal_Quarter
        },
        {
          title: "Fiscal Quarter",
          value:
            quarter
        }
      ];


      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        // header:key1 === 'Won' ? 'Won' : key1 === 'Open' ? `Open Pipeline (${this.state.Stage[0]} or later)` : `Lost or Disqualified (${this.state.Stage[0]} or later)`,
        filtersForDrilldown: [...drildownFilters],
        header: key
      });

      this.props.getCallsCompletedDrilldownData(filters, key2);
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    // console.log(oppId);
    this.setState({
      ...this.state,
      // location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>Calls Completed</Typography>
        </Grid>
        <Typography variant='body1'>
        Analyze the cadence and consistency of customer calls completed
        </Typography> */}
        {addHeaderdescription(
          "White Space Analysis + Products by Account",
          "Analyze the white space at each account plus look at product groups sold to each account"
        )}
        

        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            {
              this.state.tab === 'account' ?
              <Grid container item style={{ padding: 10 }} xs={6} md={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} style={{ paddingTop: 10 }}>
               <Autocomplete
      id="combo-box-demo"
      options={
        this.props.funnelCompareFilters !== "" && this.props.funnelCompareFilters.Region
          .filter((item) => item.Region !== "")
          .map((f) => f.Region) || []
      }
      getOptionLabel={(option) => option}
      value={this.state.Region_Account_Name[0] || null} // Ensure a valid value for Autocomplete
      onChange={this.handleChangeRegionNew}
      name="Region"
      data-test="Region"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Region:"
          variant="standard"
          InputLabelProps={{
            style: {
              color: !this.state.Region_Account_Name.includes("All")
                ? "#4472c4"
                : "#000",
            },
          }}
        />
      )}
    />
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 10 }}>
<FormControl fullWidth className={classes.formControl}>
  <InputLabel
    id="select-Account_Name-label"
    style={{
       color: this.state.Account_Name[0] !== 'All' ? "#4472c4" : '',
    }}
  >
   Account Name:
  </InputLabel>
  <Select
    data-test="Account_Name_SFDC"
    labelId="select-Account_Name_SFDC-label"
    id="select-Account_Name_SFDC"
    value={this.state.Account_Name}
    onChange={this.handleChangeNew}
    label="Account Name"
    name="Account_Name"
  >
    {/* <MenuItem value={"All"}>All</MenuItem> */}
    {this.props.funnelCompareFilters !== "" &&
      Array.from(
        new Set(
          this.props.funnelCompareFilters.Account_Name
            .filter((item) => this.state.Region_Account_Name.includes(item.Region))
            .reduce(
              (acc, curr) => acc.concat(curr.Account_Name || []),
              []
            )
        )
      ).filter(getUniqueValues).map((y) => (
        <MenuItem key={y} value={y}>
          {y}
        </MenuItem>
      ))}
  </Select>
</FormControl>
</Grid>

            </Grid>
            </Grid>
               : 
               this.state.tab === 'PG'
               ?
               <Grid container item style={{ padding: 10 }} xs={6} md={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} style={{ paddingTop: 10 }}>
               <Autocomplete
      id="combo-box-demo"
      options={
        this.props.funnelCompareFilters !== "" && this.props.funnelCompareFilters.BU
          .filter((item) => item.BU !== "")
          .map((f) => f.BU) || []
      }
      getOptionLabel={(option) => option}
      value={this.state.BU[0] || null} // Ensure a valid value for Autocomplete
      onChange={this.handleChangeBU}
      name="BU"
      data-test="Business Unit"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Business Unit:"
          variant="standard"
          InputLabelProps={{
            style: {
              color: !this.state.BU.includes("All")
                ? "#4472c4"
                : "#000",
            },
          }}
        />
      )}
    />
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 10 }}>
<FormControl fullWidth className={classes.formControl}>
  <InputLabel
    id="select-Product_Name_SFDC-label"
    style={{
       color: this.state.Product_Name_SFDC[0] !== 'All' ? "#4472c4" : '',
    }}
  >
    Product Group:
  </InputLabel>
  <Select
    data-test="Product_Name_SFDC"
    labelId="select-Product_Name_SFDC-label"
    id="select-Product_Name_SFDC"
    value={this.state.Product_Name_SFDC}
    onChange={this.handleChangeNew}
    label="Product_Name_SFDC"
    name="Product_Name_SFDC"
  >
    {/* <MenuItem value={"All"}>All</MenuItem> */}
    {this.props.funnelCompareFilters !== "" &&
      Array.from(
        new Set(
          this.props.funnelCompareFilters.productGroup
            .filter((item) => this.state.BU.includes(item.BU))
            .reduce(
              (acc, curr) => acc.concat(curr.Product_Name_SFDC || []),
              []
            )
        )
      ).filter(getUniqueValues).map((y) => (
        <MenuItem key={y} value={y}>
          {y}
        </MenuItem>
      ))}
  </Select>
</FormControl>
</Grid>

            </Grid>
            </Grid>
            :
              <Grid container item style={{ padding: 10 }} xs={4} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                {/* <FormControl
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel
                    id="select-fiscalQuarter-label"
                    style={{
                      color: "#4472c4"
                    }}
                  >
                    Account Name:
                  </InputLabel>
                  <Select
                    data-test="Account_Name"
                    labelId="select-fiscalQuarter-label"
                    id="select-fiscalQuarter"
                    value={this.state.Account_Name}
                    onChange={this.handleChange}
                    label="Account Name"
                    name="Account_Name"
                  >
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters.Account_Name
                        .filter((item) => item.Account_Name !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.Account_Name}
                            value={y.Account_Name}
                          >
                            {y.Account_Name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl> */}
               <Autocomplete
      id="combo-box-demo"
      options={[
        "All", // Add "All" option at the beginning
        ...(this.props.funnelCompareFilters !== "" && this.props.funnelCompareFilters.Region
          .filter((item) => item.Region !== "")
          .map((f) => f.Region)) || []
      ]}
      getOptionLabel={(option) => option}
      value={this.state.Region[0] || null} // Ensure a valid value for Autocomplete
      onChange={this.handleChangeRegion}
      name="Region"
      data-test="Region"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Region:"
          variant="standard"
          InputLabelProps={{
            style: {
              color: !this.state.Region.includes("All")
                ? "#4472c4"
                : "#000",
            },
          }}
        />
      )}
    />
                </Grid>
              
              
              </Grid>
            </Grid> 
            }
            

            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={20}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )
        }
{this.props.funnelCompareFilters !== "" ? (
<Grid item xs={12} md={12} id="grid-container" style={{marginTop: '1rem'}}>
        <Paper style={{ marginBottom: "-7px" }}>
          <ToggleButtonGroup
            color="primary"
            indicatorColor="primary"
            value={this.state.tab}
            exclusive
            size="large"
            onChange={this.handleChangeTab}
          >
            <ToggleButton
              style={{
                background: this.state.tab === "account" ? "#4472C4" : "",
                color: this.state.tab === "account" ? "white" : "",
                fontSize: "1rem",
                fontWeight: 600,
                height: "3.5rem",
                width: "20rem",
              }}
              value="account"
            >
              By Account
            </ToggleButton>
            <ToggleButton
              style={{
                background: this.state.tab === "PG" ? "#4472C4" : "",
                color: this.state.tab === "PG" ? "white" : "",
                fontSize: "1rem",
                fontWeight: 600,
                height: "3.5rem",
                width: "20rem",
              }}
              value="PG"
            >
              By BU+Product Group
            </ToggleButton>
            <ToggleButton
              style={{
                background: this.state.tab === "Region" ? "#4472C4" : "",
                color: this.state.tab === "Region" ? "white" : "",
                fontSize: "1rem",
                fontWeight: 600,
                height: "3.5rem",
                width: "20rem",
              }}
              value="Region"
            >
              By Region
            </ToggleButton>
          </ToggleButtonGroup>
        </Paper>
      </Grid>
):(
<Card style={{ margin: "auto", width: "100%", marginTop: '1rem', marginBottom: '1rem' }} raised={true}>
  <Grid item container justify="center">
    <Skeleton
      variant="react"
      animation="wave"
      height={20}
      width="90%"
      style={{ margin: "1rem" }}
    />
  </Grid>
</Card>
)
  }
        

        {
          this.state.tab === 'PG' ? 
          <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
        

{this.props.CallsCompletedData !== "" ? (
            <Grid item container xs={12} justify="flex-start" style={{marginTop: '1rem'}}>
              <Card style={{ padding: "1rem 2rem", width: '100%', }}>
              
              
                <Grid item container xs={12} justify="center" >
                  
                <DataTablev2 data={this.props.CallsCompletedData.BUProductGroupData} columns={this.props.CallsCompletedData.columnsBUProductGroup}
                dataTest='dataByBUProduct-tableData'
                />
                 
                  </Grid>

                  <Grid item container xs={12} justify="center" >
                  
                  <DataTablev3 data={this.props.CallsCompletedData.tableDataByWhiteSpaceBUPG.filter(f => f.Product_Group !== 'Total')} columns={this.props.CallsCompletedData.columnsWhiteSpaceBUProductGroup} title={'White space at the following accounts'}
                  totalData={this.props.CallsCompletedData.tableDataByWhiteSpaceBUPG.filter(f => f.Product_Group === 'Total')}
                  font={'body1'}
                  dataTest='dataByBUPG-BU-by-account'
                  />
                   
                    </Grid>
              </Card>
            </Grid>

            
          ) : (
            <Grid item container component={Card} justify="center" style={{marginTop: '1rem'}}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
          
          : 
          this.state.tab === 'Region' ? 
          <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
        

{this.props.ProductsByAccountDataByRegion !== "" ? (
            <Grid item container xs={12} justify="flex-start" style={{marginTop: '1rem'}}>
              <Card style={{ padding: "1rem 2rem", width: '100%', }}>
              <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >
                      
                     Total White space{this.props.ProductsByAccountDataByRegion.dataByAccount.filter(f => f.Account_Name === 'Total').length > 0 && `: ${countOrACVFormatter(this.props.ProductsByAccountDataByRegion.dataByAccount.filter(f => f.Account_Name === 'Total').map(f => f.List_Price).reduce((a,b) => a+b, 0), true)}`}
        
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                  </Grid>
                </Grid>
              
                <Grid item container xs={12} justify="center" >
                  
                <DataTablev3 data={this.props.ProductsByAccountDataByRegion.dataByAccount.filter(f => f.Account_Name !== 'Total')} columns={this.props.ProductsByAccountDataByRegion.columnsByAccount} title={'White space by Account'}
                  totalData={this.props.ProductsByAccountDataByRegion.dataByAccount.filter(f => f.Account_Name === 'Total')}
                  font={'body1'}
                  dataTest='dataByRegion-BU-by-account'
                  />
                 
                  </Grid>
              </Card>
            </Grid>

            
          ) : (
            <Grid item container component={Card} justify="center" style={{marginTop: '1rem'}}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}


{this.props.ProductsByAccountDataByRegion !== "" ? (
            <Grid item container xs={12} justify="flex-start" style={{marginTop: '1rem'}}>
              <Card style={{ padding: "1rem 2rem", width: '100%', }}>
              

                <Grid item container xs={12} justify="center" >
                  
                <DataTablev3 data={this.props.ProductsByAccountDataByRegion.dataByBU.filter(f => f.BU !== 'Total')} columns={this.props.ProductsByAccountDataByRegion.columnsbyBU} title={'White Space - Summary by BU'}
                  totalData={this.props.ProductsByAccountDataByRegion.dataByBU.filter(f => f.BU === 'Total')}
                  font={'body1'} dataTest='dataByRegion-BU'
                  />
                 
                  </Grid>
              
                <Grid item container xs={12} justify="center" >
                  
                <DataTablev3 data={this.props.ProductsByAccountDataByRegion.dataByBUAndProduct.filter(f => f.Product_Name_SFDC !== 'Total')} columns={this.props.ProductsByAccountDataByRegion.columnsBUProductGroup} title={'White space by BU + Product Group'}
                  totalData={this.props.ProductsByAccountDataByRegion.dataByBUAndProduct.filter(f => f.Product_Name_SFDC === 'Total')}
                  font={'body1'}
                  dataTest='dataByRegion-Product-BU'
                  />
                 
                  </Grid>
              </Card>
            </Grid>

            
          ) : (
            <Grid item container component={Card} justify="center" style={{marginTop: '1rem'}}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
          :
          <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {
  this.state.Account_Name === this.state.Account_NameU &&
  this.state.Region === this.state.RegionU ? (
    <>
      {this.props.CallsCompletedData && this.props.CallsCompletedData.data ? (
        <Grid container xs={12} justifyContent="flex-start">
          <Card style={{ padding: "1rem 2rem", width: "100%" }}>
            <Grid container xs={12} justifyContent="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography
                  variant="h2"
                  style={{
                    width: "100%",
                    margin: "auto",
                    padding: "1rem",
                    paddingTop: "0.3rem",
                  }}
                  align="center"
                >
                  {`Products By Account at ${this.state.Account_NameU}`}
                </Typography>
              </Grid>
              <Grid item xs={1} align="right"></Grid>
            </Grid>

            <PizzaBoxesGraph
              data={this.props.CallsCompletedData.data}
              notActiveProducts={this.props.CallsCompletedData.notActiveProducts}
              handleChangeLocation={this.handleAreaOfFocus}
            />
          </Card>
        </Grid>
      ) : (
        <Grid container component={Card} justifyContent="center">
          <Skeleton
            animation="wave"
            variant="rect"
            width="80%"
            height={200}
            style={{ margin: "20px 0px" }}
          />
        </Grid>
      )}

      {this.props.CallsCompletedData && this.props.CallsCompletedData.tableData ? (
        <Grid container xs={12} justifyContent="flex-start" style={{ marginTop: "1rem" }}>
          <Card style={{ padding: "1rem 2rem", width: "100%" }}>
            <Grid container xs={12} justifyContent="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography
                  variant="h2"
                  style={{
                    width: "100%",
                    margin: "auto",
                    padding: "1rem",
                    paddingTop: "0.3rem",
                    fontWeight: 600,
                  }}
                  align="center"
                >
                  White space at this account
                  {this.props.CallsCompletedData.tableData.length > 0 &&
                    `: ${countOrACVFormatter(
                      this.props.CallsCompletedData.tableData
                        .map((f) => f.List_price)
                        .reduce((a, b) => a + b, 0),
                      true
                    )}`}
                </Typography>
              </Grid>
              <Grid item xs={1} align="right"></Grid>
            </Grid>

            <Grid container xs={12} justifyContent="center">
              <DataTablev3
                data={this.props.CallsCompletedData.tableDataByBU.filter((f) => f.BU !== "Total")}
                columns={this.props.CallsCompletedData.columnsByBU}
                totalData={this.props.CallsCompletedData.tableDataByBU.filter((f) => f.BU === "Total")}
                title={"White Space - Summary by BU"}
                font={"body1"}
                dataTest="dataByAccount-BU"
              />
            </Grid>

            <Grid container xs={12} justifyContent="center">
              <DataTable
                data={this.props.CallsCompletedData.tableData}
                columns={this.props.CallsCompletedData.columns}
                font={"body1"}
                dataTest="dataByAccount-Product-BU"
              />
            </Grid>
          </Card>
        </Grid>
      ) : (
        <Grid container component={Card} justifyContent="center" style={{ marginTop: "1rem" }}>
          <Skeleton
            animation="wave"
            variant="rect"
            width="80%"
            height={200}
            style={{ margin: "20px 0px" }}
          />
        </Grid>
      )}
    </>
  ) : (
    this.props.CallsCompletedData && 
    <Card style={{ padding: "1rem 2rem", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "60vh" }}>
  <Grid container justifyContent="center" alignItems="center">
    <Typography variant="h2" style={{ color: "#808080", textAlign: "center" }}>
      Filter selections changed. Please hit "Go" to see the products at this account.
    </Typography>
  </Grid>
</Card>

    
  )
}

        </Grid>
        }
       

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={this.state.header}
                body={this.props.CallsCompletedDrilldownData}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
              {/* <Drilldown body={this.props.CallsCompletedDrilldownData} isMetaData={this.state.isMetaData} metaData={this.state.metaData} drilldownLabelString={this.state.drilldownLabelString} 
               company={this.props.company} 
              handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
               />   */}
                               

            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}

{this.state.location === "drilldownAreaOfFocus" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
           <SingleLevelDrilldownForAreaOfFocus
    header={this.state.drilldownHeader}
    isOpen={this.state.ModalOpen}
    onClose={this.handleDrilldownClose}
    handleRadioChange={this.handleRadioChange}
    handleContinue={this.handleContinue}
    handleCancel={this.handleCancel}
    selectedValue={this.state.selectedValue}
  />
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    user,
    ProductsByAccountData,
    drilldownOnGraphData,
    ExpectedAcvData,
    ProductsByAccountFilters,
    currentFilters,
    ProductsByAccountDataByRegion
  } = state.app;

  return {
    company: user.company,
    CallsCompletedData:ProductsByAccountData , 
    CallsCompletedDrilldownData: drilldownOnGraphData,
    funnelCompareFilters: ProductsByAccountFilters,
    ExpectedAcvData,
    currentFilters,
    ProductsByAccountDataByRegion
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearDrilldownData: () => {
    dispatch({ type: "clear_ProductsByAccount_drilldown_data" });
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_ProductsByAccount_filters_request" });
    appService.getProductsByAccountFilters().then(
      (json) => {
        dispatch({ type: "get_ProductsByAccount_filters_success", json });
        dispatch({ type: "get_ProductsByAccount_data_request" });
        // const defaultAccountName = getDefaultSelectedFiscalQuarters(
        //   json.message.Account_Name,
        //   "Account_Name"
        // );
        const defaultBUName = getDefaultSelectedFiscalQuarters(
          json.message.BU,
          "BU"
        );
        const defaultRegion = getDefaultSelectedFiscalQuarters(
          json.message.Region,
          "Region"
        );
       
        let filters = [];
        let regionFilters = [];
      
        if (defaultRegion.length > 0) {
          const pg = json.message.Account_Name.filter(f => f.Region === defaultRegion[0]).map(f => f.Account_Name)[0][0]
          filters = [...filters, { name: "Region", value: defaultRegion }, {name: "Account_Name", value: typeof pg === 'string' ? [pg] : pg}];
        }
        if(defaultBUName){
          filters = [...filters, { name: "BU", value: defaultBUName }];
          const pg = json.message.productGroup.filter(f => f.BU === defaultBUName[0]).map(f => f.Product_Name_SFDC)[0][0]
          console.log(pg, 'PGGGGGG')
          filters = [...filters, {name: 'Product_Name_SFDC', value: typeof pg === 'string' ? [pg] : pg}]
        }
        if(defaultRegion){
          regionFilters = [...regionFilters, { name: "Region", value: defaultRegion }];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService.getProductsByAccountData(filters).then(
          (json) => {
            dispatch({ type: "get_ProductsByAccount_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_ProductsByAccount_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_ProductsByAccount_data_failure", error });
          }
        );
        appService.getProductsByAccountDataByRegion(regionFilters).then(
          (json) => {
            dispatch({ type: "get_ProductsByAccountByRegion_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_ProductsByAccountByRegion_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_ProductsByAccountByRegion_data_failure", error });
          }
        );
      },
      
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_ProductsByAccount_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_ProductsByAccount_filters_failure", error });
      }
    );
  },
  getCallsCompletedDrilldownData: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getProductsByAccountdDrilldownData(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          },
        };

        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getCallsCompletedData: (filters) => {
    dispatch({ type: "get_ProductsByAccount_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getProductsByAccountData(filters).then(
      (json) => {
        dispatch({ type: "get_ProductsByAccount_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_ProductsByAccount_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_ProductsByAccount_data_failure", error });
      }
    );
  },
  getCallsCompletedUpdateData: (filters, updateFilters) => {
    dispatch({ type: "get_ProductsByAccount_update_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getProductsByAccountUpdateData(filters, updateFilters).then(
      (json) => {
        dispatch({ type: "get_ProductsByAccount_update_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_ProductsByAccount_update_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_ProductsByAccount_update_data_failure", error });
      }
    );
  },
  getCallsCompletedDataByRegion: (filters) => {
    dispatch({ type: "get_ProductsByAccountByRegion_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getProductsByAccountDataByRegion(filters).then(
      (json) => {
        dispatch({ type: "get_ProductsByAccountByRegion_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_ProductsByAccountByRegion_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_ProductsByAccountByRegion_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedProductsByAccount = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductsByAccount));
export default connectedProductsByAccount;
