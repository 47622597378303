import React, { useEffect } from "react";
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useState } from "react";
import GrowthModelTable from "./GrowthModelTable";
import {
  countOrACVFormatter,
  roundToNearestN,
} from "../../util/customFunctions";
import Tooltip from "@material-ui/core/Tooltip";
import { appService } from "../../App/app.service";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import QualipiplineTable from "./QualifiedPipelineTable";
import QualipiplineTablev2 from "./QualifiedPipelineV2";
import MeanMedianTable from "./MeanMedianTable";
import { store } from "../../util/store";

const GM = (props) => {
  const Symbol = store.getState();
  const Currency_Symbol = Symbol.app.user.Currency;

  const val = (props.selectedACVValue[0].acv / props.uniquarters.length) * 1.5;
  const defaultQuarter = props.futureQuarters.filter(
    (f) => f.selected_by_default === "Y"
  )[0].fiscal_quarter;
  const DEFAULT_VALUE = Math.round(val / 1000000) * 1000000;
  const [target, setTarget] = useState(DEFAULT_VALUE);
  const [toggle, setToggle] = useState(true);
  const [editTarget, setEditTarget] = useState(DEFAULT_VALUE);
  const [data, setData] = useState(null);

  const [fiscalQuarter, setFiscalQuarter] = useState(defaultQuarter);

  const vA = [null, undefined, ""].includes(
    props.data.filter(
      (f) =>
        f.Stage_at_time_T ===
        "A: Won from Opps qualified or later at start of qtr. with PCD in qtr."
    )[0].perc
  )
    ? 0
    : props.data.filter(
        (f) =>
          f.Stage_at_time_T ===
          "A: Won from Opps qualified or later at start of qtr. with PCD in qtr."
      )[0].perc * 100;

  const vB = [null, undefined, ""].includes(
    props.data.filter(
      (f) =>
        f.Stage_at_time_T ===
        "B: Qualified and Won in the same qtr. and NOT Qualified or later at start of qtr."
    )[0].perc
  )
    ? 0
    : props.data.filter(
        (f) =>
          f.Stage_at_time_T ===
          "B: Qualified and Won in the same qtr. and NOT Qualified or later at start of qtr."
      )[0].perc * 100;

  const vC = [null, undefined, ""].includes(
    props.data.filter(
      (f) =>
        f.Stage_at_time_T ===
        "C: Won from Opps qualified or later at start of qtr. with PCD NOT in qtr. (Pulled in)"
    )[0].perc
  )
    ? 0
    : props.data.filter(
        (f) =>
          f.Stage_at_time_T ===
          "C: Won from Opps qualified or later at start of qtr. with PCD NOT in qtr. (Pulled in)"
      )[0].perc * 100;
  const valueConA = ![null, undefined, ""].includes(props.dataA[0].convPerc)
    ? props.dataA[0].convPerc * 100
    : 0;
  const valueConB = ![null, undefined, ""].includes(props.dataB[0].convPerc)
    ? props.dataB[0].convPerc * 100
    : 0;
  const valueConC = ![null, undefined, ""].includes(props.dataC[0].convPerc)
    ? props.dataC[0].convPerc * 100
    : 0;

  const valA = Math.round(
    (parseFloat(vA.toFixed(1)) / 100) *
      DEFAULT_VALUE *
      (1 / (parseFloat(valueConA.toFixed(1)) / 100))
  );

  const valB = Math.round(
    (parseFloat(vB.toFixed(1)) / 100) *
      DEFAULT_VALUE *
      (1 / (parseFloat(valueConB.toFixed(1)) / 100))
  );

  const valC = Math.round(
    (parseFloat(vC.toFixed(1)) / 100) *
      DEFAULT_VALUE *
      (1 / (parseFloat(valueConC.toFixed(1)) / 100))
  );

  const [valueFromChildA, setValueFromChildA] = useState(
    roundToNearestN(valA, 10000)
  );
  const [valueFromChildB, setValueFromChildB] = useState(
    roundToNearestN(valB, 10000)
  );
  const [valueFromChildC, setValueFromChildC] = useState(
    roundToNearestN(valC, 10000)
  );

  // Function to handle data from child
  const handleChildDataA = (data) => {
    setValueFromChildA(data);
  };

  const handleChildDataB = (data) => {
    setValueFromChildB(data);
  };

  const handleChildDataC = (data) => {
    setValueFromChildC(data);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(255,255,255,0.8)",
      color: "black",
      fontFamily: "roboto",
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "0.5rem",
      padding: "0px",
      boxShadow: "0 0.1rem 0.1rem 0",
      marginTop: "0.1rem",
    },
  }))(Tooltip);

  const toggleInput = () => {
    setToggle(false);
  };

  const onSave = (target) => {
    setEditTarget(target);
    setToggle(true);
  };

  const handleChange = (e) => {
    setTarget(e.target.value);
  };

  const handleChangeFQ = (e) => {
    console.log(e.target.value);
    setFiscalQuarter(e.target.value);
  };

  useEffect(() => {
    let filters = [{ name: "fiscal_quarter", value: fiscalQuarter }];
    // console.log(props.activeFilters, 'Growth Model Filters')
    if (
      ![null, undefined, ""].includes(props.activeFilters) &&
      props.activeFilters[0] !== "All"
    ) {
      let f = props.activeFilters.filter((f) => f.name != "fiscal_quarter");
      filters = [...filters, ...f];
    }
    props.getGrowthModeldData(filters);
  }, [fiscalQuarter]);

  // console.log(valueFromChildC, valueFromChildB, valueFromChildA, 'FQQ')
  return (
    <>
      <Card
        style={{ margin: "auto", width: "100%", marginTop: "2rem" }}
        raised={false}
      >
        {props.ACVCreatedData !== "" ? (
          <Grid
            item
            xs={12}
            // className={classes.grid}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item style={{ padding: "0.5rem 0.5rem" }}>
              <Typography
                align="center"
                variant="h2"
                style={{
                  margin: "1rem 0rem",
                  display: "flex",
                  justifyContent: "center",
                  padding: "1rem",
                }}
              >
                Pipeline needed by source to met growth targets
              </Typography>
              <Grid container item alignItems="flex-start">
                <Typography
                  align="left"
                  variant="h2"
                  style={{
                    margin: "0rem 0rem",
                    display: "flex",
                    justifyContent: "center",
                    padding: "0rem",
                  }}
                >
                  <span style={{ padding: "0.2rem 0.5rem" }}>
                    {`Growth target - Won ${props.Value_Label} per Quarter`}
                  </span>{" "}
                </Typography>

                <HtmlTooltip
                  title={
                    <div
                      style={{
                        borderTop: "solid #fee599ff",
                        borderRadius: "0.5rem",
                        padding: "0px",
                        margin: 0,
                        borderWidth: "0.25rem",
                      }}
                    >
                      <div className="container">
                        <Typography
                          style={{ padding: "0.1rem" }}
                          className="heading-style"
                          align="left"
                        >{`Double-click on the cell you'd like to edit`}</Typography>
                      </div>
                    </div>
                  }
                >
                  <Typography
                    style={{
                      background: "#fee599ff",
                      padding: "0.2rem 0.4rem",
                      margin: "0rem 0.5rem",
                      border: "1px solid lightgrey",
                      color: "black",
                      fontWeight: 600,
                    }}
                  >
                    Edit
                  </Typography>
                </HtmlTooltip>
                {toggle ? (
                  <Typography
                    variant="h2"
                    align="right"
                    style={{
                      padding: "0.2rem 0.5rem",
                      border: "2px solid black",
                      width: "10rem",
                    }}
                    onDoubleClick={toggleInput}
                  >
                    {![null, undefined, ""].includes()
                      ? `${Currency_Symbol}${countOrACVFormatter(
                          DEFAULT_VALUE
                        )}`
                      : `${Currency_Symbol}${countOrACVFormatter(editTarget)}`}
                  </Typography>
                ) : (
                  <input
                    style={{
                      fontSize: "1.2rem",
                      margin: "0.2rem 0.5rem",
                      width: "6.5rem",
                      border: "2px solid black",
                    }}
                    type="number"
                    autoFocus
                    value={target}
                    onChange={handleChange}
                    onBlur={() => onSave(target)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        onSave(target);
                      }
                    }}
                  />
                )}
                <Typography style={{ padding: "0.5rem" }}>
                  Target Fiscal Quarter:
                </Typography>
                <Grid
                  container
                  item
                  style={{ paddingLeft: "0.5rem" }}
                  xs={2}
                  md={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        //  className={classes.formControl}
                      >
                        {/* <InputLabel
                     id="select-fiscalQuarter-label"
                     style={{
                       color:  "#4472c4"
                     }}
                   >
                     Target Fiscal Quarter:
                   </InputLabel> */}
                        <Select
                          data-test="closed-fiscal-quarter"
                          labelId="select-fiscalQuarter-label"
                          id="select-fiscalQuarter"
                          value={fiscalQuarter}
                          onChange={handleChangeFQ}
                          label="Fiscal Quarter"
                          name="fiscal_quarter"
                        >
                          {props.futureQuarters &&
                            props.futureQuarters.length > 0 &&
                            props.futureQuarters
                              .filter((item) => item.fiscal_quarter !== "")
                              .map((y) => (
                                <MenuItem
                                  key={y.fiscal_quarter}
                                  value={y.fiscal_quarter}
                                >
                                  {y.fiscal_quarter}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <GrowthModelTable
                data={props.data}
                dataA={props.dataA}
                dataB={props.dataB}
                dataC={props.dataC}
                targetValue={editTarget}
                onDataA={handleChildDataA}
                onDataC={handleChildDataC}
                onDataB={handleChildDataB}
                quarter={fiscalQuarter}
                table1={{
                  needed: valueFromChildA,
                  actual:
                    props.ACVCreatedData.tableOne.length > 0
                      ? props.ACVCreatedData.tableOne
                          .map((f) => f.acv)
                          .reduce((a, b) => a + b, 0)
                      : null,
                  delta: Math.max(
                    valueFromChildA -
                      props.ACVCreatedData.tableOne
                        .map((f) => f.acv)
                        .reduce((a, b) => a + b, 0),
                    0
                  ),
                }}
                table2={{
                  needed: valueFromChildB,
                  actual:
                    props.ACVCreatedData.tableTwo.length > 0
                      ? props.ACVCreatedData.tableTwo
                          .map((f) => f.acv)
                          .reduce((a, b) => a + b, 0) /
                        props.ACVCreatedData.tableTwo.length
                      : 0,
                  delta: Math.max(
                    valueFromChildB -
                      (props.ACVCreatedData.tableTwo.length > 0
                        ? props.ACVCreatedData.tableTwo
                            .map((f) => f.acv)
                            .reduce((a, b) => a + b, 0) /
                          props.ACVCreatedData.tableTwo.length
                        : 0),
                    0
                  ),
                }}
                table3={{
                  needed: valueFromChildC,
                  actual:
                    props.ACVCreatedData.tableThree.length > 0
                      ? props.ACVCreatedData.tableThree
                          .map((f) => f.acv)
                          .reduce((a, b) => a + b, 0)
                      : null,
                  delta: Math.max(
                    valueFromChildC -
                      props.ACVCreatedData.tableThree
                        .map((f) => f.acv)
                        .reduce((a, b) => a + b, 0),
                    0
                  ),
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item container justify="center">
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Card>

      <Card
        style={{
          margin: "auto",
          width: "100%",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
        raised={false}
      >
        {props.ACVCreatedData !== "" ? (
          <>
            <Grid
              item
              xs={12}
              // className={classes.grid}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item style={{ padding: "0.5rem 0.5rem" }}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    margin: "1rem 0rem",
                    display: "flex",
                    justifyContent: "center",
                    // padding: "1rem",
                  }}
                >
                  Qualified pipeline with projected close in {fiscalQuarter}
                </Typography>
                <QualipiplineTable
                  data={{
                    acv:
                      props.ACVCreatedData.tableOne.length > 0
                        ? props.ACVCreatedData.tableOne
                            .map((f) => f.acv)
                            .reduce((a, b) => a + b, 0)
                        : null,
                    needed: valueFromChildA,
                    delta: Math.max(
                      valueFromChildA -
                        props.ACVCreatedData.tableOne
                          .map((f) => f.acv)
                          .reduce((a, b) => a + b, 0),
                      0
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              // className={classes.grid}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item style={{ padding: "0.5rem 0.5rem" }}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    margin: "1rem 0rem",
                    display: "flex",
                    justifyContent: "center",
                    // padding: "1rem",
                  }}
                >
                  Qualified pipeline creation history
                </Typography>
                <QualipiplineTablev2
                  data={props.ACVCreatedData.tableTwo}
                  value={valueFromChildB}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item style={{ padding: "0.5rem 0.5rem" }}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    margin: "1rem 0rem",
                    display: "flex",
                    justifyContent: "center",
                    // padding: "1rem",
                  }}
                >
                  Qualified pipeline for qtrs. later than {fiscalQuarter}
                </Typography>
                <QualipiplineTable
                  data={{
                    acv:
                      props.ACVCreatedData.tableThree.length > 0
                        ? props.ACVCreatedData.tableThree
                            .map((f) => f.acv)
                            .reduce((a, b) => a + b, 0)
                        : null,
                    needed: valueFromChildC,
                    delta: Math.max(
                      valueFromChildC -
                        props.ACVCreatedData.tableThree
                          .map((f) => f.acv)
                          .reduce((a, b) => a + b, 0),
                      0
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              // className={classes.grid}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item style={{ padding: "0.5rem 0.5rem" }}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    margin: "1rem 0rem",
                    display: "flex",
                    justifyContent: "center",
                    // padding: "1rem",
                  }}
                >
                  Avg. cycle time and median cycle time for won opps. To go from
                  Qualified to Won for the last 4 completed qtrs.
                </Typography>
                <MeanMedianTable
                  data={props.ACVCreatedData.qualifiedToClosedWon}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item container justify="center">
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const { ACVCreatedData, user } = state.app;

  return {
    ACVCreatedData,
    user,
    Value_Label: user.Value_Label,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getGrowthModeldData: (filters) => {
    dispatch({ type: `get_scorecard_ACVCreated_data_request` });
    appService.getGrowthModelData(filters).then(
      (json) => {
        dispatch({ type: `get_scorecard_ACVCreated_data_success`, json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_scorecard_ACVCreated_data_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_scorecard_ACVCreated_data_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
});
const GrowthModel = connect(mapStateToProps, mapDispatchToProps)(GM);

export default GrowthModel;
