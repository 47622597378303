import * as Yup from "yup";

export const passwordValidation = Yup.string()
  .max(255)
  .required("Password is required.")
  .min(8, "Password is too short - should be a minimum of 8 characters.")
  .matches(/.*[0-9].*/, "Password must contain at least 1 number.")
  .matches(
    /[!@#$%^&*(),.?":{}|<>_\-]/,
    "Password must contain at least 1 special character."
  )
  .matches(/[A-Z]+/, "Password must contain at least 1 upper case character.")
  .matches(/[a-z]+/, "Password must contain at least 1 lower case character.");
export const passwordValidationLabel = [
  "Must be atleast 8 characters long",
  "Must contain an uppercase and a lowercase letter (A, z)",
  "Must contain a number",
  "Must contain a special character (e.g., !, @, #, $, %, ^, &, *, -, _, ~)",
];
