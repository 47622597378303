import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  Close as CloseIcon,
  Visibility as ViewIcon,
  VisibilityOff as OffIcon,
} from "@material-ui/icons";
import {
  passwordValidation,
  passwordValidationLabel,
} from "../Settings/AccountSettings/password-validaton";
import InfoIcon from "@material-ui/icons/Info";
const ResetPasswordFormikComponent = (props) => {
  const { type, classes } = props;

  return (
    <Formik
      initialValues={{
        oldpassword: "",
        code: "",
        password: "",
        confirm: "",
        visible1: false,
        visible2: false,
        visible3: false,
      }}
      validationSchema={Yup.object().shape({
        oldpassword:
          type === "changepassword"
            ? Yup.string().max(255).required("Current Password is required.")
            : Yup.string(),
        code:
          type === "resetpassword"
            ? Yup.number()
                .integer()
                .typeError("Code is a 6 digit integer.")
                .required("Code is required.")
            : Yup.number(),
        password: passwordValidation,
        confirm: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords must match."
        ),
      })}
      onSubmit={(values, { resetForm }) => {
        if (type === "changepassword") {
          props.changePass(values.oldpassword, values.password);
        } else if (type === "resetpassword") {
          props.changePass(
            values.code,
            values.password,
            props.forgotPassToken,
            props.forgotPassEmail
          );
          props.setState({
            ...this.state,
            open: false,
          });
        } else {
          props.changePass(values.password);
        }
        resetForm({});
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} className={classes.formStyle}>
          <Typography style={{ marginBottom: 10 }} align="center" variant="h2">
            {props.title}
          </Typography>
          {props.type === "changepasswordfirstime" && (
            <Typography variant="body1">
              Welcome to the SkyGeni platform. Please change your password to
              proceed.
            </Typography>
          )}

          {props.errorMessage !== "" && (
            <Box mt={5}>
              <Collapse in={props.open} data-test="reset-pass">
                <Alert
                  severity="error"
                  color={props.alert.type === "success" ? "success" : "error"}
                  variant="filled"
                  action={
                    props.alert.type === "success" ? (
                      <></>
                    ) : (
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          props.setState({
                            open: false,
                            errorMessage: "",
                          });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    )
                  }
                >
                  <Typography data-test="reset-pass-error-text" variant="body1">
                    {props.errorMessage}
                  </Typography>
                </Alert>
              </Collapse>
            </Box>
          )}

          {type === "changepassword" && (
            <TextField
              error={Boolean(touched.oldpassword && errors.oldpassword)}
              fullWidth
              helperText={touched.oldpassword && errors.oldpassword}
              label="Current Password"
              margin="normal"
              name="oldpassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.oldpassword}
              variant="outlined"
              type={values.visible1 ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setFieldValue("visible1", !values.visible1, false)
                      }
                    >
                      {values.visible1 ? <OffIcon /> : <ViewIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {props.changePasswordSuccess !== true && (
            <>
              {type === "resetpassword" && (
                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  helperText={touched.code && errors.code}
                  label="Code"
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                />
              )}
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="New Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                variant="outlined"
                type={values.visible2 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setFieldValue("visible2", !values.visible2, false)
                        }
                      >
                        {values.visible2 ? <OffIcon /> : <ViewIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid
                container
                direction="column"
                spacing={1}
                style={{ marginTop: 5 }}
              >
                {passwordValidationLabel.map((item, index) => (
                  <Grid item key={index}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <InfoIcon style={{ fontSize: 16 }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">{item}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <TextField
                error={Boolean(touched.confirm && errors.confirm)}
                fullWidth
                helperText={touched.confirm && errors.confirm}
                label="Confirm New Password"
                margin="normal"
                name="confirm"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirm}
                variant="outlined"
                type={values.visible3 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setFieldValue("visible3", !values.visible3, false)
                        }
                      >
                        {values.visible3 ? <OffIcon /> : <ViewIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          <Box mt={2}>
            <Grid item container xs={12} justifyContent="flex-start">
              {props.changePasswordSuccess !== true && (
                <Button
                  color="primary"
                  disabled={(() => {
                    if (type === "changepassword") {
                      return (
                        !values.oldpassword ||
                        !values.password ||
                        !values.confirm ||
                        errors.oldpassword ||
                        errors.password ||
                        errors.confirm
                      );
                    }
                    if (type === "resetpassword") {
                      return (
                        !values.code ||
                        !values.password ||
                        !values.confirm ||
                        errors.code ||
                        errors.password ||
                        errors.confirm
                      );
                    }
                    if (type === "changepasswordfirstime") {
                      return (
                        !values.password ||
                        !values.confirm ||
                        errors.password ||
                        errors.confirm
                      );
                    }
                    return true; // Fallback to disable button if type is unknown
                  })()}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Box>

          <Box mt={2} className={classes.backButtonContainer}>
            {type === "resetpassword" && (
              <Grid item container xs={12} justifyContent="flex-start">
                <Button
                  size="small"
                  variant="small"
                  onClick={() => props.handleChangeLocation("login")}
                  className={classes.backButton}
                >
                  Login
                </Button>
              </Grid>
            )}

            {type === "resetpassword" && (
              <Grid
                style={{ marginTop: 5 }}
                item
                container
                xs={12}
                justifyContent="flex-start"
              >
                {props.changePasswordSuccess !== true && (
                  <Button
                    size="small"
                    variant="small"
                    onClick={() => props.forgotPass(props.forgotPassEmail)}
                    disabled={props.persistentAlert?.message?.includes(
                      "Sending"
                    )}
                    className={classes.backButton}
                  >
                    Send Code Again
                  </Button>
                )}
              </Grid>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordFormikComponent;
