import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import {
  customDateFormatter,
  StyledTableRow,
  getUniqueValues,
  countOrACVFormatter,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import { store } from "../../util/store";
const useStyle = makeStyles((theme) => ({
  headerCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  dataCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function ACVCreatedTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const Symbol = store.getState();
  const Currency_Symbol = Symbol.app.user.Currency;

  console.log(props.ACVCreatedData.last31days[0], 125);
  const timeFrames = [
    {
      header: "Last 31 Days",
      dateRange: `(${customDateFormatter(
        props.ACVCreatedData.last31days[0].last31days
      )} to ${customDateFormatter(props.ACVCreatedData.currentDate[0].today)})`,
    },
    {
      header: "Last 60 Days",
      dateRange: `(${customDateFormatter(
        props.ACVCreatedData.last60days[0].last60days
      )} to ${customDateFormatter(props.ACVCreatedData.currentDate[0].today)})`,
    },
    {
      header: "Last 90 Days",
      dateRange: `(${customDateFormatter(
        props.ACVCreatedData.last90days[0].last90days
      )} to ${customDateFormatter(props.ACVCreatedData.currentDate[0].today)})`,
    },
  ];

  const timeDurations = [31, 60, 90].map((item) => `${item}`);
  // const typesOfCustomers= ["New Customer","Existing Customer"]
  const uniqueValues = props.ACVCreatedData["ACVCreatedLast31Days"]
    .map(
      (s) =>
        s[
          props.selectedTeam.includes("All") &&
          props.selectedRep.includes("All")
            ? "Team"
            : "Owner_Name"
        ]
    )
    .filter(getUniqueValues)
    .sort();
  const compare = (a, b) => (a >= b ? "#E2EFDA" : null);
  return (
    <div>
      <TableContainer style={{ padding: "1rem", paddingBottom: "3rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ minWidth: 100, height: 50, border: "none" }}
                colSpan={1}
              ></TableCell>
              {timeFrames.map((timeFrame, i) => (
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={3}
                  style={
                    i % 2 === 0
                      ? { background: "#FFE699", color: "Black", width: "100%" }
                      : { background: "#FFF4D1", color: "Black", width: "100%" }
                  }
                >
                  <p>{timeFrame.header}</p>
                  <p>{timeFrame.dateRange}</p>
                </TableCell>
              ))}
            </TableRow>
            <StyledTableRow s>
              <TableCell
                align="center"
                style={{ background: "#FFE699", minWidth: "250" }}
                className={classes.headerCell}
                colSpan={1}
                rowSpan={1}
              >
                {/* ACV Created */}
                {`${props.Value_Label} Created`}
              </TableCell>
              {timeFrames.map((timeFrames, i) => (
                <>
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                    colSpan={1}
                    style={{
                      background: "#4472C4",
                      color: "white",
                      width: "9.25%",
                      height: 90,
                    }}
                    rowSpan={1}
                  >
                    {/* New ACV Created */}
                    {`New ${props.Value_Label} Created`}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                    colSpan={1}
                    style={{
                      background: "#4472C4",
                      color: "white",
                      width: "9.25%",
                      height: 90,
                    }}
                    rowSpan={1}
                  >
                    {/* Created ACV moved to Qual. */}
                    {`Created ${props.Value_Label} moved to Qual.`}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                    colSpan={1}
                    style={{
                      background: "#4472C4",
                      color: "white",
                      width: "9.25%",
                      height: 90,
                    }}
                    rowSpan={1}
                  >
                    {/* Created to Qual. Conv. (ACV) */}
                    {`Created to Qual. Conv. (${props.Value_Label})`}
                  </TableCell>
                </>
              ))}
            </StyledTableRow>

            {!props.isrepScoreCard && (
              <>
                <StyledTableRow key={"Total"}>
                  <TableCell className={classes.headerCell} align="left">
                    {props.selectedTeam.includes("All") &&
                    props.selectedRep.includes("All")
                      ? "Company"
                      : !props.selectedTeam.includes("All")
                      ? props.selectedTeam.length > 1
                        ? "Teams"
                        : "Team"
                      : "Total"}
                  </TableCell>
                  {timeDurations.map((timeDuration) => (
                    <>
                      {/* Total Cell */}
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            timeDuration,
                            undefined,
                            "ACVCreated",
                            `${customDateFormatter(
                              props.ACVCreatedData[
                                `last${timeDuration}days`
                              ][0][`last${timeDuration}days`]
                            )} to ${customDateFormatter(
                              props.ACVCreatedData.currentDate[0].today
                            )}`
                          )
                        }
                        className={`${classes.dataCell} ${classes.drilldownCell}`}
                        align="right"
                      >
                        {/* $ */}
                        {countOrACVFormatter(
                          props.ACVCreatedData[
                            `segmentLast${timeDuration}Days`
                          ][0].acvCreated,
                          true
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            timeDuration,
                            undefined,
                            "ACVQualified",
                            `${customDateFormatter(
                              props.ACVCreatedData[
                                `last${timeDuration}days`
                              ][0][`last${timeDuration}days`]
                            )} to ${customDateFormatter(
                              props.ACVCreatedData.currentDate[0].today
                            )}`
                          )
                        }
                        className={`${classes.dataCell} ${classes.drilldownCell}`}
                        align="right"
                      >
                        {/* $ */}
                        {countOrACVFormatter(
                          props.ACVCreatedData[
                            `segmentLast${timeDuration}Days`
                          ][0].acvmovedToQualified,
                          true
                        )}
                      </TableCell>
                      <TableCell
                        className={`${classes.dataCell}`}
                        align="right"
                      >
                        {Math.round(
                          props.ACVCreatedData[
                            `segmentLast${timeDuration}Days`
                          ][0].createdToQualifiedConv * 100
                        )}
                        %
                      </TableCell>
                    </>
                  ))}
                </StyledTableRow>
              </>
            )}
            {uniqueValues.map((rep) => (
              <StyledTableRow key={rep}>
                <TableCell
                  className={classes.headerCell}
                  align="left"
                  style={{ paddingLeft: "1.75rem" }}
                >
                  {rep}
                </TableCell>
                {timeDurations.map((timeDuration) => {
                  const repData = props.ACVCreatedData[
                    `ACVCreatedLast${timeDuration}Days`
                  ].filter(
                    (item) =>
                      item[
                        props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                          ? "Team"
                          : "Owner_Name"
                      ] === rep
                  );
                  const acvCreated = repData[0].acvCreated;
                  const acvQualified = repData[0].acvmovedToQualified;
                  const perc = repData[0].createdToQualifiedConv;
                  return (
                    <>
                      {/* Total Cell */}
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            timeDuration,
                            [rep],
                            "ACVCreated",
                            `${customDateFormatter(
                              props.ACVCreatedData[
                                `last${timeDuration}days`
                              ][0][`last${timeDuration}days`]
                            )} to ${customDateFormatter(
                              props.ACVCreatedData.currentDate[0].today
                            )}`
                          )
                        }
                        className={`${classes.dataCell} ${classes.drilldownCell}`}
                        align="right"
                      >
                        {/* $ */}
                        {countOrACVFormatter(acvCreated, true)}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            timeDuration,
                            [rep],
                            "ACVQualified",
                            `${customDateFormatter(
                              props.ACVCreatedData[
                                `last${timeDuration}days`
                              ][0][`last${timeDuration}days`]
                            )} to ${customDateFormatter(
                              props.ACVCreatedData.currentDate[0].today
                            )}`
                          )
                        }
                        className={`${classes.dataCell} ${classes.drilldownCell}`}
                        align="right"
                      >
                        {/* $ */}
                        {countOrACVFormatter(acvQualified, true)}
                      </TableCell>
                      <TableCell
                        className={`${classes.dataCell}`}
                        align="right"
                      >
                        {Math.round(perc * 100)}%
                      </TableCell>
                    </>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
