import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  withStyles,
  Grid,
} from "@material-ui/core";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {
  countOrACVFormatter,
  StyledTableRow,
  _isNaN,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import { store } from "../../util/store";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

const styles = makeStyles({
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px 5px",
    color: "inherit",
  },
  nonScoreCell: {
    fontWeight: 400,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  repHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    fontWeight: 600,
  },
  repHeaderDrilldown: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    cursor: "pointer",
  },
  repNameMargin: {
    paddingLeft: "1.75rem",
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    padding: "0.25rem",
  },
  "& .MuiTableCell-sizeSmall": {
    padding: "6px 24px 6px 10px;",
  },
});

const ast = (x, y) => {
  return x === y ? (
    <span>{countOrACVFormatter(x, true)}</span>
  ) : (
    <>
      <span>{countOrACVFormatter(x, true)}</span>{" "}
      <span style={{ color: "#FF0000" }}>*</span>
    </>
  );
};

export default function PipelineCoverageSectionTable(props) {
  console.log(props);
  const Symbol = store.getState();
  const Currency_Symbol = Symbol.app.user.Currency;

  const classes = styles();
  const tableRef = React.createRef();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(255,255,255,0.8)",
      color: "black",
      fontFamily: "roboto",
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "1rem",
      padding: "0px",
      boxShadow: "0 0.1rem 0.1rem 0",
      marginTop: "0.4rem",
    },
  }))(Tooltip);

  const teams = props.data
    .filter((s) => !["company", "team"].includes(s.name))
    .map((s) => s.name)
    .filter(getUniqueValues)
    .sort();
  // const bookingTypes=props.AttainmentTable.map(s=>s.type).filter(getUniqueValues).filter(a=>a !== 'total')
  // const splitup = props.db === 'C0003' ? ["New Logo", "Upsell", "Price Increase",	"Cross Sell"] : ['New Customer', 'Renewal', 'Existing Customer']
  const splitup = props.splitup;
  //qualifiedTooltip
  const qualifiedTooltip = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <div
              style={{
                borderTop: "solid #4471C4",
                borderRadius: "0.3rem",
                padding: "0px",
                margin: 0,
                borderWidth: "0.3rem",
              }}
            >
              <div className="container">
                <Typography
                  style={{ padding: "0.1rem" }}
                  className="heading-style"
                >
                  `Qualified Pipeline $
                  {props.type === "CFQ" ? "current" : "next"} quarter``
                </Typography>
              </div>
            </div>
          </>
        ))}
    </>
  );
  //forecast tooltip
  const forecastTooltip = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <div
              style={{
                borderTop: "solid #4471C4",
                borderRadius: "0.3rem",
                padding: "0px",
                margin: 0,
                borderWidth: "0.3rem",
              }}
            >
              <div className="container">
                <Typography
                  style={{ padding: "0.1rem" }}
                  className="heading-style"
                >
                  Opportunities with Sales Executive Deal Forecast = Forecast to
                  Go for current qtr.
                </Typography>
              </div>
            </div>
          </>
        ))}
    </>
  );
  //projected yield toottip
  const projectedYieldTooltip = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <div
              style={{
                borderTop: "solid #4471C4",
                borderRadius: "0.3rem",
                padding: "0px",
                margin: 0,
                borderWidth: "0.3rem",
              }}
            >
              <div className="container">
                <Typography
                  style={{ padding: "0.1rem" }}
                  className="heading-style"
                >{`SkyGeni Projected Yield from opps. in ${props.qualifiedHeader}`}</Typography>
              </div>
            </div>
          </>
        ))}
    </>
  );

  //projected attainment tooltip
  const projectedAttainmentTooltip = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <div
              style={{
                borderTop: "solid #4471C4",
                borderRadius: "0.3rem",
                padding: "0px",
                margin: 0,
                borderWidth: "0.3rem",
              }}
            >
              <div className="container">
                <Typography
                  style={{ padding: "0.1rem" }}
                  className="heading-style"
                >{`Won + SkyGeni Projected Yield from opps. in ${props.qualifiedHeader}`}</Typography>
              </div>
            </div>
          </>
        ))}
    </>
  );

  const countAndOpps = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              style={{
                background: "#4471C4",
                color: "white",
                border: "0.5px solid lightgrey",
                minWidth: 50,
                maxWidth: 50,
              }}
            >
              # of Opps
            </TableCell>
            {props.showSeats === "Y" && (
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 50,
                  maxWidth: 50,
                }}
              >
                Seats
              </TableCell>
            )}

            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              style={{
                background: "#4471C4",
                color: "white",
                border: "0.5px solid lightgrey",
                minWidth: 50,
                maxWidth: 50,
              }}
            >
              {/* ACV */}
              {`${props.Value_Label}`}
            </TableCell>
          </>
        ))}
    </>
  );

  const countAndOppsCustom = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <HtmlTooltip title={qualifiedTooltip}>
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 50,
                  maxWidth: 50,
                }}
              >
                # of Opps
              </TableCell>
            </HtmlTooltip>
            {props.showSeats === "Y" && (
              <HtmlTooltip title={qualifiedTooltip}>
                <TableCell
                  align="center"
                  className={classes.repHeader}
                  colSpan={1}
                  style={{
                    background: "#4471C4",
                    color: "white",
                    border: "0.5px solid lightgrey",
                    minWidth: 50,
                    maxWidth: 50,
                  }}
                >
                  Seats
                </TableCell>
              </HtmlTooltip>
            )}
            <HtmlTooltip title={qualifiedTooltip}>
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 50,
                  maxWidth: 50,
                }}
              >
                {/* ACV */}
                {`${props.Value_Label}`}
              </TableCell>
            </HtmlTooltip>
          </>
        ))}
    </>
  );

  let cellNumber = props.type === "CFQ" ? 2 : 1;
  cellNumber = props.ShowLMH === false ? 0 : cellNumber;

  const countAndOppsAndPercentage = (
    <>
      {Array(cellNumber)
        .fill()
        .map((item, i) => (
          <>
            <HtmlTooltip
              title={
                i === 0 ? projectedYieldTooltip : projectedAttainmentTooltip
              }
            >
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 70,
                  maxWidth: 70,
                }}
              >
                Low
              </TableCell>
            </HtmlTooltip>
            <HtmlTooltip
              title={
                i === 0 ? projectedYieldTooltip : projectedAttainmentTooltip
              }
            >
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 70,
                  maxWidth: 70,
                }}
              >
                Mid
              </TableCell>
            </HtmlTooltip>
            <HtmlTooltip
              title={
                i === 0 ? projectedYieldTooltip : projectedAttainmentTooltip
              }
            >
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 70,
                  maxWidth: 70,
                }}
              >
                High
              </TableCell>
            </HtmlTooltip>
          </>
        ))}
    </>
  );
  return (
    <>
      {props.AttainmentTable !== "" && (
        <>
          <TableContainer
            style={{
              padding: "1rem",
              width:
                props.type === "CFQ"
                  ? props.ShowLMH === false
                    ? "65%"
                    : "100%"
                  : "65%",
            }}
            align="center"
            id={`myTabledata${props.quarterData}`}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto" }}
              data-test={
                props.isrepScoreCard
                  ? `rep-${props.tableName}`
                  : props.tableName
              }
              id={
                props.isrepScoreCard ? `rep-${props.tableName}` : props.tableID
              }
            >
              <TableHead></TableHead>
              <TableBody>
                <StyledTableRow>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    className={classes.tableHeader}
                    colSpan={1}
                    style={{
                      background: "#4471C4",
                      height: 70,
                      minWidth: 160,
                      maxWidth: 160,
                      border: "0.5px solid lightgrey",
                      color: "white",
                    }}
                  >
                    {props.isrepScoreCard ? "Rep" : "Team / Rep"}
                  </TableCell>
                  {props.db !== "C0003" && (
                    <>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        className={classes.tableHeader}
                        colSpan={1}
                        style={{
                          background: "#BF8F00",
                          color: "white",
                          border: "0.5px solid lightgrey",
                          minWidth: 60,
                          maxWidth: 60,
                        }}
                      >
                        {"Coverage Ratio Goal"}
                      </TableCell>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        className={classes.tableHeader}
                        colSpan={1}
                        style={{
                          background: "#4471C4",
                          color: "white",
                          border: "0.5px solid lightgrey",
                          minWidth: 60,
                          maxWidth: 60,
                        }}
                      >
                        {"Coverage Ratio Actual"}
                      </TableCell>
                    </>
                  )}
                  <TableCell
                    align="center"
                    rowSpan={2}
                    className={classes.tableHeader}
                    colSpan={1}
                    style={{
                      background: "#BF8F00",
                      color: "white",
                      border: "0.5px solid lightgrey",
                      minWidth: 60,
                      maxWidth: 60,
                    }}
                  >
                    {props.db === "C0003" ? "Goal" : "Quota"}
                  </TableCell>
                  {props.type === "CFQ" && (
                    <>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        className={classes.tableHeader}
                        colSpan={1}
                        style={{
                          background: "#70AD47",
                          color: "white",
                          border: "0.5px solid lightgrey",
                          minWidth: 60,
                          maxWidth: 60,
                        }}
                      >
                        {"Won"}
                      </TableCell>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        className={classes.tableHeader}
                        colSpan={1}
                        style={{
                          background: "#BF8F00",
                          color: "white",
                          border: "0.5px solid lightgrey",
                          minWidth: 60,
                          maxWidth: 60,
                        }}
                      >
                        {"Remaining Curr. Qtr. Quota"}
                      </TableCell>
                    </>
                  )}
                  {props.db === "C0003" ? (
                    <HtmlTooltip title={qualifiedTooltip}>
                      <TableCell
                        align="center"
                        colSpan={2}
                        className={classes.tableHeader}
                        style={{
                          background: "#4471C4",
                          color: "white",
                          border: "0.5px solid lightgrey",
                          minWidth: 100,
                          maxWidth: 100,
                        }}
                      >
                        {props.qualifiedHeader}
                      </TableCell>
                    </HtmlTooltip>
                  ) : (
                    <TableCell
                      align="center"
                      colSpan={props.showSeats === "Y" ? 3 : 2}
                      className={classes.tableHeader}
                      style={{
                        background: "#4471C4",
                        color: "white",
                        border: "0.5px solid lightgrey",
                        minWidth: 100,
                        maxWidth: 100,
                      }}
                    >
                      {props.qualifiedHeader}
                    </TableCell>
                  )}

                  {props.type === "CFQ" && (
                    <>
                      {/* <TableCell align='center' rowSpan={2}  className={classes.tableHeader} colSpan={1} style={{ background: '#4471C4', color: 'white',border: '0.5px solid lightgrey',minWidth:60}}>{"Expected Won + Forecast"}</TableCell>
                        <TableCell align='center' rowSpan={2} className={classes.tableHeader} colSpan={1} style={{ background: '#BF8F00', color: 'white',border: '0.5px solid lightgrey',minWidth:60}}>{"Expected Attainment %"}</TableCell> */}
                    </>
                  )}
                  {props.ShowLMH !== false && (
                    <>
                      <HtmlTooltip title={projectedYieldTooltip}>
                        <TableCell
                          align="center"
                          className={classes.tableHeader}
                          colSpan={3}
                          style={{
                            background: "#4471C4",
                            color: "white",
                            border: "0.5px solid lightgrey",
                            minWidth: 230,
                            maxWidth: 230,
                          }}
                        >
                          SkyGeni Projected Yield{" "}
                          <span
                            style={{
                              background: "#fee599ff",
                              padding: "0 0.4rem",
                              paddingLeft: "0.7rem",
                              margin: "0 0.5rem",
                              border: "1px solid lightgrey",
                              color: "black",
                            }}
                          >
                            Beta
                          </span>
                        </TableCell>
                      </HtmlTooltip>
                      {props.type === "CFQ" && (
                        <HtmlTooltip title={projectedAttainmentTooltip}>
                          <TableCell
                            align="center"
                            className={classes.tableHeader}
                            colSpan={3}
                            style={{
                              background: "#4471C4",
                              color: "white",
                              border: "0.5px solid lightgrey",
                              minWidth: 230,
                              maxWidth: 230,
                            }}
                          >
                            SkyGeni Projected Attainment{" "}
                            <span
                              style={{
                                background: "#fee599ff",
                                padding: "0 0.4rem",
                                paddingLeft: "0.7rem",
                                margin: "0 0.5rem",
                                border: "1px solid lightgrey",
                                color: "black",
                              }}
                            >
                              Beta
                            </span>
                          </TableCell>
                        </HtmlTooltip>
                      )}
                    </>
                  )}
                </StyledTableRow>
                <TableRow>
                  {props.db === "C0003" ? countAndOppsCustom : countAndOpps}
                  {countAndOppsAndPercentage}
                </TableRow>
                {!props.isrepScoreCard && (
                  <>
                    <StyledTableRow>
                      <TableCell
                        align="left"
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          fontWeight: 600,
                        }}
                        colSpan={1}
                      >
                        {props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                          ? "Company"
                          : !props.selectedTeam.includes("All")
                          ? props.selectedTeam.length > 1
                            ? "Teams"
                            : "Team"
                          : "Total"}
                      </TableCell>
                      {props.db !== "C0003" && (
                        <>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].coverageGoal
                            }
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            style={
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].actual >
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].coverageGoal
                                ? {
                                    border: "0.5px solid lightgrey",
                                    paddingRight: "0.5rem",
                                    backgroundColor: "#E2EFDA",
                                  }
                                : {
                                    border: "0.5px solid lightgrey",
                                    paddingRight: "0.5rem",
                                  }
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].actual
                            )
                              ? props.data
                                  .filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0]
                                  .actual.toFixed(2)
                              : "N/A"}
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          paddingRight: "0.5rem",
                        }}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          props.data.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].goal,
                          true
                        )}
                      </TableCell>
                      {props.type === "CFQ" && (
                        <>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocationWon(
                                "drilldownOnGraph",
                                teams,
                                splitup
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {countOrACVFormatter(
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].won,
                              true
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {countOrACVFormatter(
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].remainingCurrentQuarter,
                              true
                            )}
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            teams,
                            "open",
                            props.type,
                            true
                          )
                        }
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          cursor: "pointer",
                          paddingRight: "0.5rem",
                        }}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          props.data.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].count
                        )}
                      </TableCell>
                      {props.showSeats === "Y" && (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              teams,
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            props.data.filter((i) =>
                              ["company", "team"].includes(i.name)
                            )[0].seats
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            teams,
                            "open",
                            props.type,
                            true
                          )
                        }
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          cursor: "pointer",
                          paddingRight: "0.5rem",
                        }}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          props.data.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].acv,
                          true
                        )}
                      </TableCell>
                      {props.type === "CFQ" && (
                        <>
                          {/* <TableCell align='right'  className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>${countOrACVFormatter(props.data.filter(i=>['company','team'].includes(i.name))[0].expectedWonAcv)}</TableCell>
                        <TableCell align='right' className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>{Math.round(props.data.filter(i=>['company','team'].includes(i.name))[0].attainmentPerc)}%</TableCell> */}
                        </>
                      )}
                      {/* Skygeni Projected yield low-mid-high */}

                      {props.data.filter((i) =>
                        ["company", "team"].includes(i.name)
                      )[0].low <
                      props.datav2.filter((i) =>
                        ["company", "team"].includes(i.name)
                      )[0].low ? (
                        <HtmlTooltip
                          title={
                            <div
                              style={{
                                borderTop: "solid #FF0000",
                                borderRadius: "1rem",
                                padding: "0px",
                                margin: 0,
                                borderWidth: "0.6rem",
                              }}
                            >
                              <div
                                className="tooltip-box"
                                style={{ padding: "0px", fontSize: "1rem" }}
                              >
                                <h3
                                  className="heading-style"
                                  style={{ padding: "0.5rem" }}
                                >
                                  {props.selectedTeam.includes("All") &&
                                  props.selectedRep.includes("All")
                                    ? "Company"
                                    : !props.selectedTeam.includes("All")
                                    ? props.selectedTeam.length > 1
                                      ? "Teams"
                                      : "Team"
                                    : "Total"}
                                </h3>
                                <div
                                  style={{
                                    paddingLeft: "0.8rem",
                                    paddingRight: "0.8rem",
                                  }}
                                >
                                  <hr />
                                </div>
                                <div
                                  className="container"
                                  style={{
                                    padding: "0.5rem",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <h4 className="result-style">
                                    Outlier Opp. :{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].outlierCount
                                      )}
                                    </span>{" "}
                                  </h4>
                                  <h4
                                    style={{ paddingTop: "0.3rem" }}
                                    className="result-style"
                                  >
                                    {" "}
                                    Outlier ACV:{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].outlierAcv,
                                        true
                                      )}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                true
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].low,
                              props.datav2.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].low
                            )}
                          </TableCell>
                        </HtmlTooltip>
                      ) : (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              teams,
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          {ast(
                            props.data.filter((i) =>
                              ["company", "team"].includes(i.name)
                            )[0].low,
                            props.datav2.filter((i) =>
                              ["company", "team"].includes(i.name)
                            )[0].low
                          )}
                        </TableCell>
                      )}

                      {props.data.filter((i) =>
                        ["company", "team"].includes(i.name)
                      )[0].mid <
                      props.datav2.filter((i) =>
                        ["company", "team"].includes(i.name)
                      )[0].mid ? (
                        <HtmlTooltip
                          title={
                            <div
                              style={{
                                borderTop: "solid #FF0000",
                                borderRadius: "1rem",
                                padding: "0px",
                                margin: 0,
                                borderWidth: "0.6rem",
                              }}
                            >
                              <div
                                className="tooltip-box"
                                style={{ padding: "0px", fontSize: "1rem" }}
                              >
                                <h3
                                  className="heading-style"
                                  style={{ padding: "0.5rem" }}
                                >
                                  {props.selectedTeam.includes("All") &&
                                  props.selectedRep.includes("All")
                                    ? "Company"
                                    : !props.selectedTeam.includes("All")
                                    ? props.selectedTeam.length > 1
                                      ? "Teams"
                                      : "Team"
                                    : "Total"}
                                </h3>
                                <div
                                  style={{
                                    paddingLeft: "0.8rem",
                                    paddingRight: "0.8rem",
                                  }}
                                >
                                  <hr />
                                </div>
                                <div
                                  className="container"
                                  style={{
                                    padding: "0.5rem",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <h4 className="result-style">
                                    Outlier Opp. :{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].outlierCount
                                      )}
                                    </span>{" "}
                                  </h4>
                                  <h4
                                    style={{ paddingTop: "0.3rem" }}
                                    className="result-style"
                                  >
                                    {" "}
                                    Outlier ACV:{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].outlierAcv,
                                        true
                                      )}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                true
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].mid,
                              props.datav2.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].mid
                            )}
                          </TableCell>
                        </HtmlTooltip>
                      ) : (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              teams,
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          {ast(
                            props.data.filter((i) =>
                              ["company", "team"].includes(i.name)
                            )[0].mid,
                            props.datav2.filter((i) =>
                              ["company", "team"].includes(i.name)
                            )[0].mid
                          )}
                        </TableCell>
                      )}

                      {props.data.filter((i) =>
                        ["company", "team"].includes(i.name)
                      )[0].high <
                        props.data.filter((i) =>
                          ["company", "team"].includes(i.name)
                        )[0].remainingCurrentQuarter && props.type === "CFQ" ? (
                        <HtmlTooltip
                          title={
                            <div
                              style={{
                                borderTop: "solid #f4b183",
                                borderRadius: "1rem",
                                padding: "0px",
                                margin: 0,
                                borderWidth: "0.6rem",
                              }}
                            >
                              <div
                                className="tooltip-box"
                                style={{ padding: "0px", fontSize: "1rem" }}
                              >
                                <h3
                                  className="heading-style"
                                  style={{ padding: "0.5rem" }}
                                >
                                  {props.selectedTeam.includes("All") &&
                                  props.selectedRep.includes("All")
                                    ? "Company"
                                    : !props.selectedTeam.includes("All")
                                    ? props.selectedTeam.length > 1
                                      ? "Teams"
                                      : "Team"
                                    : "Total"}
                                </h3>
                                <div
                                  style={{
                                    paddingLeft: "0.8rem",
                                    paddingRight: "0.8rem",
                                  }}
                                >
                                  <hr />
                                </div>
                                <div
                                  className="container"
                                  style={{
                                    padding: "0.5rem",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <h4 className="result-style">
                                    {" "}
                                    Expected Pipeline Yield (High) :{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].high,
                                        true
                                      )}
                                    </span>{" "}
                                  </h4>
                                  <h4
                                    style={{ paddingTop: "0.3rem" }}
                                    className="result-style"
                                  >
                                    {" "}
                                    <u>less than</u> &nbsp; Remaining Quota :{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].remainingCurrentQuarter,
                                        true
                                      )}
                                    </span>
                                  </h4>
                                  {props.data.filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0].high <
                                    props.datav2.filter((i) =>
                                      ["company", "team"].includes(i.name)
                                    )[0].high && (
                                    <>
                                      <h4 className="result-style">
                                        Outlier Opp. :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierCount
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        {/* Outlier ACV:{" "} */}
                                        {`Outlier ${props.Value_Label}:{""}`}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierAcv,
                                            true
                                          )}
                                        </span>
                                      </h4>
                                    </>
                                  )}
                                </div>
                                <div
                                  className="container"
                                  style={{
                                    margin: "0px",
                                    paddingBottom: "0.3rem",
                                    paddingLeft: "0.8rem",
                                    paddingRight: "0.8rem",
                                    fontSize: "1rem",
                                  }}
                                >
                                  <h4
                                    style={{
                                      backgroundColor: "#f4b183",
                                      padding: "0.1rem",
                                    }}
                                    className="heading-style"
                                  >
                                    At risk of missing quota
                                  </h4>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                true
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                              background: "#F4B183",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].high,
                              props.datav2.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].high
                            )}
                          </TableCell>
                        </HtmlTooltip>
                      ) : props.data.filter((i) =>
                          ["company", "team"].includes(i.name)
                        )[0].high <
                        props.datav2.filter((i) =>
                          ["company", "team"].includes(i.name)
                        )[0].high ? (
                        <HtmlTooltip
                          title={
                            <div
                              style={{
                                borderTop: "solid #FF0000",
                                borderRadius: "1rem",
                                padding: "0px",
                                margin: 0,
                                borderWidth: "0.6rem",
                              }}
                            >
                              <div
                                className="tooltip-box"
                                style={{ padding: "0px", fontSize: "1rem" }}
                              >
                                <h3
                                  className="heading-style"
                                  style={{ padding: "0.5rem" }}
                                >
                                  {props.selectedTeam.includes("All") &&
                                  props.selectedRep.includes("All")
                                    ? "Company"
                                    : !props.selectedTeam.includes("All")
                                    ? props.selectedTeam.length > 1
                                      ? "Teams"
                                      : "Team"
                                    : "Total"}
                                </h3>
                                <div
                                  style={{
                                    paddingLeft: "0.8rem",
                                    paddingRight: "0.8rem",
                                  }}
                                >
                                  <hr />
                                </div>
                                <div
                                  className="container"
                                  style={{
                                    padding: "0.5rem",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <h4 className="result-style">
                                    Outlier Opp. :{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].outlierCount
                                      )}
                                    </span>{" "}
                                  </h4>
                                  <h4
                                    style={{ paddingTop: "0.3rem" }}
                                    className="result-style"
                                  >
                                    {" "}
                                    Outlier ACV :{" "}
                                    <span style={{ color: "rgb(31,119,180)" }}>
                                      {countOrACVFormatter(
                                        props.data.filter((i) =>
                                          ["company", "team"].includes(i.name)
                                        )[0].outlierAcv,
                                        true
                                      )}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                true
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].high,
                              props.datav2.filter((i) =>
                                ["company", "team"].includes(i.name)
                              )[0].high
                            )}
                          </TableCell>
                        </HtmlTooltip>
                      ) : (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              teams,
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          {ast(
                            props.data.filter((i) =>
                              ["company", "team"].includes(i.name)
                            )[0].high,
                            props.datav2.filter((i) =>
                              ["company", "team"].includes(i.name)
                            )[0].high
                          )}
                        </TableCell>
                      )}
                      {/* Skygeni Projected attainment low-mid-high */}
                      {props.type === "CFQ" && (
                        <>
                          {props.data.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].projectedAttainmentLow <
                          props.datav2.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].projectedAttainmentLow ? (
                            <HtmlTooltip
                              title={
                                <div
                                  style={{
                                    borderTop: "solid #FF0000",
                                    borderRadius: "1rem",
                                    padding: "0px",
                                    margin: 0,
                                    borderWidth: "0.6rem",
                                  }}
                                >
                                  <div
                                    className="tooltip-box"
                                    style={{ padding: "0px", fontSize: "1rem" }}
                                  >
                                    <h3
                                      className="heading-style"
                                      style={{ padding: "0.5rem" }}
                                    >
                                      {props.selectedTeam.includes("All") &&
                                      props.selectedRep.includes("All")
                                        ? "Company"
                                        : !props.selectedTeam.includes("All")
                                        ? props.selectedTeam.length > 1
                                          ? "Teams"
                                          : "Team"
                                        : "Total"}
                                    </h3>
                                    <div
                                      style={{
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        padding: "0.5rem",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <h4 className="result-style">
                                        Outlier Opp. :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierCount
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        Outlier ACV :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierAcv,
                                            true
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <TableCell
                                align="right"
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {ast(
                                  props.data.filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0].projectedAttainmentLow,
                                  props.datav2.filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0].projectedAttainmentLow
                                )}
                              </TableCell>
                            </HtmlTooltip>
                          ) : (
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {ast(
                                props.data.filter((i) =>
                                  ["company", "team"].includes(i.name)
                                )[0].projectedAttainmentLow,
                                props.datav2.filter((i) =>
                                  ["company", "team"].includes(i.name)
                                )[0].projectedAttainmentLow
                              )}
                            </TableCell>
                          )}

                          {props.data.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].projectedAttainmentMid <
                          props.datav2.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].projectedAttainmentMid ? (
                            <HtmlTooltip
                              title={
                                <div
                                  style={{
                                    borderTop: "solid #FF0000",
                                    borderRadius: "1rem",
                                    padding: "0px",
                                    margin: 0,
                                    borderWidth: "0.6rem",
                                  }}
                                >
                                  <div
                                    className="tooltip-box"
                                    style={{ padding: "0px", fontSize: "1rem" }}
                                  >
                                    <h3
                                      className="heading-style"
                                      style={{ padding: "0.5rem" }}
                                    >
                                      {props.selectedTeam.includes("All") &&
                                      props.selectedRep.includes("All")
                                        ? "Company"
                                        : !props.selectedTeam.includes("All")
                                        ? props.selectedTeam.length > 1
                                          ? "Teams"
                                          : "Team"
                                        : "Total"}
                                    </h3>
                                    <div
                                      style={{
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        padding: "0.5rem",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <h4 className="result-style">
                                        Outlier Opp. :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierCount
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        Outlier ACV :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierAcv,
                                            true
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <TableCell
                                align="right"
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {ast(
                                  props.data.filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0].projectedAttainmentMid,
                                  props.datav2.filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0].projectedAttainmentMid
                                )}
                              </TableCell>
                            </HtmlTooltip>
                          ) : (
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {ast(
                                props.data.filter((i) =>
                                  ["company", "team"].includes(i.name)
                                )[0].projectedAttainmentMid,
                                props.datav2.filter((i) =>
                                  ["company", "team"].includes(i.name)
                                )[0].projectedAttainmentMid
                              )}
                            </TableCell>
                          )}

                          {props.data.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].high <
                          props.datav2.filter((i) =>
                            ["company", "team"].includes(i.name)
                          )[0].high ? (
                            <HtmlTooltip
                              title={
                                <div
                                  style={{
                                    borderTop: "solid #FF0000",
                                    borderRadius: "1rem",
                                    padding: "0px",
                                    margin: 0,
                                    borderWidth: "0.6rem",
                                  }}
                                >
                                  <div
                                    className="tooltip-box"
                                    style={{ padding: "0px", fontSize: "1rem" }}
                                  >
                                    <h3
                                      className="heading-style"
                                      style={{ padding: "0.5rem" }}
                                    >
                                      {props.selectedTeam.includes("All") &&
                                      props.selectedRep.includes("All")
                                        ? "Company"
                                        : !props.selectedTeam.includes("All")
                                        ? props.selectedTeam.length > 1
                                          ? "Teams"
                                          : "Team"
                                        : "Total"}
                                    </h3>
                                    <div
                                      style={{
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        padding: "0.5rem",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <h4 className="result-style">
                                        Outlier Opp. :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierCount
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        Outlier ACV :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            props.data.filter((i) =>
                                              ["company", "team"].includes(
                                                i.name
                                              )
                                            )[0].outlierAcv,
                                            true
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <TableCell
                                align="right"
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {ast(
                                  props.data.filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0].projectedAttainmentHigh,
                                  props.datav2.filter((i) =>
                                    ["company", "team"].includes(i.name)
                                  )[0].projectedAttainmentHigh
                                )}
                              </TableCell>
                            </HtmlTooltip>
                          ) : (
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {ast(
                                props.data.filter((i) =>
                                  ["company", "team"].includes(i.name)
                                )[0].projectedAttainmentHigh,
                                props.datav2.filter((i) =>
                                  ["company", "team"].includes(i.name)
                                )[0].projectedAttainmentHigh
                              )}
                            </TableCell>
                          )}
                        </>
                      )}
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell
                        align="left"
                        className={classes.nonScoreCell}
                        style={{
                          border: "0.5px solid lightgrey",
                          fontWeight: 600,
                        }}
                        colSpan={1}
                      >
                        {props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                          ? "Teams"
                          : "Reps"}
                      </TableCell>
                      {props.type === "CFQ"
                        ? Array(
                            props.db === "C0003"
                              ? 11
                              : props.showSeats === "Y"
                              ? 14
                              : 13
                          )
                            .fill()
                            .map((item) => (
                              <TableCell
                                align="center"
                                className={classes.repHeader}
                                style={{ border: "0.5px solid lightgrey" }}
                                colSpan={1}
                              ></TableCell>
                            ))
                        : Array(
                            props.db === "C0003"
                              ? 6
                              : props.showSeats === "Y"
                              ? 7
                              : 8
                          )
                            .fill()
                            .map((item) => (
                              <TableCell
                                align="center"
                                className={classes.repHeader}
                                style={{ border: "0.5px solid lightgrey" }}
                                colSpan={1}
                              ></TableCell>
                            ))}
                    </StyledTableRow>
                  </>
                )}
                {teams.map((team) => {
                  const teamData = props.data.filter((s) => s.name === team);
                  const teamDatav2 = props.datav2.filter(
                    (s) => s.name === team
                  );
                  return (
                    <StyledTableRow>
                      <TableCell
                        align="left"
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          textIndent: "0rem",
                          paddingLeft: "1.75rem",
                          minWidth: 160,
                        }}
                        colSpan={1}
                      >
                        {team}
                      </TableCell>
                      {props.db !== "C0003" && (
                        <>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(
                              teamData[0].coverageGoal
                            )
                              ? teamData[0].coverageGoal
                              : 0}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            style={
                              teamData[0].coverageGoal > teamData[0].actual
                                ? {
                                    border: "0.5px solid lightgrey",
                                    paddingRight: "0.5rem",
                                  }
                                : {
                                    border: "0.5px solid lightgrey",
                                    paddingRight: "0.5rem",
                                    backgroundColor: "#E2EFDA",
                                  }
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(teamData[0].actual)
                              ? teamData[0].actual.toFixed(2)
                              : "N/A"}
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          paddingRight: "0.5rem",
                        }}
                        colSpan={1}
                      >
                        {Currency_Symbol}
                        {![null, undefined, ""].includes(teamData[0].goal)
                          ? countOrACVFormatter(teamData[0].goal)
                          : 0}
                      </TableCell>
                      {props.type === "CFQ" && (
                        <>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocationWon(
                                "drilldownOnGraph",
                                [team],
                                splitup
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {Currency_Symbol}
                            {![null, undefined, ""].includes(teamData[0].won)
                              ? countOrACVFormatter(teamData[0].won)
                              : 0}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {Currency_Symbol}
                            {![null, undefined, ""].includes(
                              teamData[0].remainingCurrentQuarter
                            )
                              ? countOrACVFormatter(
                                  teamData[0].remainingCurrentQuarter
                                )
                              : 0}
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            [team],
                            "open",
                            props.type,
                            true
                          )
                        }
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          cursor: "pointer",
                          paddingRight: "0.5rem",
                        }}
                        colSpan={1}
                      >
                        {![null, undefined, ""].includes(teamData[0].count)
                          ? countOrACVFormatter(teamData[0].count)
                          : 0}
                      </TableCell>
                      {props.showSeats === "Y" && (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [team],
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(teamData[0].seats)
                            ? countOrACVFormatter(teamData[0].seats)
                            : 0}
                        </TableCell>
                      )}
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            [team],
                            "open",
                            props.type,
                            true
                          )
                        }
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          cursor: "pointer",
                          paddingRight: "0.5rem",
                        }}
                        colSpan={1}
                      >
                        {Currency_Symbol}
                        {![null, undefined, ""].includes(teamData[0].acv)
                          ? countOrACVFormatter(teamData[0].acv)
                          : 0}
                      </TableCell>

                      {props.type === "CFQ" && (
                        <>
                          {/* <TableCell align='right' className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>${![null,undefined,''].includes(teamData[0].expectedWonAcv) ? countOrACVFormatter(teamData[0].expectedWonAcv) : 0}</TableCell>
                                     <TableCell align='right' className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>{![null,undefined,''].includes(teamData[0].attainmentPerc) ? Math.round(teamData[0].attainmentPerc) : 0}%</TableCell> */}
                        </>
                      )}
                      {/* Skygeni Projected yield low-mid-high */}
                      {props.ShowLMH !== false && (
                        <>
                          {teamData[0].low < teamDatav2[0].low ? (
                            <HtmlTooltip
                              title={
                                <div
                                  style={{
                                    borderTop: "solid #FF0000",
                                    borderRadius: "1rem",
                                    padding: "0px",
                                    margin: 0,
                                    borderWidth: "0.6rem",
                                  }}
                                >
                                  <div
                                    className="tooltip-box"
                                    style={{ padding: "0px", fontSize: "1rem" }}
                                  >
                                    <h3
                                      className="heading-style"
                                      style={{ padding: "0.5rem" }}
                                    >
                                      {team}
                                    </h3>
                                    <div
                                      style={{
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        padding: "0.5rem",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <h4 className="result-style">
                                        Outlier ACV :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {Currency_Symbol}
                                          {countOrACVFormatter(
                                            teamData[0].outlierAcv
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        Outlier Opp. :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            teamData[0].outlierCount
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [team],
                                    "open",
                                    props.type,
                                    true
                                  )
                                }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(
                                  teamData[0].low
                                )
                                  ? ast(teamData[0].low, teamDatav2[0].low)
                                  : "$0"}
                              </TableCell>
                            </HtmlTooltip>
                          ) : (
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [team],
                                  "open",
                                  props.type,
                                  true
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(teamData[0].low)
                                ? ast(teamData[0].low, teamDatav2[0].low)
                                : "$0"}
                            </TableCell>
                          )}

                          {teamData[0].mid < teamDatav2[0].mid ? (
                            <HtmlTooltip
                              title={
                                <div
                                  style={{
                                    borderTop: "solid #FF0000",
                                    borderRadius: "1rem",
                                    padding: "0px",
                                    margin: 0,
                                    borderWidth: "0.6rem",
                                  }}
                                >
                                  <div
                                    className="tooltip-box"
                                    style={{ padding: "0px", fontSize: "1rem" }}
                                  >
                                    <h3
                                      className="heading-style"
                                      style={{ padding: "0.5rem" }}
                                    >
                                      {team}
                                    </h3>
                                    <div
                                      style={{
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        padding: "0.5rem",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <h4 className="result-style">
                                        Outlier Opp. :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            teamData[0].outlierCount
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        Outlier ACV :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            teamData[0].outlierAcv,
                                            true
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [team],
                                    "open",
                                    props.type,
                                    true
                                  )
                                }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(
                                  teamData[0].mid
                                )
                                  ? ast(teamData[0].mid, teamDatav2[0].mid)
                                  : "$0"}
                              </TableCell>
                            </HtmlTooltip>
                          ) : (
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [team],
                                  "open",
                                  props.type,
                                  true
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(teamData[0].mid)
                                ? ast(teamData[0].mid, teamDatav2[0].mid)
                                : "$0"}
                            </TableCell>
                          )}

                          {teamData[0].high <
                            teamData[0].remainingCurrentQuarter &&
                          props.type === "CFQ" ? (
                            <HtmlTooltip
                              title={
                                <div
                                  style={{
                                    borderTop: "solid #f4b183",
                                    borderRadius: "1rem",
                                    padding: "0px",
                                    margin: 0,
                                    borderWidth: "0.6rem",
                                  }}
                                >
                                  <div
                                    className="tooltip-box"
                                    style={{ padding: "0px", fontSize: "1rem" }}
                                  >
                                    <h3
                                      className="heading-style"
                                      style={{ padding: "0.5rem" }}
                                    >
                                      {team}
                                    </h3>
                                    <div
                                      style={{
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        padding: "0.5rem",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <h4 className="result-style">
                                        {" "}
                                        Expected Pipeline Yield (High) :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            teamData[0].high,
                                            true
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        <u>less than</u> &nbsp; Remaining Quota
                                        :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            teamData[0].remainingCurrentQuarter,
                                            true
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        margin: "0px",
                                        paddingBottom: "0.3rem",
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <h4
                                        style={{
                                          backgroundColor: "#f4b183",
                                          padding: "0.1rem",
                                        }}
                                        className="heading-style"
                                      >
                                        At risk of missing quota
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [team],
                                    "open",
                                    props.type,
                                    true
                                  )
                                }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  cursor: "pointer",
                                  paddingRight: "0.5rem",
                                  background: "#F4B183",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(
                                  teamData[0].high
                                )
                                  ? ast(teamData[0].high, teamDatav2[0].high)
                                  : "$0"}
                              </TableCell>
                            </HtmlTooltip>
                          ) : teamData[0].high < teamDatav2[0].high ? (
                            <HtmlTooltip
                              title={
                                <div
                                  style={{
                                    borderTop: "solid #FF0000",
                                    borderRadius: "1rem",
                                    padding: "0px",
                                    margin: 0,
                                    borderWidth: "0.6rem",
                                  }}
                                >
                                  <div
                                    className="tooltip-box"
                                    style={{ padding: "0px", fontSize: "1rem" }}
                                  >
                                    <h3
                                      className="heading-style"
                                      style={{ padding: "0.5rem" }}
                                    >
                                      {team}
                                    </h3>
                                    <div
                                      style={{
                                        paddingLeft: "0.8rem",
                                        paddingRight: "0.8rem",
                                      }}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className="container"
                                      style={{
                                        padding: "0.5rem",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <h4 className="result-style">
                                        Outlier Opp. :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            teamData[0].outlierCount
                                          )}
                                        </span>{" "}
                                      </h4>
                                      <h4
                                        style={{ paddingTop: "0.3rem" }}
                                        className="result-style"
                                      >
                                        {" "}
                                        Outlier ACV :{" "}
                                        <span
                                          style={{ color: "rgb(31,119,180)" }}
                                        >
                                          {countOrACVFormatter(
                                            teamData[0].outlierAcv,
                                            true
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [team],
                                    "open",
                                    props.type,
                                    true
                                  )
                                }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(
                                  teamData[0].high
                                )
                                  ? ast(teamData[0].high, teamDatav2[0].high)
                                  : "$0"}
                              </TableCell>
                            </HtmlTooltip>
                          ) : (
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [team],
                                  "open",
                                  props.type,
                                  true
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(teamData[0].high)
                                ? ast(teamData[0].high, teamDatav2[0].high)
                                : "$0"}
                            </TableCell>
                          )}
                          {/* Skygeni Projected attainment low-mid-high */}
                          {props.type === "CFQ" && (
                            <>
                              {teamData[0].projectedAttainmentLow <
                              teamDatav2[0].projectedAttainmentLow ? (
                                <HtmlTooltip
                                  title={
                                    <div
                                      style={{
                                        borderTop: "solid #FF0000",
                                        borderRadius: "1rem",
                                        padding: "0px",
                                        margin: 0,
                                        borderWidth: "0.6rem",
                                      }}
                                    >
                                      <div
                                        className="tooltip-box"
                                        style={{
                                          padding: "0px",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        <h3
                                          className="heading-style"
                                          style={{ padding: "0.5rem" }}
                                        >
                                          {team}
                                        </h3>
                                        <div
                                          style={{
                                            paddingLeft: "0.8rem",
                                            paddingRight: "0.8rem",
                                          }}
                                        >
                                          <hr />
                                        </div>
                                        <div
                                          className="container"
                                          style={{
                                            padding: "0.5rem",
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          <h4 className="result-style">
                                            Outlier Opp. :{" "}
                                            <span
                                              style={{
                                                color: "rgb(31,119,180)",
                                              }}
                                            >
                                              {countOrACVFormatter(
                                                teamData[0].outlierCount
                                              )}
                                            </span>{" "}
                                          </h4>
                                          <h4
                                            style={{ paddingTop: "0.3rem" }}
                                            className="result-style"
                                          >
                                            {" "}
                                            Outlier ACV :{" "}
                                            <span
                                              style={{
                                                color: "rgb(31,119,180)",
                                              }}
                                            >
                                              {countOrACVFormatter(
                                                teamData[0].outlierAcv,
                                                true
                                              )}
                                            </span>
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <TableCell
                                    align="right"
                                    className={classes.repHeader}
                                    style={{
                                      border: "0.5px solid lightgrey",
                                      paddingRight: "0.5rem",
                                    }}
                                    colSpan={1}
                                  >
                                    {![null, undefined, ""].includes(
                                      teamData[0].projectedAttainmentLow
                                    )
                                      ? ast(
                                          teamData[0].projectedAttainmentLow,
                                          teamDatav2[0].projectedAttainmentLow
                                        )
                                      : "$0"}
                                  </TableCell>
                                </HtmlTooltip>
                              ) : (
                                <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  style={{
                                    border: "0.5px solid lightgrey",
                                    paddingRight: "0.5rem",
                                  }}
                                  colSpan={1}
                                >
                                  {![null, undefined, ""].includes(
                                    teamData[0].projectedAttainmentLow
                                  )
                                    ? ast(
                                        teamData[0].projectedAttainmentLow,
                                        teamDatav2[0].projectedAttainmentLow
                                      )
                                    : "$0"}
                                </TableCell>
                              )}

                              {teamData[0].projectedAttainmentMid <
                              teamDatav2[0].projectedAttainmentMid ? (
                                <HtmlTooltip
                                  title={
                                    <div
                                      style={{
                                        borderTop: "solid #FF0000",
                                        borderRadius: "1rem",
                                        padding: "0px",
                                        margin: 0,
                                        borderWidth: "0.6rem",
                                      }}
                                    >
                                      <div
                                        className="tooltip-box"
                                        style={{
                                          padding: "0px",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        <h3
                                          className="heading-style"
                                          style={{ padding: "0.5rem" }}
                                        >
                                          {team}
                                        </h3>
                                        <div
                                          style={{
                                            paddingLeft: "0.8rem",
                                            paddingRight: "0.8rem",
                                          }}
                                        >
                                          <hr />
                                        </div>
                                        <div
                                          className="container"
                                          style={{
                                            padding: "0.5rem",
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          <h4 className="result-style">
                                            Outlier Opp. :{" "}
                                            <span
                                              style={{
                                                color: "rgb(31,119,180)",
                                              }}
                                            >
                                              {countOrACVFormatter(
                                                teamData[0].outlierCount
                                              )}
                                            </span>{" "}
                                          </h4>
                                          <h4
                                            style={{ paddingTop: "0.3rem" }}
                                            className="result-style"
                                          >
                                            {" "}
                                            Outlier ACV :{" "}
                                            <span
                                              style={{
                                                color: "rgb(31,119,180)",
                                              }}
                                            >
                                              {countOrACVFormatter(
                                                teamData[0].outlierAcv,
                                                true
                                              )}
                                            </span>
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <TableCell
                                    align="right"
                                    className={classes.repHeader}
                                    style={{
                                      border: "0.5px solid lightgrey",
                                      paddingRight: "0.5rem",
                                    }}
                                    colSpan={1}
                                  >
                                    {![null, undefined, ""].includes(
                                      teamData[0].projectedAttainmentMid
                                    )
                                      ? ast(
                                          teamData[0].projectedAttainmentMid,
                                          teamDatav2[0].projectedAttainmentMid
                                        )
                                      : "$0"}
                                  </TableCell>
                                </HtmlTooltip>
                              ) : (
                                <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  style={{
                                    border: "0.5px solid lightgrey",
                                    paddingRight: "0.5rem",
                                  }}
                                  colSpan={1}
                                >
                                  {![null, undefined, ""].includes(
                                    teamData[0].projectedAttainmentMid
                                  )
                                    ? ast(
                                        teamData[0].projectedAttainmentMid,
                                        teamDatav2[0].projectedAttainmentMid
                                      )
                                    : "$0"}
                                </TableCell>
                              )}

                              {teamData[0].projectedAttainmentHigh <
                              teamDatav2[0].projectedAttainmentHigh ? (
                                <HtmlTooltip
                                  title={
                                    <div
                                      style={{
                                        borderTop: "solid #FF0000",
                                        borderRadius: "1rem",
                                        padding: "0px",
                                        margin: 0,
                                        borderWidth: "0.6rem",
                                      }}
                                    >
                                      <div
                                        className="tooltip-box"
                                        style={{
                                          padding: "0px",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        <h3
                                          className="heading-style"
                                          style={{ padding: "0.5rem" }}
                                        >
                                          {team}
                                        </h3>
                                        <div
                                          style={{
                                            paddingLeft: "0.8rem",
                                            paddingRight: "0.8rem",
                                          }}
                                        >
                                          <hr />
                                        </div>
                                        <div
                                          className="container"
                                          style={{
                                            padding: "0.5rem",
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          <h4 className="result-style">
                                            Outlier Opp. :{" "}
                                            <span
                                              style={{
                                                color: "rgb(31,119,180)",
                                              }}
                                            >
                                              {countOrACVFormatter(
                                                teamData[0].outlierCount
                                              )}
                                            </span>{" "}
                                          </h4>
                                          <h4
                                            style={{ paddingTop: "0.3rem" }}
                                            className="result-style"
                                          >
                                            {" "}
                                            Outlier ACV :{" "}
                                            <span
                                              style={{
                                                color: "rgb(31,119,180)",
                                              }}
                                            >
                                              {countOrACVFormatter(
                                                teamData[0].outlierAcv,
                                                true
                                              )}
                                            </span>
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <TableCell
                                    align="right"
                                    className={classes.repHeader}
                                    style={{
                                      border: "0.5px solid lightgrey",
                                      paddingRight: "0.5rem",
                                    }}
                                    colSpan={1}
                                  >
                                    {![null, undefined, ""].includes(
                                      teamData[0].projectedAttainmentHigh
                                    )
                                      ? ast(
                                          teamData[0].projectedAttainmentHigh,
                                          teamDatav2[0].projectedAttainmentHigh
                                        )
                                      : "$0"}
                                  </TableCell>
                                </HtmlTooltip>
                              ) : (
                                // </HtmlTooltip>
                                <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  style={{
                                    border: "0.5px solid lightgrey",
                                    paddingRight: "0.5rem",
                                  }}
                                  colSpan={1}
                                >
                                  {![null, undefined, ""].includes(
                                    teamData[0].projectedAttainmentHigh
                                  )
                                    ? ast(
                                        teamData[0].projectedAttainmentHigh,
                                        teamDatav2[0].projectedAttainmentHigh
                                      )
                                    : "$0"}
                                </TableCell>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

// background:'#f4b183'
